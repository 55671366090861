<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('CAMPAIGN.ADD.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.DESC')"
    />
    <form class="flex flex-col w-full" @submit.prevent="addCampaign">
      <div class="w-full">
        <!-- START TITLE INPUT -->
        <woot-input
          v-model="title"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />
        <!-- END TITLE INPUT -->

        <!-- START INBOX MULTISELECT -->
        <label :class="{ error: $v.selectedInbox.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
          <select v-model="selectedInbox">
            <option
              v-for="item in campaignInboxes"
              :key="item.name"
              :value="item"
            >
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.selectedInbox.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
          </span>
        </label>
        <!-- END INBOX MULTISELECT -->

        <!-- START BOT MULTISELECT -->
        <label>
          {{ $t('CAMPAIGN.ADD.FORM.BOT.LABEL') }}
          <select v-model="selectedAgentBotId">
            <option :value="null" selected>
              {{ $t('CAMPAIGN.ADD.FORM.BOT.INBOX_DEFAULT_OPTION') }}
            </option>
            <option
              v-for="agentBot in agentBots"
              :key="agentBot.id"
              :value="agentBot.id"
            >
              {{ agentBot.name }}
            </option>
          </select>
        </label>
        <!-- END BOT MULTISELECT -->

        <!-- START RADIO SELECT CSV/LABEL -->
        <label :class="{ error: $v.picked.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.SOURCE.LABEL') }}
          <div>
            <input
              id="label_radio"
              v-model="picked"
              type="radio"
              value="label"
              @change="handleChangePicking"
            />
            <label for="label_radio" class="inline pr-3 pl-1">
              {{ $t('CAMPAIGN.ADD.FORM.SOURCE.AUDIENCE') }}
            </label>
            <input
              id="csv_radio"
              v-model="picked"
              type="radio"
              value="csv"
              @change="handleChangePicking"
            />
            <label for="csv_radio" class="inline pl-1">
              {{ $t('CAMPAIGN.ADD.FORM.SOURCE.CSV') }}
            </label>
          </div>

          <span v-if="$v.picked.$error" class="editor-warning__message">
            {{ $t('CAMPAIGN.ADD.FORM.SOURCE.ERROR') }}
          </span>

          <label
            v-if="isOneOffType"
            class="multiselect-wrap--small"
            :class="{ error: $v.selectedAudience.$error }"
          >
            <multiselect
              v-if="picked === 'label'"
              v-model="selectedAudience"
              :options="audienceList"
              track-by="id"
              label="title"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              :placeholder="$t('CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER')"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              @blur="$v.selectedAudience.$touch"
              @select="$v.selectedAudience.$touch"
            />
            <span
              v-if="picked === 'label' && $v.selectedAudience.$error"
              class="editor-warning__message"
            >
              {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
            </span>
          </label>

          <div v-if="isCsvAudience">
            <input
              ref="fileInput"
              type="file"
              accept=".csv"
              @change="handleCsvUpload"
            />
            <small style="display: block">
              {{ $t('CAMPAIGN.ADD.FORM.CSV.SAMPLE_FILE_1') }}
              <a
                href="/assets/clientes-prospectados.csv"
                target="_blank"
                style="font-size: inherit; padding: 0; color: blue"
              >
                {{ $t('CAMPAIGN.ADD.FORM.CSV.SAMPLE_FILE_2') }}
              </a>
            </small>
            <span v-if="!csvReceived" class="editor-warning__message">
              {{ $t('CAMPAIGN.ADD.FORM.CSV.ERROR') }}
            </span>
            <span
              v-if="csvErrorMessage.length > 0"
              class="editor-warning__message"
            >
              <ul>
                <li v-for="element in csvErrorMessage" :key="element">
                  {{ element }}
                </li>
              </ul>
            </span>
          </div>
        </label>
        <!-- END RADIO SELECT CSV/LABEL -->

        <!-- START COUNTRY CODE INPUT -->
        <div v-if="isCsvAudience" :class="{ error: countryName === null }">
          {{ $t('CAMPAIGN.ADD.FORM.COUNTRY_SELECTOR.TITLE') }}
          <multiselect
            v-model="countryName"
            :options="countriesList"
            track-by="name"
            label="name"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :hide-selected="false"
            :placeholder="$t('CAMPAIGN.ADD.FORM.COUNTRY_SELECTOR.LABEL')"
            :select-label="
              $t('CAMPAIGN.ADD.FORM.COUNTRY_SELECTOR.SELECT_LABEL')
            "
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          />
        </div>
        <!-- END COUNTRY CODE INPUT -->

        <!-- START MESSAGE LABEL -->
        <div v-if="hasWhatsappTemplates">
          <templates-picker
            v-if="!template"
            :inbox-id="inboxSelected"
            @click.stop
            @onSelect="toggleWaTemplate"
          />
          <campaign-template-parser
            v-else
            :template="template"
            :csv-headers="csvHeaders"
            :first-example="
              selectedAudience.length === 0 ? {} : selectedAudience[0]
            "
            :is-csv-audience="isCsvAudience"
            @template-update="onTemplateUpdate"
            @attachments-change="updateAttachments"
          />
        </div>

        <div v-else-if="isCommonWhatsappCampaign">
          <common-whatsapp-campaign-message
            :first-example="
              selectedAudience.length === 0 ? {} : selectedAudience[0]
            "
            :csv-headers="csvHeaders"
            :is-csv-audience="isCsvAudience"
            @on-change-message="onChangeMessageCommonWhatsapp"
            @attachments-change="updateAttachments"
          />

          <common-whatsapp-intervals-select
            @selected-interval="onChangeIntervalSelected"
          />
        </div>
        <!-- END MESSAGE LABEL -->

        <label v-if="isOneOffType">
          {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
          <woot-date-time-picker
            :value="scheduledAt"
            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
            @change="onChange"
          />
        </label>
      </div>

      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <woot-button
          :is-loading="uiFlags.isCreatingOneOff"
          :disabled="badCampaignParams"
          type="submit"
        >
          {{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
// import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import campaignMixin from 'shared/mixins/campaignMixin';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { CAMPAIGNS_EVENTS } from '../../../../../helper/AnalyticsHelper/events';
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import CampaignTemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/CampaignTemplateParser.vue';
import countries from 'shared/constants/countries.js';
import CommonWhatsappIntervalsSelect from '../CommonWhatsappCampaign/CommonWhatsappIntervalsSelect.vue';
import CommonWhatsappCampaignMessage from '../CommonWhatsappCampaign/CommonWhatsappCampaignMessage.vue';
import whatsappTemplateMixin from 'shared/mixins/whatsappTemplateMixin';
import * as csv from 'csvtojson';

export default {
  components: {
    WootDateTimePicker,
    TemplatesPicker,
    CampaignTemplateParser,
    CommonWhatsappIntervalsSelect,
    CommonWhatsappCampaignMessage,
  },
  mixins: [whatsappTemplateMixin, alertMixin, campaignMixin],
  props: {},
  data() {
    return {
      title: '',
      message: '',
      selectedInbox: null,
      show: true,
      enabled: true,
      scheduledAt: null,
      selectedAudience: [],
      selectedAudienceBckpFromCSV: [],
      csvHeaders: [],
      picked: 'label',
      csvErrorMessage: [],
      csvReceived: false,
      selectedInterval: [],
      countryName: '',
      template: null, // selected WhatsApp Template
      countriesList: [...countries].map(e => ({
        name: e.emoji + ' ' + e.name,
        code: e.dial_code,
      })),
      selectedAgentBotId: null,
    };
  },

  validations() {
    const commonValidations = {
      title: {
        required,
      },
      selectedInbox: {
        required,
      },
      picked: {
        required,
      },
    };
    return {
      ...commonValidations,
      selectedAudience: {
        isEmpty() {
          return !!this.selectedAudience.length;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      audienceList: 'labels/getLabels',
      conversationsUiFlags: 'contactConversations/getUIFlags',
      currentUser: 'getCurrentUser',
      agentBots: 'agentBots/getBots',
    }),
    inboxSelected: {
      get() {
        if (this.selectedInbox) return this.selectedInbox.id;
        return false;
      },
      set(value) {
        this.selectedInbox = value;
      },
    },
    isCsvAudience() {
      return this.picked === 'csv';
    },
    countryCode() {
      return this.countryName?.code?.replace(/\D/g, ''); // Only numbers
    },
    badCampaignParams() {
      return (
        this.$v.$invalid ||
        !this.selectedAudience?.length ||
        !this.picked ||
        !this.scheduledAt ||
        this.isCommonWhatsappConfigInvalid ||
        (!this.countryCode && this.isCsvAudience) ||
        this.templateWithMediaAndAttachmentWasNotUploaded ||
        this.uploadedFileIsNotInCorrectExtension
      );
    },
    isCommonWhatsappConfigInvalid() {
      if (!this.isCommonWhatsappCampaign) return false;
      if (!this.selectedInterval || this.selectedInterval.length !== 2)
        return true;
      if (this.selectedInterval[1] < this.selectedInterval[0]) return true;
      return false;
    },
  },
  mounted() {
    this.$track(CAMPAIGNS_EVENTS.OPEN_NEW_CAMPAIGN_MODAL, {
      type: this.campaignType,
    });
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    handleChangePicking() {
      if (this.picked.toUpperCase() !== 'CSV') {
        this.selectedAudience = [];
      } else {
        this.csvHeaders = [];
      }
    },
    getCampaignDetails() {
      let campaignDetails = null;
      let audience = [];
      let inbox_id;
      let message;
      if (this.isCsvAudience) {
        this.selectedAudience.forEach(element => {
          if (this.isCommonWhatsappCampaign) {
            element.parameters = this.commonWhatsappMessage.parameters.map(
              e => ({ type: 'text', text: element[e] })
            );
            return;
          }
          if (!this.waTemplateConfig) return;
          element.parameters = Object.values(this.waTemplateConfig).map(val => {
            if (val.isFromCSV)
              return {
                type: 'text',
                text: element[val.field],
              };
            return {
              type: 'text',
              text: val.value,
            };
          });
        });
        audience.push({
          csv_data: this.selectedAudience,
          type: 'CSV',
        });
      } else {
        audience = this.selectedAudience.map(item => {
          return {
            id: item.id,
            type: 'Label',
          };
        });
        if (this.waTemplateConfig)
          audience[0].parameters = Object.values(this.waTemplateConfig).map(
            e => {
              return {
                type: 'text',
                text: e.value,
              };
            }
          );
        if (this.isCommonWhatsappCampaign) {
          audience[0].parameters = this.commonWhatsappMessage.parameters.map(
            e => ({ type: 'text', text: e })
          );
        }
      }
      if (this.template) {
        message = JSON.stringify(this.template);
        inbox_id = this.selectedInbox.id;
      } else {
        message = this.message;
        inbox_id = this.selectedInbox?.id;
      }
      campaignDetails = {
        title: this.title,
        message,
        inbox_id,
        scheduled_at: this.scheduledAt,
        audience: audience,
        agent_bot_id: this.selectedAgentBotId,
      };
      if (this.isCommonWhatsappCampaign) {
        campaignDetails.message = JSON.stringify({
          text: this.commonWhatsappMessage.text,
        });
        campaignDetails.trigger_rules = {
          minInterval: this.selectedInterval[0],
          maxInterval: this.selectedInterval[1],
        };
      }
      if (this.isCsvAudience) campaignDetails.country_code = this.countryCode;
      if (this.attachedFiles.length)
        campaignDetails.attachment = this.attachedFiles[0]?.resource.file;
      return campaignDetails;
    },
    onChangeIntervalSelected(event) {
      this.selectedInterval = event;
    },
    onChangeMessageCommonWhatsapp(event) {
      this.commonWhatsappMessage = event;
      this.message = event.text;
    },
    async addCampaign() {
      try {
        const campaignDetails = this.getCampaignDetails();

        // Validating fields
        if (this.canNotRequestWithCurrentParams() || !campaignDetails.message) {
          this.showAlert(this.$t('CAMPAIGN.ADD.CHECK_INPUTS_ERROR'));
          return;
        }

        await this.$store.dispatch('campaigns/createOneOff', campaignDetails);
        this.$store.dispatch('campaigns/get'); // Refreshing state with possible new records
        this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.isCsvAudience
            ? this.$t('CAMPAIGN.ADD.API.ERROR_NUMBER_OF_CONTACTS_CSV')
            : this.$t('CAMPAIGN.ADD.API.ERROR_NUMBER_OF_CONTACTS_LABEL') ||
              this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    handleCsvUpload(event) {
      this.csvErrorMessage = [];
      this.csvReceived = false;

      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();

      try {
        reader.onload = async e => {
          const csvContent = e.target.result;
          let jsonResult = await csv().fromString(csvContent);
          if (jsonResult.length === 0) return;

          const missingPhoneIndexes = [];
          const missingNameIndexes = [];
          const fieldOver50Chars = [];
          let moreThan5000Lines = false;

          if (jsonResult.length > 5000) {
            jsonResult = jsonResult.slice(0, 5000);
            moreThan5000Lines = true;
          }

          const list = [];
          // Validating CSV Fields
          jsonResult.forEach((element, index) => {
            if (!element.celular) {
              missingPhoneIndexes.push(index);
              return;
            }
            element.celular = element.celular.replace(/[^0-9+]/g, '');
            if (element.celular.length < 6) {
              missingPhoneIndexes.push(index);
              return;
            }
            if (!element.nome) {
              missingNameIndexes.push(index);
            }
            Object.values(element).forEach(value => {
              if (value && value.length > 50) {
                value = value.slice(0, 50);
                if (!fieldOver50Chars.includes(index))
                  fieldOver50Chars.push(index);
              }
            });
            Object.keys(element).forEach(key => {
              if (['celular', 'nome'].includes(key)) return;
              const newKey = key
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/\s+/g, '_');
              element[newKey] = element[key];
              if (newKey !== key) delete element[key];
            });
            list.push(element);
          });
          this.selectedAudience = list;
          this.csvHeaders = Object.keys(list[0]);
          this.csvReceived = true;

          // Showing text error based on failures on csv Upload
          if (missingPhoneIndexes.length > 0)
            this.csvErrorMessage.push(
              this.$t('CAMPAIGN.ADD.FORM.CSV.MISSING_PHONES') +
                missingPhoneIndexes.join(',')
            );
          if (missingNameIndexes.length > 0)
            this.csvErrorMessage.push(
              this.$t('CAMPAIGN.ADD.FORM.CSV.MISSING_NAME') +
                missingNameIndexes.join(',')
            );
          if (fieldOver50Chars.length > 0)
            this.csvErrorMessage.push(
              this.$t('CAMPAIGN.ADD.FORM.CSV.OVER_CHARS') +
                fieldOver50Chars.join(',')
            );
          if (moreThan5000Lines)
            this.csvErrorMessage.push(
              this.$t('CAMPAIGN.ADD.FORM.CSV.OVER_LINES')
            );
        };
        reader.readAsText(file);
      } catch (error) {
        const alert = this.$t('CAMPAIGN.ADD.FORM.CSV.ERROR_CSV_EXTENSION');
        this.showAlert(alert);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 5rem;
}

.message-editor {
  @apply px-3;

  ::v-deep {
    .ProseMirror-menubar {
      @apply rounded-tl-[4px];
    }
  }
}
input[type='file'] {
  margin: 0;
}
</style>
