import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUserRole: 'getCurrentRole',
    }),
    isManager() {
      return this.currentUserRole === 'manager';
    },
    allowedApps() {
      return [
        {
          id: 'google_calendar',
          name: 'Google Agenda',
          description:
            'Aprimore o seu agendamento e gerenciamento de tempo integrando-se com o Google Agenda. Esta integração permite que você crie eventos diretamente a partir do chatbot, garantindo que seus clientes nunca sejam esquecidos. Mantenha-se organizado e aumente a produtividade com a integração perfeita com o Google Agenda.',
          enabled: true,
          logo: 'googlecalendar.png',
          i18n_key: 'google_calendar',
          action: '/google_calendar',
          hook_type: 'account',
          allow_multiple_hooks: false,
          button: '/google_calendar',
          hooks: [],
        },
      ];
    },
  },
};
