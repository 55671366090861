<template>
  <div class="flex-1 overflow-auto p-4">
    <div v-if="isLoading">
      <div class="items-center flex text-base justify-center">
        <spinner color-scheme="primary" />
        <span>{{ $t('CAMPAIGN.LIST.LOADING_MESSAGE') }}</span>
      </div>
      <div class="h-[80vh]" />
    </div>
    <div v-else>
      <!-- ERROR IN API -->
      <span
        v-if="shouldShowErrorMessage"
        class="text-red-400 dark:text-red-500 text-sm block font-normal -mt-px w-full"
      >
        {{ $t('CAMPAIGN.REPORT.ERROR') }}
      </span>
      <div v-else>
        <!-- DETAILS CONTAINER -->
        <div class="flex row">
          <metric-card
            :show-live-label="false"
            style="max-width: calc(100vw - 100px)"
          >
            <campaign-report-details
              :inbox="campaignInbox"
              :campaign="campaign"
              :campaign-report="campaignReport"
              @search-by-range="setCurrentRangeFilter"
            />
          </metric-card>
        </div>
        <div class="flex row">
          <!-- FUNIL DE CONVERSÃO -->
          <metric-card class="h-[500px]" :show-live-label="false">
            <div
              v-if="isLoadingReport"
              class="items-center flex text-base justify-center"
            >
              <spinner color-scheme="primary" />
            </div>
            <campaign-report-chart
              v-else
              :campaign-report="campaignReport"
              :default-text-color="defaultTextColor"
              :current-display-theme="currentDisplayTheme"
            />
          </metric-card>
          <!-- BENCHMARKS -->
          <div
            style="max-width: calc(100vw - 85px) !important"
            class="responsive-benchmark"
          >
            <campaign-report-benchmarks
              :is-loading="isLoadingReport"
              :campaign="campaign"
              :campaign-report="campaignReport"
              :current-display-theme="currentDisplayTheme"
            />
          </div>
        </div>
        <!-- MESSAGES CONTAINER -->
        <div class="flex row">
          <metric-card
            :show-live-label="false"
            style="max-width: calc(100vw - 100px)"
          >
            <campaign-report-messages
              :campaign-report="campaignReport"
              :page-index="pageIndex"
              :is-loading="isLoadingReportMessages"
              @page-change="pageChange"
              @status-change="statusChange"
              @search-by-contactinfo="searchByContactInfo"
              @clean-filter="cleanSearchFilter"
            />
          </metric-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import campaignMixin from 'shared/mixins/campaignMixin';
import MetricCard from 'dashboard/routes/dashboard/settings/reports/components/overview/MetricCard.vue';
import CampaignReportChart from '../reports/CampaignReportChart.vue';
import CampaignReportDetails from '../reports/CampaignReportDetails.vue';
import CampaignReportBenchmarks from '../reports/CampaignReportBenchmarks.vue';
import CampaignReportMessages from '../reports/CampaignReportMessages.vue';
import Spinner from 'shared/components/Spinner.vue';
import { getChangesOnTheme } from 'dashboard/helper/themeHelper';

export default {
  components: {
    MetricCard,
    CampaignReportChart,
    CampaignReportDetails,
    CampaignReportBenchmarks,
    CampaignReportMessages,
    Spinner,
  },
  mixins: [campaignMixin],
  data() {
    return {
      isLoading: true,
      isLoadingReport: true,
      isLoadingReportMessages: true,
      campaignReport: {},
      pageIndex: 1,
      statusFilter: '',
      contactInfoFilter: '',
      startAt: '',
      endAt: '',
      defaultTextColor: '',
      currentDisplayTheme: '',
    };
  },
  computed: {
    campaign() {
      return this.$store.getters['ongoingCampaigns/getCurrentCampaign'](
        this.campaignDisplayId
      );
    },
    campaignInbox() {
      return this.campaign?.inbox || {};
    },
    shouldShowErrorMessage() {
      return !this.campaign;
    },
  },
  watch: {
    campaignId() {
      // Ensure it will not try to fetch a new campaign report if its not in the campaign report panel
      // I'm doing it to make sure that the current report data is updated
      if (this.$route?.params?.campaignId) this.fetchCurrentCampaignReport();
    },
  },
  created() {
    this.setDefaultTextColor();
  },
  mounted() {
    this.fetchCurrentCampaign();
    this.fetchCurrentCampaignReport();
    getChangesOnTheme(this.currentDisplayTheme, this);
  },
  methods: {
    async fetchCurrentCampaign() {
      await this.$store.dispatch('ongoingCampaigns/get');
      this.isLoading = false;
    },
    async fetchCurrentCampaignReport() {
      const reqParams = {
        id: this.campaignDisplayId,
        pageIndex: this.pageIndex,
        contactInfo: this.contactInfoFilter,
        status: this.statusFilter,
        startAt: this.startAt,
        endAt: this.endAt,
      };
      const response = await this.$store.dispatch(
        'ongoingCampaigns/getReport',
        reqParams
      );
      this.isLoadingReport = false;
      this.isLoadingReportMessages = false;
      if (response === 'API error') return;
      this.campaignReport = response;
    },
    handleThemeChange(newTheme) {
      this.currentDisplayTheme = newTheme;
      this.setDefaultTextColor();
    },
    setDefaultTextColor() {
      if (!this.currentDisplayTheme)
        this.currentDisplayTheme = window.localStorage.color_scheme;

      // Cores padrão para as classes scss 'dark:text-slate-100' e 'text-slate-800' respectivamente
      this.defaultTextColor =
        this.currentDisplayTheme === 'dark'
          ? 'hsl(208 11.7% 91.1% / var(--tw-text-opacity))'
          : 'hsl(198 6.6% 15.8% / var(--tw-text-opacity))';
    },
    pageChange(newVal) {
      this.pageIndex = newVal;
      this.isLoadingReportMessages = true;
      this.fetchCurrentCampaignReport();
    },
    statusChange(newVal) {
      this.statusFilter = newVal;
      this.isLoadingReportMessages = true;
      this.fetchCurrentCampaignReport();
    },
    searchByContactInfo(newVal) {
      this.contactInfoFilter = newVal;
      this.isLoadingReportMessages = true;
      this.fetchCurrentCampaignReport();
    },
    setCurrentRangeFilter(startAt, endAt) {
      this.startAt = startAt;
      this.endAt = endAt;
      this.isLoadingReport = true;
      this.fetchCurrentCampaignReport();
    },
    cleanSearchFilter() {
      this.statusFilter = '';
      this.contactInfoFilter = '';
      this.isLoadingReportMessages = true;
      this.fetchCurrentCampaignReport();
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1236px) {
  .responsive-benchmark {
    @apply w-full;
  }
}
</style>
