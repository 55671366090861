<template>
  <div class="control-section">
    <div>
      <ejs-chart
        id="chartcontainer"
        style="display: block"
        :title="$t('CAMPAIGN.REPORT.BARS.TITLE')"
        :title-style="titleStyle"
        :sub-title="$t('CAMPAIGN.REPORT.BARS.SUBTITLE')"
        :sub-title-style="subtitleStyle"
        :primary-x-axis="primaryXAxis"
        :primary-y-axis="primaryYAxis"
        :chart-area="chartArea"
        width="700px"
        :tooltip="tooltip"
        :legend-settings="legend"
        class="text-slate-900 dark:text-woot-50"
      >
        <e-series-collection>
          <!-- MODIFICAÇÕES ÚTEIS
            Mudar desenho da legenda: legend-shape="Rectangle" "Circle" "SeriesType"
          
          -->

          <e-series
            fill="#570095"
            :data-source="seriesData"
            type="StackingBar"
            x-name="Status"
            y-name="DeliveredCount"
            :name="$t('CAMPAIGN.REPORT.STATUS.DELIVERED')"
            width="2"
            column-width="0.5"
            :marker="marker"
            :corner-radius="
              deliveredPercentage === 100
                ? bothSidesCornerRadius
                : leftSideCornerRadius
            "
            :tooltip-format="`${updatedCount.delivered || 0}`"
          />
          <e-series
            fill="#53373b"
            :data-source="seriesData"
            type="StackingBar"
            x-name="Status"
            y-name="ReadCount"
            :name="$t('CAMPAIGN.REPORT.STATUS.READ')"
            width="2"
            column-width="0.5"
            :marker="marker"
            :corner-radius="
              readPercentage === 100
                ? bothSidesCornerRadius
                : leftSideCornerRadius
            "
            :tooltip-format="`${updatedCount.read || 0}`"
          />
          <e-series
            fill="#b97264"
            :data-source="seriesData"
            type="StackingBar"
            x-name="Status"
            y-name="AnsweredCount"
            :name="$t('CAMPAIGN.REPORT.STATUS.ANSWERED')"
            width="2"
            column-width="0.5"
            :marker="marker"
            :corner-radius="
              answeredPercentage === 100
                ? bothSidesCornerRadius
                : leftSideCornerRadius
            "
            :tooltip-format="`${updatedCount.answered || 0}`"
          />
          <e-series
            fill="#8e55c1"
            :data-source="seriesData"
            type="StackingBar"
            x-name="Status"
            y-name="CompletedCount"
            :name="$t('CAMPAIGN.REPORT.STATUS.COMPLETED')"
            width="2"
            column-width="0.5"
            :marker="marker"
            :corner-radius="
              completedPercentage === 100
                ? bothSidesCornerRadius
                : leftSideCornerRadius
            "
            :tooltip-format="`${updatedCount.completed || 0}`"
          />
          <!-- BARRAS DE PORCENTAGENS DOS STATUS QUE AINDA DEVEM SER REGISTRADOS (FALTANTES, RESTANTES)  -->

          <e-series
            :fill="
              currentDisplayTheme === 'dark' ? 'hsl(206 6.0% 43.5%)' : '#d1d5de'
            "
            :data-source="seriesData"
            type="StackingBar"
            x-name="Status"
            y-name="notDeliveredCount"
            :name="$t('CAMPAIGN.REPORT.STATUS.NOT_DELIVERED')"
            width="2"
            column-width="0.5"
            :opacity="currentDisplayTheme === 'dark' ? '0.1' : '0.5'"
            :corner-radius="
              !deliveredPercentage
                ? bothSidesCornerRadius
                : rightSideCornerRadius
            "
            :marker="markerWithImg('delivered')"
            :tooltip-format="`${notDeliveredCount || 0}`"
          />
          <e-series
            :fill="
              currentDisplayTheme === 'dark' ? 'hsl(206 6.0% 43.5%)' : '#d1d5de'
            "
            :data-source="seriesData"
            type="StackingBar"
            x-name="Status"
            y-name="notReadCount"
            :name="$t('CAMPAIGN.REPORT.STATUS.NOT_READ')"
            width="2"
            column-width="0.5"
            :opacity="currentDisplayTheme === 'dark' ? '0.1' : '0.5'"
            :corner-radius="
              !readPercentage ? bothSidesCornerRadius : rightSideCornerRadius
            "
            :marker="markerWithImg('read')"
            :tooltip-format="`${notReadCount || 0}`"
          />
          <e-series
            :fill="
              currentDisplayTheme === 'dark' ? 'hsl(206 6.0% 43.5%)' : '#d1d5de'
            "
            :data-source="seriesData"
            type="StackingBar"
            x-name="Status"
            y-name="notAnsweredCount"
            :name="$t('CAMPAIGN.REPORT.STATUS.NOT_ANSWERED')"
            width="2"
            column-width="0.5"
            :opacity="currentDisplayTheme === 'dark' ? '0.1' : '0.5'"
            :corner-radius="
              !answeredPercentage
                ? bothSidesCornerRadius
                : rightSideCornerRadius
            "
            :marker="markerWithImg('answered')"
            :tooltip-format="`${notAnsweredCount || 0}`"
          />
          <e-series
            :fill="
              currentDisplayTheme === 'dark' ? 'hsl(206 6.0% 43.5%)' : '#d1d5de'
            "
            :data-source="seriesData"
            type="StackingBar"
            x-name="Status"
            y-name="notCompletedCount"
            :name="$t('CAMPAIGN.REPORT.STATUS.NOT_COMPLETED')"
            width="2"
            column-width="0.5"
            :opacity="currentDisplayTheme === 'dark' ? '0.1' : '0.5'"
            :corner-radius="
              !completedPercentage
                ? bothSidesCornerRadius
                : rightSideCornerRadius
            "
            :marker="markerWithImg('completed')"
            :tooltip-format="`${notCompletedCount || 0}`"
          />
        </e-series-collection>
      </ejs-chart>
    </div>
  </div>
</template>

<script>
import { Browser } from '@syncfusion/ej2-base';
import {
  ChartComponent,
  SeriesDirective,
  SeriesCollectionDirective,
  StackingBarSeries,
  Category,
  Tooltip,
  Legend,
  Highlight,
  DataLabel,
  ColumnSeries,
} from '@syncfusion/ej2-vue-charts';
import benchmarksMixin from './benchmarksMixin';

export default {
  components: {
    'ejs-chart': ChartComponent,
    'e-series-collection': SeriesCollectionDirective,
    'e-series': SeriesDirective,
  },
  mixins: [benchmarksMixin],
  provide: {
    chart: [
      StackingBarSeries,
      Legend,
      Category,
      Tooltip,
      Highlight,
      DataLabel,
      ColumnSeries,
    ],
  },
  props: {
    campaignReport: {
      type: Object,
      default: () => {},
    },
    defaultTextColor: {
      type: String,
      default: '',
    },
    currentDisplayTheme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // Initializing Primary Y Axis
      primaryYAxis: {
        lineStyle: { width: 0 },
        majorTickLines: { width: 0 },
        labelFormat: '{value}', // Texto que aparece na porcentagem, caso queira colocar use {value}%
        visible: false, // Se a barra de procentagem vai aparecer
        edgeLabelPlacement: 'Shift',
      },
      // Area do gráfico
      chartArea: {
        border: { width: 0 },
      },

      width: Browser.isDevice ? '100%' : '75%',
      legend: {
        alignment: 'Far',
        enableHighlight: true,
        visible: true,
        height: '50',
        width: '570',
      },
      // Tooltip de cada barra
      tooltip: {
        enable: true,
        // eslint-disable-next-line no-template-curly-in-string
        format: '<b>${point.y}</b>', // Header -> '<b>${point.x}<b>' OU Valor -> '<b>${point.y}<b>'
        duration: 100,
      },

      // border: { color: '#ffffff', width: 1 }, // Estilo da borda de cada barra

      // Número dentro da barra horizontal (mostra valor do eixo y)
      marker: {
        dataLabel: {
          visible: true,
          position: 'Bottom',
          font: {
            fontWeight: '900',
            fontFamily: 'var($base-font-family)',
            color: '#c0c3ca',
            size: '0.7rem',
          },
          border: { width: 2 },
        },
      },
      // Estilos dos border radius das barras
      rightSideCornerRadius: {
        bottomRight: 10,
        topRight: 10,
      },
      leftSideCornerRadius: {
        bottomLeft: 10,
        topLeft: 10,
      },
      bothSidesCornerRadius: {
        bottomLeft: 10,
        topLeft: 10,
        bottomRight: 10,
        topRight: 10,
      },
      imagesUrl: {
        delivered: '/assets/images/dashboard/campaigns/delivered_icon.png',
        read: '/assets/images/dashboard/campaigns/read_icon.png',
        answered: '/assets/images/dashboard/campaigns/answered_icon.png',
        completed: '/assets/images/dashboard/campaigns/completed_icon.png',
      },
    };
  },
  computed: {
    deliveredCount() {
      const readMessages =
        this.countObj.read + this.countObj.answered + this.countObj.completed;
      const deliveredMessages = this.countObj.delivered + readMessages;
      return deliveredMessages;
    },
    notDeliveredCount() {
      return this.alreadySentCount - this.deliveredCount;
    },
    completedCount() {
      return this.countObj.completed || 0;
    },
    notCompletedCount() {
      return this.alreadySentCount - this.completedCount;
    },
    answeredCount() {
      const interactedMessages =
        this.countObj.answered + this.countObj.completed;
      return interactedMessages;
    },
    notAnsweredCount() {
      return this.alreadySentCount - this.answeredCount;
    },
    readCount() {
      const readMessages =
        this.countObj.read + this.countObj.answered + this.countObj.completed;
      return readMessages;
    },
    notReadCount() {
      return this.alreadySentCount - this.readCount;
    },

    completedPercentage() {
      return this.statusPercentages.completed || 0;
    },
    answeredPercentage() {
      return this.statusPercentages.answered || 0;
    },
    readPercentage() {
      return this.statusPercentages.read || 0;
    },
    deliveredPercentage() {
      return this.statusPercentages.delivered || 0;
    },
    seriesData() {
      return [
        {
          Status: this.$t('CAMPAIGN.REPORT.STATUS.COMPLETED'),
          CompletedCount: this.completedCount,
          CompletedPercentage: this.completedPercentage,
          notCompletedCount: this.notCompletedCount,
          notCompletedPercentage: 100 - this.completedPercentage || 0,
        },
        {
          Status: this.$t('CAMPAIGN.REPORT.STATUS.ANSWERED'),
          AnsweredCount: this.answeredCount,
          AnsweredPercentage: this.answeredPercentage,
          notAnsweredCount: this.notAnsweredCount,
          notAnsweredPercentage: 100 - this.answeredPercentage || 0,
        },
        {
          Status: this.$t('CAMPAIGN.REPORT.STATUS.READ'),
          ReadCount: this.readCount,
          ReadPercentage: this.readPercentage,
          notReadCount: this.notReadCount,
          notReadPercentage: 100 - this.readPercentage || 0,
        },
        {
          Status: this.$t('CAMPAIGN.REPORT.STATUS.DELIVERED'),
          DeliveredCount: this.deliveredCount,
          DeliveredPercentage: this.deliveredPercentage,
          notDeliveredCount: this.notDeliveredCount,
          notDeliveredPercentage: 100 - this.deliveredPercentage || 0,
        },
      ];
    },
    titleStyle() {
      return {
        fontFamily: 'var($base-font-family)',
        size: '1.25rem',
        textAlignment: 'Near', // Alinha o texto à esquerda
        color: this.defaultTextColor,
      };
    },
    subtitleStyle() {
      return {
        fontFamily: 'var($base-font-family)',
        fontWeight: 'regular', // Para deixar em negrito colocar 'bold'
        textAlignment: 'Near', // Alinha o texto à esquerda
        color: this.defaultTextColor,
        size: '0.875rem',
      };
    },
    primaryXAxis() {
      return {
        valueType: 'Category',
        lineStyle: { width: 15, color: 'transparent' }, // Linha vertical entre coluna vertical e barras
        // maximumLabelWidth: '22',
        // crossesAt: 15, // Trazer as barras mais para dentro da label
        majorGridLines: { width: 0 }, // Espaços entre divisória entre cada barra de status
        majorTickLines: { width: 0 }, // Espaços entre entre o nome do status e a barra
        // labelPosition: 'Inside', // Label do eixo dentro da barra
        // ESTILO DO TEXTO DOS STATUS
        labelStyle: {
          size: '0.875rem',
          fontFamily: 'var($base-font-family)',
          // textAlignment: 'center', // Alinha o texto à esquerda
          color: this.defaultTextColor,
        },
      };
    },
  },
  created() {},
  mounted() {
    this.primaryYAxis.maximum = this.totalCount; // Até quanto vai a porcentagem do eixo horizontal
  },
  methods: {
    markerWithImg(status) {
      const widthAndHeight = status === 'sent' ? 15 : 20;
      return {
        visible: true,
        offset: {
          x: -20, // Quantos pixel para direita o marker vai andar
          y: 0, // Quantos pixel para cima o marker vai andar
        },
        width: widthAndHeight,
        height: widthAndHeight,
        shape: 'Image',
        imageUrl: this.imagesUrl[status],
      };
    },
  },
};
</script>

<style scoped></style>
