<template>
  <div class="control-section hsm-preview-container">
    <!-- DETAILS CONTAINER -->
    <div class="flex ml-0 flex-col h-full">
      <div class="flex justify-between responsive-content">
        <h5 class="text-2xl text-slate-800 dark:text-slate-100 mr-2 mb-4">
          {{ $t('CAMPAIGN.REPORT.DETAILS.TEMPLATE_PREVIEW') }}
        </h5>
      </div>
      <div class="hsm-preview-content">
        <p class="text-slate-800 dark:text-slate-100" style="font-size: 0.9rem">
          <WhatsappMessagePreview
            :template="template"
            :message="commonWhatsappMessage"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import campaignMixin from 'shared/mixins/campaignMixin';
import timeMixin from 'dashboard/mixins/time';
import WhatsappMessagePreview from 'dashboard/routes/dashboard/settings/campaigns/WhatsappMessagePreview.vue';

export default {
  components: {
    WhatsappMessagePreview,
  },
  mixins: [campaignMixin, timeMixin],
  props: {
    message: {
      type: String,
      default: '',
    },
    inbox: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      default: () => {},
    },
    campaignReport: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    template() {
      return JSON.parse(this.campaign.message);
    },
    commonWhatsappMessage() {
      return JSON.parse(this.campaign.message)?.text || '';
    },
  },
  methods: {
    async loadCampaignDetails(campaignId) {
      await this.$store.dispatch('campaigns/fetchById', campaignId);
    },
  },
};
</script>

<style lang="scss" scoped>
.hsm-preview-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.hsm-preview-content {
  max-height: 100%;
}
</style>
