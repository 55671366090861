<template>
  <div class="responsive-container flex flex-col">
    <!-- Base Quality Title -->
    <div class="title">
      <h5 class="text-2xl text-slate-800 dark:text-slate-100 mr-2 mb-4">
        {{ $t('CAMPAIGN.REPORT.BASE_QUALITY.TITLE') }}
      </h5>
    </div>

    <!-- Base Quality and Speedometer container -->
    <div class="flex flex-row">
      <!-- Base quality container -->
      <div
        class="base-quality-container flex flex-col w-full text-slate-600 dark:text-slate-400"
      >
        <!-- START 'NUMBER OF RECIPIENTS' SECTION -->
        <div class="recipients-container">
          <span class="section-title text-sm font-bold">
            {{ $t('CAMPAIGN.REPORT.DETAILS.TARGET_AUDIENCE_QUANTITY') }}
          </span>
          <p
            class="text-slate-600 dark:text-slate-400"
            style="font-size: 0.9rem"
          >
            <fluent-icon
              icon="people"
              class="inline text-slate-600 dark:text-slate-400"
            />
            {{ audienceCount }}
          </p>
        </div>
        <!-- END 'NUMBER OF RECIPIENTS' SECTION -->
        <!-- Valor Planejadas -->
        <div class="planned-container">
          <span class="section-title text-sm font-bold">
            {{ $t('CAMPAIGN.REPORT.BASE_QUALITY.PLANNED') }}
          </span>
          <p
            class="text-slate-600 dark:text-slate-400"
            style="font-size: 0.9rem"
          >
            <fluent-icon
              icon="send-clock"
              class="inline text-slate-600 dark:text-slate-500"
            />
            {{ totalCount }}
          </p>
        </div>
        <!-- Valor Enviadas -->
        <div class="sent-container">
          <span class="section-title text-sm font-bold">
            {{ $t('CAMPAIGN.REPORT.BASE_QUALITY.SENT') }}
          </span>
          <p
            class="text-slate-600 dark:text-slate-400"
            style="font-size: 0.9rem"
          >
            <fluent-icon
              icon="send"
              class="inline text-slate-600 dark:text-slate-500"
            />
            {{ alreadySentCount }}
          </p>
        </div>
      </div>

      <!-- SPEEDOMETER -->
      <div class="speedometer-container w-full mt-20">
        <vue-speedometer
          :width="250"
          :needle-height-ratio="0.7"
          :value="baseQuality ? baseQuality : 0"
          :custom-segment-stops="[0, 200, 500, 1000]"
          :current-value-text="`${Math.round(
            baseQuality ? baseQuality / 10 : 0
          )}%`"
          :segment-colors="['#FF0000', '#FFFF00', '#008000']"
          :custom-segment-labels="[
            {
              text: $t('CAMPAIGN.REPORT.BASE_QUALITY.LEGEND_BAD'),
              position: 'OUTSIDE',
              fontSize: '15px',
            },
            {
              text: $t('CAMPAIGN.REPORT.BASE_QUALITY.LEGEND_GOOD'),
              position: 'OUTSIDE',
              fontSize: '15px',
            },
            {
              text: $t('CAMPAIGN.REPORT.BASE_QUALITY.LEGEND_EXCELLENT'),
              position: 'OUTSIDE',
              fontSize: '15px',
            },
          ]"
          :ring-width="47"
          :needle-transition-duration="3333"
          needle-transition="easeElastic"
          needle-color="#676f74"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueSpeedometer from 'vue-speedometer';
import benchmarksMixin from './benchmarksMixin';

export default {
  components: { VueSpeedometer },
  mixins: [benchmarksMixin],
  provide: {},
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      default: () => {},
    },
    campaignReport: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    baseQuality() {
      return Math.round((this.alreadySentCount / this.countObj.all) * 1000);
    },
    audienceCount() {
      const zeroCountMessage = this.$t(
        'CAMPAIGN.REPORT.DETAILS.CAMPAIGN_MESSAGES_COUNT_IS_ZERO'
      );
      if (this.isOneOffType) {
        const csv_data = this.campaign.audience[0]?.csv_data;
        if (csv_data === '[]') return 0;
        return this.campaign.campaign_messages_count || zeroCountMessage;
      }
      return this.campaignReport.messages_count || zeroCountMessage;
    },
  },
  methods: {},
};
</script>
<style>
.responsive-container {
  flex: 1;
}

.speedometer-container {
  max-width: 250px;
}

.segment-label {
  color: var(--slate-600);
  /* Dark mode */
  @media (prefers-color-scheme: dark) {
    color: var(--slate-500);
  }
}
@media (max-height: 720px) {
  .text-sm {
    font-size: 0.75rem; /* Ajuste para reduzir o tamanho da fonte */
  }
}
</style>
