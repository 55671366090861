<template>
  <div class="responsive-container">
    <div ref="progressContainer" class="progress-bar-container w-full">
      <h5 class="text-2xl text-left text-slate-800 dark:text-slate-200">
        {{ $t('CAMPAIGN.REPORT.PROGRESS_BAR.TITLE') }}
      </h5>
      <div ref="progressBar" class="progress-bar flex w-full mt-4" />
    </div>
  </div>
</template>

<script>
import { ProgressBar } from '@syncfusion/ej2-progressbar';
import BenchmarksMixin from 'dashboard/routes/dashboard/settings/campaigns/reports/benchmarksMixin';

export default {
  mixins: [BenchmarksMixin],
  props: {
    campaignReport: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      progressBarInstance: null,
    };
  },
  watch: {
    campaignReport: {
      handler() {
        this.updateProgressBar();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateProgressBar();
  },
  methods: {
    updateProgressBar() {
      if (this.progressBarInstance) {
        this.progressBarInstance.destroy();
        this.progressBarInstance = null;
      }

      const value = this.progressBar ? this.progressBar : 0;
      const textAlignment = value < 10 ? 'Far' : 'Center';

      let percentageProgress = new ProgressBar({
        type: 'Linear',
        width: '100%',
        value: ` ${this.progressBar ? this.progressBar : 0}`,
        showProgressValue: true,
        trackThickness: 40, // Espessura da trilha
        trackColor: '#151718',
        progressThickness: 40, // Espessura do progresso
        progressColor: '#147CFF',
        cornerRadius: 'Square',
        // Fonte do label dentro da barra de progresso
        labelStyle: {
          color: '#FFFFFF',
          size: '28px',
          fontFamily: 'PlusJakarta',
          fontWeight: 'bold',
          textAlignment: textAlignment,
        },
        animation: {
          enable: true,
          duration: 2000,
          delay: 0,
        },
      });

      percentageProgress.appendTo(
        this.$refs.progressBar ? this.$refs.progressBar : 0
      );
      this.progressBarInstance = percentageProgress;
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
</style>
