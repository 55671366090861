import { MESSAGE_TYPE } from 'shared/constants/messages';
import { applyPageFilters, sortComparator } from './helpers';

export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

/**
 * Deprecated: O pessoal do Chatwoot já fez uma nova lógica dentro do helper sortComparator,
 * então vou levar as modificações feitas nessa função até agora (sort_on_agent_last_seen_at) para lá
 */
// const sortComparator = {
//   latest: (a, b) => b.last_activity_at - a.last_activity_at,
//   sort_on_created_at: (a, b) => a.created_at - b.created_at,
//   sort_on_priority: (a, b) => {
//     return (
//       CONVERSATION_PRIORITY_ORDER[a.priority] -
//       CONVERSATION_PRIORITY_ORDER[b.priority]
//     );
//   },
//   sort_on_waiting_since: (a, b) => {
//     if (!a.waiting_since && !b.waiting_since) {
//       return a.created_at - b.created_at;
//     }

//     if (!a.waiting_since) {
//       return 1;
//     }

//     if (!b.waiting_since) {
//       return -1;
//     }

//     return a.waiting_since - b.waiting_since;
//   },
//   sort_on_agent_last_seen_at: (a, b) => {
//     if (a.unread_count > 0 && b.unread_count === 0) {
//       return -1;
//     }
//     if (a.unread_count === 0 && b.unread_count > 0) {
//       return 1;
//     }
//     return b.last_activity_at - a.last_activity_at;
//   },
// };

// getters
const getters = {
  getAllConversations: ({ allConversations, chatSortFilter: sortKey }) => {
    return allConversations.sort((a, b) => sortComparator(a, b, sortKey));
  },
  getSelectedChat: ({ selectedChatId, allConversations }) => {
    const selectedChat = allConversations.find(
      conversation => conversation.id === selectedChatId
    );
    return selectedChat || {};
  },
  getSelectedChatAttachments: (_state, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    return selectedChat.attachments || [];
  },
  getLastEmailInSelectedChat: (stage, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    const { messages = [] } = selectedChat;
    const lastEmail = [...messages].reverse().find(message => {
      const {
        content_attributes: contentAttributes = {},
        message_type: messageType,
      } = message;
      const { email = {} } = contentAttributes;
      const isIncomingOrOutgoing =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.INCOMING;
      if (email.from && isIncomingOrOutgoing) {
        return true;
      }
      return false;
    });

    return lastEmail;
  },
  getMineChats: (_state, _, __, rootGetters) => activeFilters => {
    const currentUserID = rootGetters.getCurrentUser?.id;

    return _state.allConversations.filter(conversation => {
      const { assignee } = conversation.meta;
      const isAssignedToMe = assignee && assignee.id === currentUserID;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isChatMine = isAssignedToMe && shouldFilter;

      return isChatMine;
    });
  },
  getAppliedConversationFilters: _state => {
    return _state.appliedFilters;
  },
  getUnAssignedChats:
    (_state, _, __, rootGetters) => (activeFilters, teamsList) => {
      // Filtra as conversas caso seja um agente que selecionou o filtro 'NÃO ATRIBUIDO'
      const currentUser = rootGetters.getCurrentUser;
      if (currentUser?.role === 'agent') {
        return _state.allConversations.filter(conversation => {
          const { team } = conversation.meta;
          const isUnAssigned = !conversation.meta.assignee;

          const assignedToAgentTeam =
            team &&
            teamsList &&
            teamsList.find(e => e.id === team.id).is_member;
          const shouldFilter = applyPageFilters(conversation, activeFilters);

          return isUnAssigned && shouldFilter && (!team || assignedToAgentTeam);
        });
      }

      return _state.allConversations.filter(conversation => {
        const isUnAssigned = !conversation.meta.assignee;
        const shouldFilter = applyPageFilters(conversation, activeFilters);
        return isUnAssigned && shouldFilter;
      });
    },
  getAllStatusChats:
    (_state, _, __, rootGetters) => (activeFilters, teamsList) => {
      const currentUser = rootGetters.getCurrentUser;

      // Filtra as conversas caso seja um agente que selecionou o filtro 'TODOS'
      if (currentUser?.role === 'agent') {
        return _state.allConversations.filter(conversation => {
          const { assignee, team } = conversation.meta;
          const isAssignedToMe = assignee && assignee.id === currentUser?.id;

          const isUnAssigned = !conversation.meta.assignee;
          const assignedToAgentTeam =
            team &&
            teamsList &&
            teamsList.find(e => e.id === team.id).is_member;
          const assignedToTeamAndNoAgent = assignedToAgentTeam && isUnAssigned;
          const noTeamAndNoAgent = isUnAssigned && !team;

          const shouldFilter = applyPageFilters(conversation, activeFilters);

          return (
            shouldFilter &&
            (isAssignedToMe || assignedToTeamAndNoAgent || noTeamAndNoAgent)
          );
        });
      }
      return _state.allConversations.filter(conversation => {
        const shouldFilter = applyPageFilters(conversation, activeFilters);
        return shouldFilter;
      });
    },
  getChatListLoadingStatus: ({ listLoadingStatus }) => listLoadingStatus,
  getAllMessagesLoaded(_state) {
    const [chat] = getSelectedChatConversation(_state);
    return !chat || chat.allMessagesLoaded === undefined
      ? false
      : chat.allMessagesLoaded;
  },
  getUnreadCount(_state) {
    const [chat] = getSelectedChatConversation(_state);
    if (!chat) return [];
    return chat.messages.filter(
      chatMessage =>
        chatMessage.created_at * 1000 > chat.agent_last_seen_at * 1000 &&
        chatMessage.message_type === 0 &&
        chatMessage.private !== true
    ).length;
  },
  getChatStatusFilter: ({ chatStatusFilter }) => chatStatusFilter,
  getChatSortFilter: ({ chatSortFilter }) => chatSortFilter,
  getSelectedInbox: ({ currentInbox }) => currentInbox,
  getConversationById: _state => conversationId => {
    return _state.allConversations.find(
      value => value.id === Number(conversationId)
    );
  },
  getConversationParticipants: _state => {
    return _state.conversationParticipants;
  },
  getConversationLastSeen: _state => {
    return _state.conversationLastSeen;
  },
};

export default getters;
