<template>
  <ejs-chart
    id="chartcontainer1"
    style="display: block"
    :title="$t('CAMPAIGN.REPORT.DELIVERY_DETAILS.TITLE')"
    :title-style="titleStyle"
    :sub-title="
      $t('CAMPAIGN.REPORT.DELIVERY_DETAILS.SUBTITLE') +
      ' (' +
      $t('CAMPAIGN.REPORT.DELIVERY_DETAILS.UNIT') +
      ')'
    "
    :sub-title-style="subtitleStyle"
    :primary-x-axis="primaryXAxis"
    :primary-y-axis="primaryYAxis"
    :chart-area="chartArea"
    width="100%"
    height="100%"
    :tooltip="tooltip"
    :legend-settings="legend"
    class="text-slate-900 dark:text-woot-50"
  >
    <e-series-collection>
      <e-series
        fill="#005a63"
        :data-source="seriesData"
        type="StackingBar100"
        x-name="Status"
        y-name="SentCount"
        :name="$t('CAMPAIGN.REPORT.STATUS.SENT')"
        width="2"
        column-width="0.6"
        :marker="marker"
        :corner-radius="
          // Checo se a barra de falhas é maior que zero
          !!failedPercentage
            ? leftSideCornerRadius
            : // Agora checo se já enviou 100% das mensagens
            sentPercentage === 100
            ? bothSidesCornerRadius
            : leftSideCornerRadius
        "
        :tooltip-format="`${updatedCount.sent || 0}`"
      />
      <e-series
        fill="#FF77A3"
        y-name="FailedCount"
        :name="$t('CAMPAIGN.REPORT.STATUS.FAILED')"
        x-name="Status"
        :data-source="seriesData"
        type="StackingBar100"
        width="2"
        column-width="0.6"
        :corner-radius="
          !sentPercentage
            ? failedPercentage === 100
              ? bothSidesCornerRadius
              : rightSideCornerRadius
            : failedPercentage + sentPercentage === 100
            ? rightSideCornerRadius
            : failedPercentage
            ? rightSideCornerRadius
            : {}
        "
        :tooltip-format="`${updatedCount.failed || 0}`"
      />
    </e-series-collection>
  </ejs-chart>
</template>

<script>
import { Browser } from '@syncfusion/ej2-base';
import {
  ChartComponent,
  SeriesDirective,
  SeriesCollectionDirective,
  StackingBarSeries,
  Category,
  Tooltip,
  Legend,
  Highlight,
  DataLabel,
  ColumnSeries,
} from '@syncfusion/ej2-vue-charts';
import benchmarksMixin from './benchmarksMixin';

export default {
  components: {
    'ejs-chart': ChartComponent,
    'e-series-collection': SeriesCollectionDirective,
    'e-series': SeriesDirective,
  },
  mixins: [benchmarksMixin],
  provide: {
    chart: [
      StackingBarSeries,
      Legend,
      Category,
      Tooltip,
      Highlight,
      DataLabel,
      ColumnSeries,
    ],
  },
  props: {
    campaignReport: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    defaultTextColor: {
      type: String,
      default: '',
    },
    currentDisplayTheme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // Initializing Primary Y Axis
      primaryYAxis: {
        lineStyle: { width: 0 },
        majorTickLines: { width: 0 },
        labelFormat: '{value}', // Texto que aparece na porcentagem, caso queira colocar use {value}%
        visible: false, // Se a barra de procentagem vai aparecer
        edgeLabelPlacement: 'Shift',
      },
      // Area do gráfico
      chartArea: {
        border: { width: 0 },
      },

      width: Browser.isDevice ? '100%' : '75%',
      legend: {
        alignment: 'Center',
        enableHighlight: true,
        visible: true,
        height: '50',
      },
      // Tooltip de cada barra
      tooltip: {
        enable: true,
        // eslint-disable-next-line no-template-curly-in-string
        format: '<b>${point.y}</b>', // Header -> '<b>${point.x}<b>' OU Valor -> '<b>${point.y}<b>'
        duration: 100,
      },

      // Número dentro da barra horizontal (mostra valor do eixo y)
      marker: {
        dataLabel: {
          visible: true,
          position: 'Bottom',
          font: {
            fontWeight: '900',
            fontFamily: 'var($base-font-family)',
            color: '#c0c3ca',
            size: '0.7rem',
          },
          border: { width: 5 },
        },
      },
      // Estilos dos border radius das barras
      rightSideCornerRadius: {
        bottomRight: 10,
        topRight: 10,
      },
      leftSideCornerRadius: {
        bottomLeft: 10,
        topLeft: 10,
      },
      bothSidesCornerRadius: {
        bottomLeft: 10,
        topLeft: 10,
        bottomRight: 10,
        topRight: 10,
      },
      imagesUrl: {
        total: '/assets/images/dashboard/campaigns/total_icon.png',
        sent: '/assets/images/dashboard/campaigns/sent_icon.png',
      },
    };
  },
  computed: {
    sentPercentage() {
      return this.statusPercentages.sent || 0;
    },
    failedPercentage() {
      return this.statusPercentages.failed || 0;
    },
    seriesData() {
      return [
        {
          Status: this.$t('CAMPAIGN.REPORT.STATUS.SENT'),
          SentCount: this.alreadySentCount,
          FailedCount: this.failedCount,
        },
      ];
    },
    titleStyle() {
      return {
        fontFamily: 'var($base-font-family)',
        size: '1.25rem',
        textAlignment: 'Near', // Alinha o texto à esquerda
        color: this.defaultTextColor,
      };
    },
    subtitleStyle() {
      return {
        fontFamily: 'var($base-font-family)',
        fontWeight: 'regular', // Para deixar em negrito colocar 'bold'
        textAlignment: 'Near', // Alinha o texto à esquerda
        color: this.defaultTextColor,
        size: '0.875rem',
      };
    },
    primaryXAxis() {
      return {
        valueType: 'Category',
        lineStyle: { width: 0, color: 'transparent' }, // Linha vertical entre coluna vertical e barras
        // maximumLabelWidth: '22',
        // crossesAt: 15, // Trazer as barras mais para dentro da label
        majorGridLines: { width: 0 }, // Espaços entre divisória entre cada barra de status
        majorTickLines: { width: 0 }, // Espaços entre entre o nome do status e a barra
        // labelPosition: 'Inside', // Label do eixo dentro da barra
        // ESTILO DO TEXTO DOS STATUS
        labelStyle: {
          size: '0.875rem',
          fontFamily: 'var($base-font-family)',
          // textAlignment: 'center', // Alinha o texto à esquerda
          color: this.defaultTextColor,
        },
      };
    },
  },
  created() {},
  mounted() {
    this.primaryYAxis.maximum = this.totalCount; // Até quanto vai a porcentagem do eixo horizontal
  },
  methods: {
    markerWithImg(status) {
      const widthAndHeight = status === 'sent' ? 15 : 20;
      return {
        visible: true,
        offset: {
          x: -20, // Quantos pixel para direita o marker vai andar
          y: 0, // Quantos pixel para cima o marker vai andar
        },
        width: widthAndHeight,
        height: widthAndHeight,
        shape: 'Image',
        imageUrl: this.imagesUrl[status],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#chartcontainer1 {
  @apply flex align-middle justify-between;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  min-height: 200px;
}
</style>
