var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ejs-chart',{staticClass:"text-slate-900 dark:text-woot-50",staticStyle:{"display":"block"},attrs:{"id":"chartcontainer1","title":_vm.$t('CAMPAIGN.REPORT.DELIVERY_DETAILS.TITLE'),"title-style":_vm.titleStyle,"sub-title":_vm.$t('CAMPAIGN.REPORT.DELIVERY_DETAILS.SUBTITLE') +
    ' (' +
    _vm.$t('CAMPAIGN.REPORT.DELIVERY_DETAILS.UNIT') +
    ')',"sub-title-style":_vm.subtitleStyle,"primary-x-axis":_vm.primaryXAxis,"primary-y-axis":_vm.primaryYAxis,"chart-area":_vm.chartArea,"width":"100%","height":"100%","tooltip":_vm.tooltip,"legend-settings":_vm.legend}},[_c('e-series-collection',[_c('e-series',{attrs:{"fill":"#005a63","data-source":_vm.seriesData,"type":"StackingBar100","x-name":"Status","y-name":"SentCount","name":_vm.$t('CAMPAIGN.REPORT.STATUS.SENT'),"width":"2","column-width":"0.6","marker":_vm.marker,"corner-radius":// Checo se a barra de falhas é maior que zero
        !!_vm.failedPercentage
          ? _vm.leftSideCornerRadius
          : // Agora checo se já enviou 100% das mensagens
          _vm.sentPercentage === 100
          ? _vm.bothSidesCornerRadius
          : _vm.leftSideCornerRadius,"tooltip-format":`${_vm.updatedCount.sent || 0}`}}),_vm._v(" "),_c('e-series',{attrs:{"fill":"#FF77A3","y-name":"FailedCount","name":_vm.$t('CAMPAIGN.REPORT.STATUS.FAILED'),"x-name":"Status","data-source":_vm.seriesData,"type":"StackingBar100","width":"2","column-width":"0.6","corner-radius":!_vm.sentPercentage
          ? _vm.failedPercentage === 100
            ? _vm.bothSidesCornerRadius
            : _vm.rightSideCornerRadius
          : _vm.failedPercentage + _vm.sentPercentage === 100
          ? _vm.rightSideCornerRadius
          : _vm.failedPercentage
          ? _vm.rightSideCornerRadius
          : {},"tooltip-format":`${_vm.updatedCount.failed || 0}`}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }