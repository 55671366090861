<template>
  <header class="border-b border-slate-50 dark:border-slate-800">
    <div class="flex w-full justify-between">
      <div class="flex items-center max-w-full -translate-y-2">
        <h1
          class="text-xl text-slate-900 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis"
        >
          {{ $t('CAMPAIGN.FILTER.TITLE') }}
        </h1>
      </div>

      <div class="flex gap-x-2">
        <div class="w-[320px] flex search-wrap relative">
          <input
            v-model="searchQuery"
            type="text"
            :placeholder="$t('CAMPAIGN.FILTER.INPUT_SEARCH')"
            class="contact-search text-slate-900 dark:text-slate-100 pl-10"
            :style="{ 'padding-right': searchQuery ? '96px' : '0px' }"
            style="font-size: 1rem"
            @keyup.enter="emitSearch"
          />
          <fluent-icon
            icon="search"
            class="absolute left-2.5 top-1/2 transform -translate-y-5 h-5 leading-9 text-sm text-slate-900 dark:text-slate-100"
          />
          <woot-button
            v-if="searchQuery"
            :is-loading="false"
            class="clear"
            :class-names="searchButtonClass"
            @click="emitSearch"
          >
            {{ $t('CAMPAIGN.FILTER.SEARCH_BUTTON') }}
          </woot-button>
        </div>
        <!-- Filtro de status -->
        <div class="flex relative">
          <div
            v-if="hasAppliedFilters"
            class="absolute h-2 w-2 top-1 right-3 bg-slate-500 dark:bg-slate-500 rounded-full"
          />
          <woot-button
            v-tooltip.right="$t('CAMPAIGN.FILTER.STATUS_TOOLTIP')"
            variant="smooth"
            color-scheme="secondary"
            class="selector-button"
            icon="filter"
            @click="toggleFilter"
          />
          <div
            v-if="showStatusFilter"
            v-on-clickaway="closeStatusFilter"
            class="absolute top-10 left-0 text-slate-700 dark:text-slate-100 bg-white dark:bg-slate-800 border-slate-75 dark:border-slate-400 shadow-md p-4 rounded-md"
          >
            <div
              v-for="status in filterList"
              :key="status.id"
              class="flex items-center mb-2 border-slate-75 dark:border-slate-400 shadow-md"
            >
              <input
                :id="status.id"
                v-model="status.selected"
                type="checkbox"
                class="mr-2"
              />
              <label :for="status.id">{{ status.label }}</label>
            </div>
            <div class="flex gap-2 mt-2">
              <woot-button color-scheme="primary" @click="confirmStatusFilter">
                {{ $t('CAMPAIGN.FILTER.CONFIRM_BUTTON') }}
              </woot-button>
              <woot-button color-scheme="alert" @click="resetStatusFilter">
                {{ $t('CAMPAIGN.FILTER.RESET_BUTTON') }}
              </woot-button>
            </div>
          </div>
        </div>

        <!-- Filtro de data -->
        <div class="flex items-center relative">
          <date-picker
            v-model="dateRange"
            range
            format="YYYY-MM-DD"
            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('CAMPAIGN.FILTER.DATE_FILTER')"
            @change="applyDateFilter"
          />
        </div>
        <!-- Ordenação -->
        <div class="relative flex">
          <woot-button
            v-tooltip.right="$t('CAMPAIGN.FILTER.ORDERING_TOOLTIP')"
            variant="smooth"
            size="big"
            color-scheme="secondary"
            class="selector-button"
            icon="sort-icon"
            @click="toggleDropdown"
          />
          <div
            v-if="showActionsDropdown"
            v-on-clickaway="closeDropdown"
            class="absolute top-10 right-0 text-slate-700 dark:text-slate-100 bg-white dark:bg-slate-800 border-slate-75 dark:border-slate-400 shadow-md p-4 rounded-md z-10"
          >
            <div class="items-center flex justify-between">
              <span
                class="text-slate-800 dark:text-slate-100 text-xs font-medium"
              >
                {{ $t('CAMPAIGN.FILTER.ORDER_BY_LABEL') }}
              </span>

              <campaign-filter-item
                type="sort"
                :selected-value="sortFilter"
                :items="orderingList"
                @onChangeFilter="onChangeFilter"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import CampaignFilterItem from './CampaignFilterItem.vue';
import DatePicker from 'vue2-datepicker';

export default {
  components: {
    CampaignFilterItem,
    DatePicker,
  },
  mixins: [clickaway],
  data() {
    return {
      sortFilter: 'scheduled_at_desc',
      open: false,
      showDateFilter: false,
      dateRange: [],
      showActionsDropdown: false,
      searchQuery: '',
      showStatusFilter: false,
    };
  },
  computed: {
    filterList() {
      return [
        {
          id: 'active',
          label: this.$t('CAMPAIGN.FILTER.STATUS.ACTIVE'),
          selected: true,
        },
        {
          id: 'processing',
          label: this.$t('CAMPAIGN.FILTER.STATUS.PROCESSING'),
          selected: true,
        },
        {
          id: 'in_progress',
          label: this.$t('CAMPAIGN.FILTER.STATUS.IN_PROGRESS'),
          selected: true,
        },
        {
          id: 'completed',
          label: this.$t('CAMPAIGN.FILTER.STATUS.COMPLETED'),
          selected: true,
        },
        {
          id: 'error',
          label: this.$t('CAMPAIGN.FILTER.STATUS.ERROR'),
          selected: true,
        },
      ];
    },
    orderingList() {
      return {
        created_at_asc: this.$t('CAMPAIGN.FILTER.ORDER_BY.CREATED_AT_ASC'),
        created_at_desc: this.$t('CAMPAIGN.FILTER.ORDER_BY.CREATED_AT_DESC'),
        scheduled_at_asc: this.$t('CAMPAIGN.FILTER.ORDER_BY.SCHEDULED_AT_ASC'),
        scheduled_at_desc: this.$t(
          'CAMPAIGN.FILTER.ORDER_BY.SCHEDULED_AT_DESC'
        ),
      };
    },
    localSearchQuery: {
      get() {
        return this.searchQuery;
      },
      set(newValue) {
        this.$emit('update:searchQuery', newValue);
      },
    },
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    ...mapGetters({
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
    }),
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter;
    },
    applyDateFilter() {
      const [start_at, end_at] = this.dateRange.map(date =>
        date ? new Date(date).toISOString().split('T')[0] : ''
      );
      this.$emit('date-filter-change', { start_at, end_at });
      this.showDateFilter = false;
    },
    resetDateFilter() {
      this.dateRange = [];
      this.$emit('date-filter-change', { start_at: '', end_at: '' });
      this.showDateFilter = false;
    },
    toggleDropdown() {
      this.showActionsDropdown = !this.showActionsDropdown;
    },
    closeDropdown() {
      this.showActionsDropdown = false;
    },
    toggleFilter() {
      this.showStatusFilter = !this.showStatusFilter;
    },
    closeStatusFilter() {
      this.showStatusFilter = false;
    },
    emitSearch() {
      this.$emit('search', this.searchQuery);
    },
    onChangeFilter(value, type) {
      if (type === 'sort') {
        this.sortFilter = value;
        this.$emit('change-sort', value);
        this.closeDropdown(); // Fechar a janela de seleção
      }
    },
    confirmStatusFilter() {
      const selectedStatuses = this.filterList
        .filter(status => status.selected)
        .map(status => status.id);

      this.$emit('status-change', selectedStatuses);
      this.showStatusFilter = false;
    },
    resetStatusFilter() {
      this.filterList.forEach(status => {
        status.selected = true;
      });
      this.$emit('status-change', []);
      this.showStatusFilter = false;
    },
  },
};
</script>

<style scoped lang="scss">
.hidden-input {
  display: none;
}
.search-wrap {
  .contact-search {
    @apply pl-10 text-sm w-full h-[2.375rem] m-0;
  }

  .button {
    transition:
      opacity 100ms linear,
      transform 100ms linear;
    @apply right-1 absolute top-1/2 transform -translate-y-5 h-5 leading-9 text-sm text-slate-900 dark:text-slate-100;
  }
  .button.show {
    @apply opacity-100 visible;
  }
}
</style>
