import { LocalStorage } from 'shared/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';

export const setColorTheme = isOSOnDarkMode => {
  const selectedColorScheme =
    LocalStorage.get(LOCAL_STORAGE_KEYS.COLOR_SCHEME) || 'auto';
  if (
    (selectedColorScheme === 'auto' && isOSOnDarkMode) ||
    selectedColorScheme === 'dark'
  ) {
    document.body.classList.add('dark');
    document.documentElement.setAttribute('style', 'color-scheme: dark;');
  } else {
    document.body.classList.remove('dark');
    document.documentElement.setAttribute('style', 'color-scheme: light;');
  }
};

/** Para usar essa funcionalidade no componente Vue siga esses passos:
 * 1 - Importe a função no seu arquivo
 * 2 - Ative-a no mounted com 'getChangesOnTheme({{Variável que armazena o tema}}, this);'
 * 3 - Em methods adicione a função handleThemeChange(newTheme) e faça o que quiser dentro dela
 * @return {String}: 'dark' ou 'light'
 */
export const getChangesOnTheme = (observedColorScheme, vueInstance) => {
  const htmlElement = document.querySelector('html');
  const checkColorScheme = () => {
    const styles = window.getComputedStyle(htmlElement);
    const colorScheme = styles.getPropertyValue('color-scheme');

    if (colorScheme !== observedColorScheme) {
      observedColorScheme = colorScheme; // Atualiza a variável
      vueInstance.handleThemeChange(colorScheme); // Chama a função com o novo valor
    }
  };
  checkColorScheme();
  // Adiciona observer para captar alterações do tema
  const observer = new MutationObserver(checkColorScheme);
  observer.observe(htmlElement, { attributes: true });

  vueInstance.$once('hook:beforeDestroy', () => {
    observer.disconnect();
  });
};
