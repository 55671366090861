/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class Inboxes extends CacheEnabledApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'inbox';
  }

  getCampaigns(inboxId) {
    return axios.get(`${this.url}/${inboxId}/campaigns`);
  }

  deleteInboxAvatar(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/avatar`);
  }

  getAgentBot(inboxId) {
    return axios.get(`${this.url}/${inboxId}/agent_bot`);
  }

  setAgentBot(inboxId, botId) {
    return axios.post(`${this.url}/${inboxId}/set_agent_bot`, {
      agent_bot: botId,
    });
  }

  set_automatic_actions_settings(inboxId, automatic_actions_settings) {
    return axios.post(`${this.url}/${inboxId}/set_automatic_actions_settings`, {
      automatic_actions_settings: automatic_actions_settings,
    });
  }

  // Conversation resolution reasons api methods
  create_conversation_resolution_reason(inboxId, title, resolutionType) {
    return axios.post(
      `${this.url}/${inboxId}/conversation_resolution_reasons`,
      {
        title,
        resolution_type: resolutionType,
      }
    );
  }

  update_conversation_resolution_reason(
    inboxId,
    conversation_resolution_id,
    title,
    resolutionType
  ) {
    return axios.patch(
      `${this.url}/${inboxId}/conversation_resolution_reasons/${conversation_resolution_id}`,
      {
        title,
        resolution_type: resolutionType,
      }
    );
  }

  delete_conversation_resolution_reasons(inboxId, conversation_resolution_id) {
    return axios.delete(
      `${this.url}/${inboxId}/conversation_resolution_reasons/${conversation_resolution_id}`
    );
  }

  generate_default_conversation_resolution_reasons(inboxId) {
    return axios.post(
      `${this.url}/${inboxId}/conversation_resolution_reasons/generate_default_options`
    );
  }

  set_teimosinha_status(inboxId, teimosinha_status) {
    return axios.patch(`${this.url}/${inboxId}/set_teimosinha_status`, {
      teimosinha_status: teimosinha_status,
    });
  }

  set_teimosinha_message_settings(inboxId, messageId, teimosinha_settings) {
    return axios.patch(
      `${this.url}/${inboxId}/teimosinha_messages/${messageId}`,
      {
        ...teimosinha_settings,
      }
    );
  }

  destroy_teimosinha_message(inboxId, messageId) {
    return axios.delete(
      `${this.url}/${inboxId}/teimosinha_messages/${messageId}`
    );
  }

  create_teimosinha_message(inboxId, teimosinha_settings) {
    return axios.post(`${this.url}/${inboxId}/teimosinha_messages`, {
      ...teimosinha_settings,
    });
  }

  fetch_whatsapp_templates(inboxId) {
    return axios.post(`${this.url}/${inboxId}/sync_templates`);
  }
}

export default new Inboxes();
