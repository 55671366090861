<template>
  <div class="medium-12 columns">
    <!-- <textarea
      v-model="processedString"
      rows="4"
      readonly
      style="color: black"
      class="template-input"
    /> -->
    <p class="text-xs">Preview:</p>
    <whatsapp-message-preview :message="processedString" :template="template" />
    <file-upload
      v-if="templateWithMedia"
      ref="upload"
      input-id="campaignAttachment"
      :size="4096 * 4096"
      :accept="allowedFileTypes"
      :multiple="false"
      :drop="true"
      :drop-directory="false"
      :data="{
        direct_upload_url: '/rails/active_storage/direct_uploads',
        direct_upload: true,
      }"
      @input-file="onFileUpload"
    >
      <woot-button
        v-if="true"
        :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
        icon="attach"
        emoji="📎"
        color-scheme="secondary"
        variant="smooth"
        :style="mustUploadFile ? 'border: 1px solid red' : 'border: none'"
      >
        {{
          $t('WHATSAPP_TEMPLATES.PARSER.ATTACH') + ` ${currentFileExtension}`
        }}
      </woot-button>
      <span
        v-if="mustUploadFile || uploadedFileIsNotInCorrectExtension"
        class="pl-2 text-red-400 text-xs inline-block"
      >
        {{
          $t('WHATSAPP_TEMPLATES.PARSER.SUBMIT_FILE') +
          ` ${currentFileExtension}`
        }}
      </span>
    </file-upload>
    <div v-if="attachedFiles.length">
      <attachment-preview
        class="flex-col mt-4"
        :attachments="attachedFiles"
        :remove-attachment="removeAttachment"
      />
    </div>
    <div v-if="variables" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_LABEL') }}
      </p>
      <div
        v-for="(variable, key) in processedParams"
        :key="key"
        class="template__variable-item"
      >
        <p class="variable-label" style="color: black">
          {{ fieldLabels[key - 1] }}
        </p>
        <param-type-picker
          :value="processedParams[key]"
          :csv-headers="csvHeaders"
          :field-labels="fieldLabels"
          :is-csv-audience="isCsvAudience"
          :is-ongoing="isOngoing"
          :available-contact-infos="available_contact_infos"
          @change-field-value="onFieldChange($event, key)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ParamTypePicker from './ParamTypePicker.vue';
import WhatsappMessagePreview from '../../../../routes/dashboard/settings/campaigns/WhatsappMessagePreview.vue';
import FileUpload from 'vue-upload-component';
import fileUploadMixin from 'dashboard/mixins/fileUploadMixin';
import whatsappTemplateMixin from 'shared/mixins/whatsappTemplateMixin';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview.vue';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    ParamTypePicker,
    WhatsappMessagePreview,
    FileUpload,
    AttachmentPreview,
  },
  mixins: [fileUploadMixin, whatsappTemplateMixin, alertMixin],
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    firstExample: {
      type: Object,
      default: () => {},
    },
    initialParams: {
      type: Array,
      default: () => [],
    },
    csvHeaders: {
      type: Array,
      default: () => [],
    },
    isCsvAudience: {
      type: Boolean,
      default: false,
    },
    isOngoing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processedParams: {},
      attachedFiles: [],
      paramsConfig: {},
      available_contact_infos: [
        { key: 'Dados do contato', value: 'disabled' },
        { key: 'Nome', value: 'contact.name' },
        { key: 'Primeiro nome', value: 'contact.first_name' },
        { key: 'Último nome', value: 'contact.last_name' },
        { key: 'E-mail', value: 'contact.email' },
        { key: 'Telefone', value: 'contact.phone' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      currentChat: 'getSelectedChat',
    }),
    fieldLabels() {
      return this.bodyComponent?.example?.body_text[0];
    },
    variables() {
      const variables = this.templateString.match(/{{([^}]+)}}/g);
      return variables;
    },
    templateString() {
      return this.template.components.find(
        component => component.type === 'BODY'
      ).text;
    },
    processedString() {
      return this.templateString.replace(/{{([^}]+)}}/g, (match, variable) => {
        const variableKey = this.processVariable(variable);
        return this.processedParams[variableKey] || `{{${variable}}}`;
      });
    },
  },
  mounted() {
    this.generateVariables();
  },
  methods: {
    processVariable(str) {
      return str.replace(/{{|}}/g, '');
    },
    generateVariables() {
      const matchedVariables = this.templateString.match(/{{([^}]+)}}/g);
      if (!matchedVariables) return;

      const variables = matchedVariables.map(i => this.processVariable(i));
      this.processedParams = variables.reduce((acc, variable) => {
        acc[variable] = '';
        return acc;
      }, {});
      Object.keys(this.processedParams).forEach(key => {
        this.paramsConfig[key] = {
          isFromCSV: false,
          value: '',
          field: '',
        };
      });
    },
    onFieldChange(event, index) {
      const { type, value, fieldSelected } = event;
      if (type === 'fixed' || type === 'origin') {
        this.processedParams[index] = this.replaceIfInLabelOpts(value);
        this.paramsConfig[index].isFromCSV = false;
        this.paramsConfig[index].value = value;
        this.paramsConfig[index].paramType = type;
      } else {
        this.processedParams[index] = this.firstExample[fieldSelected];
        this.paramsConfig[index].isFromCSV = true;
        this.paramsConfig[index].field = fieldSelected;
      }
      this.$emit('template-update', this.paramsConfig);
    },
    replaceIfInLabelOpts(val) {
      if (!this.available_contact_infos.some(e => e.value === val)) return val;
      return this.available_contact_infos.find(e => e.value === val).key;
    },
    attachFile({ blob, file }) {
      // If another file is being uploaded we remove the first one
      this.attachedFiles = [];
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
      this.propagateAttachments();
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
      this.propagateAttachments();
    },
    propagateAttachments() {
      this.$emit('attachments-change', this.attachedFiles);
    },
  },
};
</script>

<style scoped lang="scss">
.template__variables-container {
  padding: var(--space-one);
}

.variables-label {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-one);
}

.template__variable-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-one);

  .label {
    font-size: var(--font-size-mini);
  }

  .variable-input {
    flex: 1;
    font-size: var(--font-size-small);
    margin-left: var(--space-one);
  }

  .variable-label {
    text-align: center;
    width: 100%;
    background-color: var(--s-75);
    border-radius: var(--border-radius-normal);
    display: inline-block;
    font-size: var(--font-size-mini);
    padding: var(--space-one) var(--space-medium);
  }
}

.error {
  background-color: var(--r-100);
  border-radius: var(--border-radius-normal);
  color: var(--r-800);
  padding: var(--space-one);
  text-align: center;
}
.template-input {
  background-color: var(--s-25);
}
</style>
