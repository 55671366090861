<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('CAMPAIGN.ONGOING.ADD.TITLE')"
      :header-content="$t('CAMPAIGN.ONGOING.ADD.DESC')"
    />
    <form class="flex flex-col w-full" @submit.prevent="addCampaign">
      <div class="w-full">
        <!-- START TITLE INPUT -->
        <woot-input
          v-model="title"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />
        <!-- END TITLE INPUT -->

        <!-- START DESCRIPTION SECTION -->
        <label>
          {{ $t('CAMPAIGN.ONGOING.MODAL.DESCRIPTION') }}
          <textarea
            v-model="description"
            rows="2"
            type="text"
            :placeholder="$t('CAMPAIGN.ONGOING.MODAL.DESCRIPTION_PLACEHOLDER')"
          />
        </label>
        <!-- END DESCRIPTION SECTION -->

        <!-- START RECURRENCY -->
        <input-radio-group
          :label="$t('CAMPAIGN.ONGOING.MODAL.RECURRENCY.HEADER')"
          :items="recurrencyOptions"
          :action="handleRecurrencyChange"
        />
        <label
          v-if="
            selectedRecurrency === 'monthly' || selectedRecurrency === 'weekly'
          "
          class="multiselect-wrap--small"
          :class="{ error: $v.selectedDays.$error }"
        >
          <multiselect
            v-model="selectedDays"
            :options="daysOptionsList"
            track-by="title"
            label="title"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.ONGOING.MODAL.SELECT_DATE')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            @blur="$v.selectedDays.$touch"
            @select="$v.selectedDays.$touch"
          />
          <span v-if="$v.selectedDays.$error" class="editor-warning__message">
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
          </span>
        </label>
        <!-- END RECURRENCY -->

        <!-- START TRIGGER HOUR -->
        <div>
          <label>{{ $t('CAMPAIGN.ONGOING.MODAL.TRIGGER_TIME') }}</label>
          <date-picker
            v-model="time"
            type="time"
            format="H:mm"
            :minute-step="5"
            :hour-options="Array.from({ length: 21 }, (_, i) => i)"
            value-type="format"
            :confirm="true"
            :clearable="false"
            :editable="true"
            :confirm-text="$t('CAMPAIGN.ONGOING.MODAL.SELECT_TIME')"
          />
        </div>
        <!-- END TRIGGER HOUR -->

        <!-- START DATA SOURCE INPUT -->
        <woot-input
          v-model="dataSourceUrl"
          :label="$t('CAMPAIGN.ONGOING.MODAL.DATA_SOURCE.LABEL')"
          type="text"
          :class="{ error: $v.dataSourceUrl.$error }"
          :error="
            $v.dataSourceUrl.$error
              ? $t('CAMPAIGN.ONGOING.MODAL.DATA_SOURCE.ERROR')
              : ''
          "
          :placeholder="$t('CAMPAIGN.ONGOING.MODAL.DATA_SOURCE.PLACEHOLDER')"
          @blur="$v.dataSourceUrl.$touch"
        />
        <!-- END DATA SOURCE INPUT -->

        <!-- START INBOX MULTISELECT -->
        <label :class="{ error: $v.selectedInbox.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
          <select v-model="selectedInbox">
            <option
              v-for="item in campaignInboxes"
              :key="item.name"
              :value="item"
            >
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.selectedInbox.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
          </span>
        </label>
        <!-- END INBOX MULTISELECT -->

        <!-- START MESSAGE LABEL -->
        <div v-if="hasWhatsappTemplates">
          <templates-picker
            v-if="!template"
            :inbox-id="inboxSelected"
            @click.stop
            @onSelect="toggleWaTemplate"
          />
          <campaign-template-parser
            v-else
            :template="template"
            :csv-headers="[]"
            :first-example="{}"
            :is-csv-audience="true"
            :is-ongoing="true"
            @template-update="onTemplateUpdate"
            @attachments-change="updateAttachments"
          />
          <!-- RESET TEMPLATE -->
          <woot-button
            v-if="template"
            ref="arrowDownButton"
            color-scheme="secondary"
            class="mt-2 mb-3 flex"
            icon="chevron-down"
            @click.prevent
            @click="resetWaTemplateSelection"
          >
            {{ $t('CAMPAIGN.TEMPLATE_PARSER.CHOOSE_ANOTHER_BUTTON') }}
          </woot-button>
        </div>

        <div v-else-if="isCommonWhatsappCampaign">
          <common-whatsapp-campaign-message
            :origin-params="true"
            :is-csv-audience="false"
            @on-change-message="onChangeMessageCommonWhatsapp"
          />

          <common-whatsapp-intervals-select
            @selected-interval="onChangeIntervalSelected"
          />
        </div>
        <!-- END MESSAGE LABEL -->
      </div>

      <!-- START BOT MULTISELECT -->
      <label>
        {{ $t('CAMPAIGN.ADD.FORM.BOT.LABEL') }}
        <select v-model="selectedAgentBotId">
          <option :value="null" selected>
            {{ $t('CAMPAIGN.ADD.FORM.BOT.INBOX_DEFAULT_OPTION') }}
          </option>
          <option
            v-for="agentBot in agentBots"
            :key="agentBot.id"
            :value="agentBot.id"
          >
            {{ agentBot.name }}
          </option>
        </select>
      </label>
      <!-- END BOT MULTISELECT -->

      <!-- START FILTERS INPUT -->
      <woot-input
        v-model="filters"
        :label="$t('CAMPAIGN.ONGOING.MODAL.FILTERS.LABEL')"
        type="text"
        :class="{ error: $v.filters.$error }"
        :error="
          $v.filters.$error ? $t('CAMPAIGN.ONGOING.MODAL.FILTERS.ERROR') : ''
        "
        :placeholder="$t('CAMPAIGN.ONGOING.MODAL.FILTERS.PLACEHOLDER')"
        @blur="$v.filters.$touch"
      />
      <!-- END FILTERS INPUT -->

      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <woot-button
          :is-loading="uiFlags.isCreating"
          :disabled="badCampaignParams"
          type="submit"
        >
          {{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import ongoingCampaignMixin from 'shared/mixins/ongoingCampaignMixin';
import whatsappTemplateMixin from 'shared/mixins/whatsappTemplateMixin';

import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import CampaignTemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/CampaignTemplateParser.vue';
import DatePicker from 'vue2-datepicker';
import InputRadioGroup from 'dashboard/routes/dashboard/settings/inbox/components/InputRadioGroup.vue';

import CommonWhatsappIntervalsSelect from '../CommonWhatsappCampaign/CommonWhatsappIntervalsSelect.vue';
import CommonWhatsappCampaignMessage from '../CommonWhatsappCampaign/CommonWhatsappCampaignMessage.vue';

export default {
  components: {
    TemplatesPicker,
    CampaignTemplateParser,
    DatePicker,
    InputRadioGroup,
    CommonWhatsappIntervalsSelect,
    CommonWhatsappCampaignMessage,
  },
  mixins: [
    alertMixin,
    campaignMixin,
    whatsappTemplateMixin,
    ongoingCampaignMixin,
  ],
  props: {},
  data() {
    return {
      title: '',
      description: '',
      message: '',
      dataSourceUrl: '',
      time: '',
      selectedInbox: null,
      messageParams: [],
      template: null, // selected WhatsApp Template
      filters: '',
      selectedInterval: [],
      selectedAgentBotId: null,
    };
  },

  computed: {
    ...mapGetters({
      uiFlags: 'ongoingCampaigns/getUIFlags',
      currentUser: 'getCurrentUser',
      campaigns: 'ongoingCampaigns/getAllCampaigns',
      agentBots: 'agentBots/getBots',
    }),
    inboxSelected: {
      get() {
        if (this.selectedInbox) return this.selectedInbox.id;
        return false;
      },
      set(value) {
        this.selectedInbox = value;
      },
    },
    badCampaignParams() {
      return (
        this.$v.$invalid ||
        this.isCommonWhatsappConfigInvalid ||
        this.templateWithMediaAndAttachmentWasNotUploaded
      );
    },
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    onChangeMessageCommonWhatsapp(event) {
      this.commonWhatsappMessage = event;
      this.message = event.text;
    },
    onChangeIntervalSelected(event) {
      this.selectedInterval = event;
    },
    getCampaignDetails() {
      const campaignDetails = {
        title: this.title,
        data_source: this.dataSourceUrl,
        cron_time: this.buildCronTime(),
        inbox_id: this.selectedInbox.id,
        message: this.isCommonWhatsappCampaign
          ? { text: this.commonWhatsappMessage.text }
          : this.template,
        additional_infos: this.additionalInfosData(),
        agent_bot_id: this.selectedAgentBotId,
        description: this.description,
      };
      if (this.attachedFiles.length)
        campaignDetails.attachment = this.attachedFiles[0]?.resource.file;
      return campaignDetails;
    },
    // eslint-disable-next-line consistent-return
    async addCampaign() {
      try {
        const campaignDetails = this.getCampaignDetails();
        if (this.canNotRequestWithCurrentParams() || !campaignDetails.message)
          return this.showAlert(this.$t('CAMPAIGN.ONGOING.MODAL.PARAMS_ERROR'));

        await this.$store.dispatch('ongoingCampaigns/create', campaignDetails);
        this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (err) {
        this.showAlert(this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
