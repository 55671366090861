import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import OngoingCampaignsAPI from '../../api/ongoingCampaigns';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getCurrentCampaign: _state => campaignDisplayId => {
    return _state.records.find(
      record => record.id === Number(campaignDisplayId)
    );
  },
  getAllCampaigns: _state => {
    // Grouping by status and sorting by lastest IDs
    return _state.records.sort((a, b) => {
      if (a.status === 'disabled') {
        if (b.status === 'disabled') return b.id - a.id;
        return 1;
      }
      if (b.status === 'disabled') return -1;
      return b.id - a.id;
    });
  },
};

export const actions = {
  get: async function getCampaigns({ commit }) {
    commit(types.SET_ONGOING_CAMPAIGN_UI_FLAG, { isFetching: true });
    try {
      const response = await OngoingCampaignsAPI.get();
      commit(types.SET_ONGOING_CAMPAIGNS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_ONGOING_CAMPAIGN_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createCampaign({ commit }, campaignObj) {
    commit(types.SET_ONGOING_CAMPAIGN_UI_FLAG, { isCreating: true });
    try {
      const response = await OngoingCampaignsAPI.create(campaignObj);
      commit(types.ADD_ONGOING_CAMPAIGN, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_ONGOING_CAMPAIGN_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, campaignObj) => {
    commit(types.SET_ONGOING_CAMPAIGN_UI_FLAG, { isUpdating: true });
    try {
      const response = await OngoingCampaignsAPI.update(campaignObj);
      commit(types.EDIT_ONGOING_CAMPAIGN, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_ONGOING_CAMPAIGN_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_ONGOING_CAMPAIGN_UI_FLAG, { isDeleting: true });
    try {
      await OngoingCampaignsAPI.delete(id);
      commit(types.DELETE_ONGOING_CAMPAIGN, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_ONGOING_CAMPAIGN_UI_FLAG, { isDeleting: false });
    }
  },
  getReport: async ({ commit }, params) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: true });
    try {
      const response = await OngoingCampaignsAPI.getReport(params);
      return response.data;
    } catch (error) {
      // Ignore error
      return 'API error';
    }
  },
};

export const mutations = {
  [types.SET_ONGOING_CAMPAIGN_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_ONGOING_CAMPAIGN]: MutationHelpers.create,
  [types.SET_ONGOING_CAMPAIGNS]: MutationHelpers.set,
  [types.EDIT_ONGOING_CAMPAIGN]: MutationHelpers.update,
  [types.DELETE_ONGOING_CAMPAIGN]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
