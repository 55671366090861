export default {
  data() {
    return {
      attachedFiles: [],
      commonWhatsappMessage: {},
      waTemplateConfig: {},
    };
  },
  computed: {
    // FETCHING EACH TEMPLATE COMPONENT
    headerComponent() {
      return this.template?.components?.find(
        component => component.type === 'HEADER'
      );
    },
    bodyComponent() {
      return this.template?.components?.find(
        component => component.type === 'BODY'
      );
    },
    footerComponent() {
      return this.template?.components?.find(
        component => component.type === 'FOOTER'
      );
    },
    buttonsComponent() {
      return this.template?.components?.find(
        component => component.type === 'BUTTONS'
      );
    },

    // FETCHING THE 'TEXT' FILED IN EACH COMPONENT
    headerText() {
      const headerIsText = this.headerComponent?.format === 'TEXT';
      return headerIsText ? this.headerComponent.text : '';
    },
    bodyText() {
      return this.bodyComponent?.text || '';
    },
    footerText() {
      return this.footerComponent?.text || '';
    },

    // ALREADY PROCESSED STRINGS TO CREATE MULTIATENDEDOR MESSAGE IN OUR DEFAULT STYLE
    processedHeaderContent() {
      return this.headerText ? `*${this.headerText}*\n\n` : '';
    },
    processedBodyContent() {
      return this.bodyComponent?.text.replace(
        /{{([^}]+)}}/g,
        (match, variable) => {
          const variableKey = this.processVariable(variable);
          return this.processedParams[variableKey] || `{{${variable}}}`;
        }
      );
    },
    processedFooterContent() {
      const footerContent = this.footerComponent?.text;
      return footerContent ? `\n\n_${footerContent}_\n\n` : '';
    },
    processedButtonsContent() {
      const buttons = this.buttonsComponent?.buttons;
      if (!buttons?.length) return '';

      let buttonsText = '\n';
      buttons.forEach(e => {
        buttonsText += '\n- ' + e.text;
      });
      return buttonsText;
    },

    // CHECKING MEDIA TYPES OF TEMPLATE HEADER
    hasImage() {
      return (
        this.attachedFileType === 'image/png' ||
        this.attachedFileType === 'image/jpeg' ||
        this.headerComponent?.format === 'IMAGE'
      );
    },
    hasDocument() {
      return (
        this.attachedFileType === 'application/pdf' ||
        this.headerComponent?.format === 'DOCUMENT'
      );
    },
    hasVideo() {
      return this.headerComponent?.format === 'VIDEO';
    },
    templateWithMedia() {
      return this.hasImage || this.hasDocument || this.hasVideo;
    },

    // Validations pre-sending media
    allowedFileTypes() {
      if (this.isCommonWhatsappCampaign)
        return 'image/png, image/jpeg, application/pdf';
      if (this.hasVideo) return 'video/mp4';
      if (this.hasDocument) return 'application/pdf';
      if (this.hasImage) return 'image/png, image/jpeg';
      return '';
    },
    currentFileExtension() {
      if (this.hasVideo) return '.mp4';
      if (this.hasDocument) return '.pdf';
      if (this.hasImage) return '.png/.jpeg';
      return '';
    },
    mustUploadFile() {
      return !this.attachedFiles.length && this.templateWithMedia;
    },
    uploadedFileIsNotInCorrectExtension() {
      const fileType = String(this.attachedFiles[0]?.resource?.file?.type);
      const correctExt = this.allowedFileTypes?.includes(fileType.trim());
      return !!this.attachedFiles.length && !correctExt;
    },

    // Check if the user has uploaded a file to the campaign
    templateWithMediaAndAttachmentWasNotUploaded() {
      if (!this.template) return false;
      const mediaTypes = ['VIDEO', 'DOCUMENT', 'IMAGE'];
      return (
        mediaTypes.includes(this.headerComponent?.format) &&
        (!this.attachedFiles.length || this.uploadedFileIsNotInCorrectExtension)
      );
    },

    hasWhatsappTemplates() {
      if (this.selectedInbox) {
        return !!this.selectedInbox?.message_templates;
      }
      return false;
    },
  },
  methods: {
    updateAttachments(newVal) {
      this.attachedFiles = newVal;
    },
    onResetTemplate() {
      this.$emit('toggleWaTemplate', false);
      this.template = null;
    },
    onTemplateUpdate(event) {
      this.waTemplateConfig = event;
    },
    toggleWaTemplate(template) {
      this.$emit('toggleWaTemplate', true);
      this.template = template;
      this.message = template.components[0].text;
    },
    handleRecurrencyChange(val) {
      this.selectedRecurrency = val.id;
      this.selectedDays = [];
    },
    resetWaTemplateSelection() {
      this.template = null;
    },
    numberOfMessageParamsMatchWithInsertedValues() {
      if (this.isCommonWhatsappCampaign) return true;

      if (!this.template) return false;
      this.messageParams = Object.values(this.waTemplateConfig).map(e => {
        return {
          param_type: e.paramType,
          text: e.value,
        };
      });
      const numberOfParams =
        this.bodyComponent?.text.match(/{{([^}]+)}}/g)?.length || 0;

      // eslint-disable-next-line consistent-return
      return (
        !numberOfParams ||
        numberOfParams ===
          Object.keys(this.messageParams.filter(e => e.text)).length
      );
    },
    isWppConfigInvalid() {
      if (!this.isWhatsappCampaign) return false;
      if (!this.template) return false;
      let validation = false;
      Object.values(this.waTemplateConfig).forEach(element => {
        if (
          (element.isFromCSV && !element.field) ||
          (!element.isFromCSV && !element.value)
        )
          validation = true;
      });
      return validation;
    },
    canNotRequestWithCurrentParams() {
      return this.badCampaignParams || this.isWppConfigInvalid();
    },
  },
};
