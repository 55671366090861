<template>
  <div class="tickets-menu-link">
    <woot-button
      v-tooltip.right="$t(`SIDEBAR.TICKETS`)"
      class-names="tickets-menu-link--button"
      variant="clear"
      color-scheme="secondary"
      @click="handleClick"
    >
      <fluent-icon icon="ticket-diagonal" />
    </woot-button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    handleClick() {
      this.$emit('open-tickets-menu');
    },
  },
};
</script>

<style scoped lang="scss">
.tickets-menu-link {
  margin: var(--space-smaller) 0;
}

.tickets-menu-link--button {
  display: inline-flex;
  position: relative;
  border-radius: var(--border-radius-large);
  border: 1px solid transparent;
  color: var(--s-600);

  &:hover {
    background: var(--w-25);
    color: var(--s-600);
  }

  &:focus {
    border-color: var(--w-500);
  }

  &.is-active {
    background: var(--w-50);
    color: var(--w-500);
  }
}
</style>
