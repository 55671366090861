export default {
  computed: {
    countObj() {
      return this.campaignReport?.count ?? {};
    },
    alreadySentCount() {
      return (
        this.countObj.sent +
        this.countObj.delivered +
        this.countObj.read +
        this.countObj.answered +
        this.countObj.completed
      );
    },
    alreadyProcessed() {
      return this.alreadySentCount + this.countObj.failed;
    },
    progressBar() {
      return Math.round((this.alreadyProcessed / this.totalCount) * 100);
    },
    totalCount() {
      return this.campaignReport?.count?.all ?? 0;
    },
    failedCount() {
      return this.countObj.failed;
    },
    notSentCount() {
      return (
        this.totalCount - (this.updatedCount.failed + this.updatedCount.sent)
      );
    },
    updatedCount() {
      const statusCountObj = { ...this.campaignReport.count };
      // Lógica de adição explicado na função statusPercentages
      statusCountObj.answered += statusCountObj.completed;
      statusCountObj.read += statusCountObj.answered;
      statusCountObj.delivered += statusCountObj.read;
      statusCountObj.sent += statusCountObj.delivered;
      return statusCountObj;
    },
    statusPercentages() {
      const statusCountObj = { ...this.campaignReport.count };
      const ALLStatusCount = statusCountObj.all;
      /** LÓGICA DE FORNECIMENTO DOS DADOS NO GRÁFICO
       * Como é um gráfico de FUNIL, DEVEMOS ter os valores de status ANTERIORES na mesma ordem de grandeza. Sempre decrescente do topo para baixo
       * Exemplo: O cliente só pode Responder (status answered) se ele já leu (status read), então mesmo que o FB não envie esse evento, posso supor que foi enviado
       *
       * Então vou fazer a tradução dos valores para que esses status mais avançados fiquem SEMPRE iguais ou menores que seus anteriores
       *  */
      statusCountObj.answered += statusCountObj.completed;
      statusCountObj.read += statusCountObj.answered;
      statusCountObj.delivered += statusCountObj.read;
      statusCountObj.sent += statusCountObj.delivered;
      Object.entries(statusCountObj).forEach(([status, value]) => {
        let currentPercentage;
        if (value)
          currentPercentage = Math.round((value / ALLStatusCount) * 100);
        if (currentPercentage > 100) currentPercentage = 100;
        statusCountObj[status] = currentPercentage || 0;
      });
      return statusCountObj;
    },
    sentRate() {
      const sucessRate = (this.alreadySentCount / this.countObj.all) * 100;
      return Math.round(sucessRate);
    },
    openRate() {
      const readMessages =
        this.countObj.read + this.countObj.answered + this.countObj.completed;
      const deliveredMessages = this.countObj.delivered + readMessages;
      const openRate = (readMessages / deliveredMessages) * 100;
      return Math.round(openRate);
    },

    interactRate() {
      const readMessages =
        this.countObj.read + this.countObj.answered + this.countObj.completed;
      const interactedMessages =
        this.countObj.answered + this.countObj.completed;
      const interactRate = (interactedMessages / readMessages) * 100;
      return Math.round(interactRate);
    },
  },
};
