var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white dark:bg-slate-800 mb-2 border border-slate-50 dark:border-slate-900 rounded-md px-5 py-4"},[_c('router-link',{staticStyle:{"color":"inherit"},attrs:{"to":_vm.campaignReportURL}},[_c('div',{staticClass:"flex flex-row items-start justify-between"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-base font-medium -mt-1 mb-1"},[_vm._v("\n          "+_vm._s(_vm.campaign.title)+"\n        ")]),_vm._v(" "),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.formatMessage(_vm.campaign.description)),expression:"formatMessage(campaign.description)"}],staticClass:"text-sm line-clamp-1 [&>p]:mb-0"})]),_vm._v(" "),_c('div',{staticClass:"flex flex-row space-x-4"},[_c('router-link',{attrs:{"to":_vm.campaignReportURL}},[_c('woot-button',{attrs:{"variant":"link","icon":"open","size":"small","color-scheme":"primary"}},[_vm._v("\n            "+_vm._s(_vm.$t('CAMPAIGN.LIST.BUTTONS.DETAILS'))+"\n          ")])],1),_vm._v(" "),_c('woot-button',{attrs:{"variant":"link","icon":"edit","color-scheme":"secondary","size":"small"},on:{"click":[function($event){$event.preventDefault();},function($event){return _vm.$emit('edit', _vm.campaign)}]}},[_vm._v("\n          "+_vm._s(_vm.$t('CAMPAIGN.LIST.BUTTONS.EDIT'))+"\n        ")]),_vm._v(" "),_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(
            !_vm.campaign.can_be_destroyed
              ? _vm.$t('CAMPAIGN.ONGOING.CAN_NOT_BE_DESTROYED')
              : ''
          ),expression:"\n            !campaign.can_be_destroyed\n              ? $t('CAMPAIGN.ONGOING.CAN_NOT_BE_DESTROYED')\n              : ''\n          ",modifiers:{"left":true}}],attrs:{"variant":"link","icon":"dismiss-circle","size":"small","color-scheme":"secondary","disabled":!_vm.campaign.can_be_destroyed},on:{"click":[function($event){$event.preventDefault();},function($event){return _vm.$emit('delete', _vm.campaign)}]}},[_vm._v("\n          "+_vm._s(_vm.$t('CAMPAIGN.LIST.BUTTONS.DELETE'))+"\n        ")]),_vm._v(" "),_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(
            !_vm.campaign.inbox ? _vm.$t('CAMPAIGN.ONGOING.CAN_NOT_BE_ACTIVATED') : ''
          ),expression:"\n            !campaign.inbox ? $t('CAMPAIGN.ONGOING.CAN_NOT_BE_ACTIVATED') : ''\n          ",modifiers:{"left":true}}],attrs:{"variant":"link","icon":_vm.campaign.status === 'disabled' ? 'eye-show' : 'eye-hide',"size":"small","color-scheme":"secondary","disabled":!_vm.campaign.inbox},on:{"click":[function($event){$event.preventDefault();},_vm.updateCampaignStatus]}},[_vm._v("\n          "+_vm._s(_vm.campaign.status === 'disabled'
              ? _vm.$t('CAMPAIGN.ONGOING.CHANGE_STATUS.ACTIVATE')
              : _vm.$t('CAMPAIGN.ONGOING.CHANGE_STATUS.DEACTIVATE'))+"\n        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-row mt-5 items-center space-x-3"},[_c('woot-label',{staticClass:"text-xs mr-3",attrs:{"small":"","title":_vm.currentCampaignStatus.text,"color-scheme":_vm.currentCampaignStatus.colorScheme}}),_vm._v(" "),(_vm.campaign.inbox)?_c('inbox-name',{staticClass:"ltr:ml-0 rtl:mr-0 mb-1",attrs:{"inbox":_vm.campaign.inbox}}):_vm._e(),_vm._v(" "),(_vm.campaign.agent_bot)?_c('div',{staticClass:"text-xs text-slate-700 dark:text-slate-900 mb-1 bg-slate-100 dark:bg-slate-700 p-1 text-ellipsis max-w-[12rem]"},[_c('b',[_vm._v("BOT:")]),_vm._v(" "+_vm._s(_vm.campaign.agent_bot.name)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-xs text-slate-700 dark:text-slate-500 mb-1"},[_vm._v("\n        "+_vm._s(_vm.$t('CAMPAIGN.ONGOING.LAST_TRIGGERED') + _vm.last_triggered_time)+"\n      ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }