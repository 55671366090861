/* global axios */
import ApiClient from './ApiClient';

export const buildCreatePayload = ({
  title,
  data_source,
  cron_time,
  inbox_id,
  message,
  description,
  agent_bot_id,
  attachment,
  additional_infos,
  status,
}) => {
  let payload = new FormData();
  const fields = {
    title,
    data_source,
    cron_time,
    inbox_id,
    message,
    description,
    agent_bot_id,
    attachment,
    additional_infos,
    status,
  };
  Object.entries(fields).forEach(([key, value]) => {
    const mustStringify = key === 'message' || key === 'additional_infos';
    if (value)
      payload.append(key, mustStringify ? JSON.stringify(value) : value);
  });
  return payload;
};

class OngoingCampaignsAPI extends ApiClient {
  constructor() {
    super('ongoing_campaigns', { accountScoped: true });
  }

  getReport(params) {
    const { id, pageIndex, status, contactInfo, startAt, endAt } = params;
    let targetUrl = `${this.url}/${id}/report?page=${pageIndex || 1}`;

    const statusAsParam = status ? `&status=${status}` : '';
    const rangeAsParam =
      startAt && endAt ? `&end_at=${endAt}&start_at=${startAt}` : '';
    const contactInfoAsParam = contactInfo
      ? `&q=${encodeURIComponent(contactInfo)}`
      : '';
    targetUrl += statusAsParam;
    targetUrl += rangeAsParam;
    targetUrl += contactInfoAsParam;

    return axios.get(targetUrl);
  }

  create({
    title,
    data_source,
    cron_time,
    inbox_id,
    message,
    description,
    agent_bot_id,
    attachment,
    additional_infos,
  }) {
    return axios({
      method: 'post',
      url: this.url,
      data: buildCreatePayload({
        title,
        data_source,
        cron_time,
        inbox_id,
        message,
        description,
        agent_bot_id,
        attachment,
        additional_infos,
      }),
    });
  }

  update({
    campaign_id,
    title,
    data_source,
    cron_time,
    inbox_id,
    message,
    description,
    agent_bot_id,
    attachment,
    additional_infos,
    status,
  }) {
    return axios({
      method: 'patch',
      url: this.url + `/${campaign_id}`,
      data: buildCreatePayload({
        title,
        data_source,
        cron_time,
        inbox_id,
        message,
        description,
        agent_bot_id,
        attachment,
        additional_infos,
        status,
      }),
    });
  }
}

export default new OngoingCampaignsAPI();
