<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup"
    >
      {{ buttonText }}
    </woot-button>
    <ongoing-campaigns v-if="isOngoingType" />
    <one-off-campaigns v-else />
    <!-- ONGOING MODAL -->
    <woot-modal :show.sync="showAddOngoingPopup" :on-close="hideAddPopup">
      <add-ongoing-campaign @on-close="hideAddPopup" />
    </woot-modal>

    <!-- ONE OFF MODAL -->
    <woot-modal :show.sync="showAddOneOffPopup" :on-close="hideAddPopup">
      <add-one-off-campaign @on-close="hideAddPopup" />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import campaignMixin from 'shared/mixins/campaignMixin';
import OneOffCampaigns from './oneoff/Campaign.vue';
import OngoingCampaigns from './ongoing/Campaign.vue';
import AddOngoingCampaign from './ongoing/AddCampaign.vue';
import AddOneOffCampaign from './oneoff/AddCampaign.vue';

export default {
  components: {
    OneOffCampaigns,
    AddOneOffCampaign,
    AddOngoingCampaign,
    OngoingCampaigns,
  },
  mixins: [campaignMixin],
  data() {
    return {
      showAddOneOffPopup: false,
      showAddOngoingPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      agentBots: 'agentBots/getBots',
    }),
    buttonText() {
      if (this.isOngoingType) {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONGOING');
      }
      return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONE_OFF');
    },
  },
  mounted() {
    this.$store.dispatch('agentBots/get');
  },
  methods: {
    openAddPopup() {
      if (this.isOngoingType) this.showAddOngoingPopup = true;
      else this.showAddOneOffPopup = true;
    },
    hideAddPopup() {
      this.showAddOngoingPopup = false;
      this.showAddOneOffPopup = false;
    },
  },
};
</script>
