<template>
  <div class="container flex-1 overflow-auto">
    <campaign-filter
      :search-query.sync="searchQuery"
      @search="updateSearchTitle"
      @status-change="updateStatusFilter"
      @change-sort="updateSortFilter"
      @date-filter-change="updateDateFilter"
    />
    <campaigns-table
      :campaigns="campaigns"
      :show-empty-result="showEmptyResult"
      :is-loading="uiFlags.isFetching"
      :campaign-type="type"
      @edit="openEditPopup"
      @delete="openDeletePopup"
    />
    <div v-if="totalCampaignsCount" class="table-pagination">
      <!--
       Estou settando o page-size-option como null para remover o selector de quantidade de itens na página.
       Isso ocasiona um crash dentro do componente da lib, porém, como não afeta o escopo do componente, vamos ignorá-lo por hora 
      -->
      <ve-pagination
        :total="totalCampaignsCount"
        :page-index="pageIndex"
        :page-size="10"
        :page-size-option="null"
        @on-page-number-change="onPageNumberChange"
      />
    </div>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CAMPAIGN.DELETE.CONFIRM.TITLE')"
      :message="$t('CAMPAIGN.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('CAMPAIGN.DELETE.CONFIRM.YES')"
      :reject-text="$t('CAMPAIGN.DELETE.CONFIRM.NO')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import CampaignsTable from '../CampaignsTable.vue';
import CampaignFilter from './CampaignFilter.vue';
import { VePagination, VeLocale } from 'vue-easytable';
import ptBR from '../reports/ptBR.js';

export default {
  components: {
    CampaignsTable,
    CampaignFilter,
    VePagination,
  },
  mixins: [alertMixin, campaignMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pageSize: 10,
      pageIndex: 1,
      showEditPopup: false,
      selectedCampaign: {},
      showDeleteConfirmationPopup: false,
      selectedStatus: [],
      searchTitle: '',
      searchQuery: '',
      sortFilter: '',
      dateFilter: {
        start_at: '',
        end_at: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      labelList: 'labels/getLabels',
    }),
    totalCampaignsCount() {
      return this.campaignsResponse?.total_count || 0;
    },
    campaigns() {
      if (!this.campaignsResponse?.campaigns?.length) return [];
      return this.campaignsResponse?.campaigns;
    },
    campaignsResponse() {
      return this.$store.getters['campaigns/getCampaignsResponses'];
    },
    showEmptyResult() {
      return !this.uiFlags.isFetching && this.campaigns.length === 0;
    },
  },
  mounted() {
    this.searchByFilters();
    const currentLanguage = this.$root.$i18n.locale;
    if (currentLanguage === 'pt_BR' || currentLanguage === 'es')
      VeLocale.use(ptBR);
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.searchByFilters();
    },
    onChangeFilter(value, type) {
      if (type === 'sort') {
        this.$emit('change-sort', value);
      }
    },
    openEditPopup(campaign) {
      this.selectedCampaign = campaign;
      this.showEditPopup = true;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openDeletePopup(campaign) {
      this.showDeleteConfirmationPopup = true;
      this.selectedCampaign = campaign;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      const { id } = this.selectedCampaign;
      this.deleteCampaign(id);
    },
    async deleteCampaign(id) {
      try {
        await this.$store.dispatch('campaigns/delete', id);
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.SUCCESS_MESSAGE'));
        this.searchByFilters();
      } catch (error) {
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.ERROR_MESSAGE'));
      }
    },

    updateSearchTitle(title) {
      this.pageIndex = 1;
      this.searchTitle = title;
      this.searchByFilters();
    },
    updateStatusFilter(statuses) {
      this.pageIndex = 1;
      this.selectedStatus = statuses;
      this.searchByFilters();
    },
    updateSortFilter(sortValue) {
      this.sortFilter = sortValue;
      this.searchByFilters();
    },
    updateDateFilter({ start_at, end_at }) {
      this.pageIndex = 1;
      this.dateFilter = { start_at, end_at };
      this.searchByFilters();
    },

    searchByFilters() {
      const queryParams = {
        status: this.selectedStatus.join(','),
        title: this.searchTitle,
        sort_by: this.sortFilter || 'scheduled_at_desc',
        start_at: this.dateFilter.start_at || '',
        end_at: this.dateFilter.end_at || '',
        page: this.pageIndex,
      };

      this.$store.dispatch('campaigns/get', queryParams);
    },
  },
};
</script>

<style scoped lang="scss">
.button-wrapper {
  @apply flex justify-end pb-2.5;
}
.container {
  min-height: 500px;
}

.ve-pagination {
  background-color: transparent;
}
v-deep .ve-pagination {
  background-color: transparent;
}

v-deep .ve-pagination-select {
  display: none;
}
.table-pagination {
  margin-top: var(--space-normal);
  text-align: right;
}

.table-pagination {
  margin-top: var(--space-normal);
  text-align: right;
}
</style>
