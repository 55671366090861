<template>
  <div class="responsive-container text-slate-800 dark:text-slate-100">
    <div class="h-full">
      <metric-card
        class="h-full justify-center items-center text-center"
        :show-live-label="false"
      >
        <spinner v-if="isLoading" color-scheme="primary" />
        <div v-else class="benchmark-content relative">
          <div style="margin: 1rem auto">
            <h2
              class="text-5xl"
              :style="`color: ${textColor}; font-weight: bold`"
            >
              {{ engagementPercentage || 0 }}%
            </h2>
            <h5 class="text-xl" :style="`color: ${textColor}`">
              {{ $t('CAMPAIGN.REPORT.BENCHMARK.ENGAGEMENT') }}
            </h5>
            <span class="text-sm text-slate-600">
              {{
                (engagementSum || 0) +
                ' ' +
                $t('CAMPAIGN.REPORT.BENCHMARK.ENGAGEMENT_LABEL')
              }}
            </span>
          </div>
          <button class="fixed-div justify-center align-middle h-14 w-14">
            <img
              src="/assets/images/dashboard/campaigns/dartboard.png"
              alt="dartboard"
              class="w-8 h-8"
            />
          </button>
          <button
            class="fixed-tooltip-div justify-center align-middle h-14 w-14"
          >
            <fluent-icon
              v-tooltip.left="$t('CAMPAIGN.REPORT.BENCHMARK.ENGAGEMENT_INFO')"
              icon="info"
              size="28"
              class="mt-px"
            />
          </button>
        </div>
      </metric-card>
    </div>

    <div class="h-full">
      <metric-card
        class="h-full justify-center items-center text-center"
        :show-live-label="false"
      >
        <spinner v-if="isLoading" color-scheme="primary" />
        <div v-else class="benchmark-content relative">
          <div style="margin: 1rem auto">
            <h2
              class="text-5xl"
              :style="`color: ${textColor}; font-weight: bold`"
            >
              {{ `${averageFirstInteractionTime || 0}min` }}
            </h2>
            <h5 class="text-xl" :style="`color: ${textColor}`">
              {{ $t('CAMPAIGN.REPORT.BENCHMARK.FIRST_INTERACTION') }}
            </h5>
            <span class="text-sm text-slate-600">
              {{ $t('CAMPAIGN.REPORT.BENCHMARK.FIRST_INTERACTION_LABEL') }}
            </span>
          </div>
          <button class="fixed-div justify-center align-middle h-14 w-14">
            <img
              src="/assets/images/dashboard/campaigns/message.png"
              alt="message"
              class="w-8 h-8"
            />
          </button>
          <button
            class="fixed-tooltip-div justify-center align-middle h-14 w-14"
          >
            <fluent-icon
              v-tooltip.left="$t('CAMPAIGN.REPORT.BENCHMARK.OPEN_RATE_INFO')"
              icon="info"
              size="28"
              class="mt-px"
            />
          </button>
        </div>
      </metric-card>
    </div>
  </div>
</template>

<script>
import MetricCard from 'dashboard/routes/dashboard/settings/reports/components/overview/MetricCard.vue';
import Spinner from 'shared/components/Spinner.vue';
import benchmarksMixin from './benchmarksMixin';

export default {
  components: { MetricCard, Spinner },
  mixins: [benchmarksMixin],
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
    campaignReport: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    currentDisplayTheme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    averageFirstInteractionTime() {
      if (!this.campaignReport.benchmark?.avg_first_response_time) return null;
      let timeInMinutes =
        this.campaignReport.benchmark.avg_first_response_time / 60;
      const parsedTime = Math.round(timeInMinutes); // Round up to the nearest integer
      return parsedTime > 0 ? parsedTime : 1;
    },
    engagementSum() {
      // We make the sum of the engagement statuses count
      if (!this.countObj) return null;
      return this.countObj.answered + this.countObj.completed;
    },
    engagementPercentage() {
      if (!this.campaignReport.count) return null;
      const percentage =
        (this.engagementSum / this.campaignReport.count.all) * 100;
      if (percentage < 0) return 0;
      if (percentage > 100) return 100;
      return Math.round(percentage); // Round up to the nearest integer
    },
    currentCampaignStatus() {
      return this.campaignStatusInfos(this.campaign.campaign_status);
    },
    textColor() {
      return this.currentDisplayTheme === 'dark' ? '#147cff' : '#0013b3';
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.responsive-container {
  @apply flex align-middle justify-between h-[500px] gap-4;
  flex-direction: column;
}
.fixed-div {
  @apply absolute top-[-30px] left-[-10px] flex flex-row;
  box-shadow: var(--shadow-large);
  background-color: transparent;
  border: 1px solid #8080801f;
  border-radius: 50%;
}
.fixed-tooltip-div {
  @apply absolute top-[-30px] right-[-10px] flex flex-row;
  box-shadow: var(--shadow-large);
  background-color: transparent;
  border: 1px solid #8080801f;
  border-radius: 50%;
  flex: 1;
}
@media (min-width: 1236px) {
  .benchmark-content {
    @apply w-[330px];
  }
}
@media (max-width: 1236px) {
  .responsive-container {
    padding-bottom: 1rem;
  }
}
</style>
