<template>
  <div
    class="bg-white dark:bg-slate-800 mb-2 border border-slate-50 dark:border-slate-900 rounded-md px-5 py-4"
  >
    <router-link :to="campaignReportURL" style="color: inherit">
      <div class="flex flex-row items-start justify-between">
        <div class="flex flex-col">
          <div class="text-base font-medium -mt-1 mb-1">
            {{ campaign.title }}
          </div>
          <div
            v-dompurify-html="formatMessage(campaign.message)"
            class="text-sm line-clamp-1 [&>p]:mb-0"
          />
        </div>
        <div class="flex flex-row space-x-4">
          <router-link :to="campaignReportURL">
            <woot-button
              variant="link"
              icon="open"
              size="small"
              color-scheme="primary"
            >
              {{ $t('CAMPAIGN.LIST.BUTTONS.DETAILS') }}
            </woot-button>
          </router-link>
          <woot-button
            v-if="campaign.campaign_status === 'active'"
            variant="link"
            icon="dismiss-circle"
            size="small"
            color-scheme="secondary"
            @click.prevent
            @click="$emit('delete', campaign)"
          >
            {{ $t('CAMPAIGN.LIST.BUTTONS.DELETE') }}
          </woot-button>
        </div>
      </div>

      <!-- Centralizar as informações das taxas -->
      <div class="flex flex-row justify-center my-4 space-x-8">
        <div class="planned-container text-center">
          <span class="section-title text-xs font-bold">
            {{ $t('CAMPAIGN.REPORT.BENCHMARK.SENT_RATE') }}
          </span>
          <p class="timestamp text-base">
            <fluent-icon
              icon="send"
              class="inline text-slate-600 dark:text-slate-500"
            />{{ sentRate || 0 }}%
          </p>
        </div>
        <div class="sent-container text-center">
          <span class="section-title text-xs font-bold">
            {{ $t('CAMPAIGN.REPORT.BENCHMARK.OPEN_RATE') }}
          </span>
          <p class="timestamp text-base">
            <fluent-icon
              icon="send"
              class="inline text-slate-600 dark:text-slate-500"
            />{{ openRate || 0 }}%
          </p>
        </div>
        <div class="sent-container text-center">
          <span class="section-title text-xs font-bold">
            {{ $t('CAMPAIGN.REPORT.BENCHMARK.INTERACT_RATE') }}
          </span>
          <p class="timestamp text-base">
            <fluent-icon
              icon="send"
              class="inline text-slate-600 dark:text-slate-500"
            />{{ interactRate || 0 }}%
          </p>
        </div>
      </div>

      <div class="flex flex-row mt-5 items-center space-x-3">
        <woot-label
          small
          :title="currentCampaignStatus.text"
          :color-scheme="currentCampaignStatus.colorScheme"
          class="text-xs mr-3"
        />
        <inbox-name :inbox="campaign.inbox" class="ltr:ml-0 rtl:mr-0 mb-0" />
        <div
          v-if="campaign.scheduled_at"
          class="text-xs text-slate-700 dark:text-slate-500 mb-0"
        >
          {{ messageStamp(new Date(campaign.scheduled_at), 'LLL d, h:mm a') }}
        </div>
        <div v-if="campaign.agent_bot" class="flex items-center mb-1">
          <fluent-icon
            icon="bot"
            class="text-xs inline text-slate-600 dark:text-slate-500 mr-1"
          />
          <p class="text-slate-600 dark:text-slate-500 m-1">
            {{ campaign.agent_bot.name }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { frontendURL } from 'dashboard/helper/URLHelper';
import campaignMixin from 'shared/mixins/campaignMixin';
import InboxName from 'dashboard/components/widgets/InboxName.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import timeMixin from 'dashboard/mixins/time';
import benchmarksMixin from '../reports/benchmarksMixin';

export default {
  components: {
    InboxName,
  },
  mixins: [messageFormatterMixin, timeMixin, campaignMixin, benchmarksMixin],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    campaignReportURL() {
      return frontendURL(
        `accounts/${this.accountId}/campaigns/one_off/${this.campaign.id}`
      );
    },
    currentCampaignStatus() {
      return this.campaignStatusInfos(this.campaign.campaign_status);
    },
    totalMetrics() {
      const metrics = this.campaign.campaign_metrics || {};
      const total = Object.values(metrics).reduce(
        (acc, value) => acc + value,
        0
      );
      return total;
    },
    alreadySentCount() {
      const metrics = this.campaign.campaign_metrics || {};
      const {
        sent = 0,
        delivered = 0,
        read = 0,
        answered = 0,
        completed = 0,
      } = metrics;
      return sent + delivered + read + answered + completed;
    },
    sentRate() {
      return this.totalMetrics > 0
        ? Math.round((this.alreadySentCount / this.totalMetrics) * 100)
        : 0;
    },
    openRate() {
      const metrics = this.campaign.campaign_metrics || {};
      const { read = 0, answered = 0, completed = 0, delivered = 0 } = metrics;
      const readMessages = read + answered + completed;
      const deliveredMessages = delivered + readMessages;
      return deliveredMessages > 0
        ? Math.round((readMessages / deliveredMessages) * 100)
        : 0;
    },
    interactRate() {
      const metrics = this.campaign.campaign_metrics || {};
      const { read = 0, answered = 0, completed = 0 } = metrics;
      const readMessages = read + answered + completed;
      const interactedMessages = answered + completed;
      return readMessages > 0
        ? Math.round((interactedMessages / readMessages) * 100)
        : 0;
    },
  },
};
</script>
