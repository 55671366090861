<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header :header-title="$t('CAMPAIGN.ONGOING.EDIT.TITLE')" />
    <form class="flex flex-col w-full" @submit.prevent="editCampaign">
      <div class="w-full">
        <!-- START TITLE INPUT -->
        <woot-input
          v-model="title"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />
        <!-- END TITLE INPUT -->

        <!-- START DESCRIPTION SECTION -->
        <label>
          {{ $t('CAMPAIGN.ONGOING.MODAL.DESCRIPTION') }}
          <textarea
            v-model="description"
            rows="2"
            type="text"
            :placeholder="$t('CAMPAIGN.ONGOING.MODAL.DESCRIPTION_PLACEHOLDER')"
          />
        </label>
        <!-- END DESCRIPTION SECTION -->

        <!-- START RECURRENCY -->
        <input-radio-group
          :label="$t('CAMPAIGN.ONGOING.MODAL.RECURRENCY.HEADER')"
          :items="recurrencyOptions"
          :action="handleRecurrencyChange"
        />
        <label
          v-if="selectedRecurrency !== 'daily'"
          class="multiselect-wrap--small"
          :class="{ error: $v.selectedDays.$error }"
        >
          <multiselect
            v-model="selectedDays"
            :options="daysOptionsList"
            track-by="title"
            label="title"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.ONGOING.MODAL.SELECT_DATE')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            @blur="$v.selectedDays.$touch"
            @select="$v.selectedDays.$touch"
          />
          <span v-if="$v.selectedDays.$error" class="editor-warning__message">
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
          </span>
        </label>
        <!-- END RECURRENCY -->

        <!-- START TRIGGER HOUR -->
        <div>
          <label>{{ $t('CAMPAIGN.ONGOING.MODAL.TRIGGER_TIME') }}</label>
          <date-picker
            v-model="time"
            type="time"
            format="H:mm"
            :minute-step="5"
            :hour-options="Array.from({ length: 21 }, (_, i) => i)"
            value-type="format"
            :confirm="true"
            :clearable="false"
            :editable="true"
            :confirm-text="$t('CAMPAIGN.ONGOING.MODAL.SELECT_TIME')"
          />
        </div>
        <!-- END TRIGGER HOUR -->

        <!-- START DATA SOURCE INPUT -->
        <woot-input
          v-model="dataSourceUrl"
          :label="$t('CAMPAIGN.ONGOING.MODAL.DATA_SOURCE.LABEL')"
          type="text"
          :class="{ error: $v.dataSourceUrl.$error }"
          :error="
            $v.dataSourceUrl.$error
              ? $t('CAMPAIGN.ONGOING.MODAL.DATA_SOURCE.ERROR')
              : ''
          "
          :placeholder="$t('CAMPAIGN.ONGOING.MODAL.DATA_SOURCE.PLACEHOLDER')"
          @blur="$v.dataSourceUrl.$touch"
        />
        <!-- END DATA SOURCE INPUT -->

        <!-- START INBOX MULTISELECT -->
        <label :class="{ error: $v.selectedInbox.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
          <select v-model="selectedInbox" @change="onChangeInbox">
            <option
              v-for="item in campaignInboxes"
              :key="item.name"
              :value="item"
            >
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.selectedInbox.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
          </span>
        </label>
        <!-- END INBOX MULTISELECT -->

        <!-- START MESSAGE LABEL -->
        <div v-if="hasWhatsappTemplates">
          <templates-picker
            v-if="!template"
            :inbox-id="inboxSelected"
            @click.stop
            @onSelect="toggleWaTemplate"
          />
          <campaign-template-parser
            v-else
            :template="template"
            :csv-headers="[]"
            :first-example="{}"
            :is-csv-audience="true"
            :is-ongoing="true"
            @template-update="onTemplateUpdate"
            @attachments-change="updateAttachments"
          />
          <p v-if="previousTemplateParams" style="margin-bottom: 0.5rem">
            {{
              $t('CAMPAIGN.ONGOING.EDIT.PREVIOUS_PARAMS') +
              ': ' +
              previousTemplateParams
            }}
          </p>

          <!-- LAST CAMPAIGN ATTACHMENT -->
          <div v-if="campaign.attachment_url && templateWithMedia" class="mb-2">
            <span class="text-xs">
              {{ $t('CAMPAIGN.ONGOING.ATTACHMENT.SUBMIT_AGAIN') }}
            </span>
            <a :href="campaign.attachment_url" target="_blank">
              <span
                class="flex items-center justify-center rounded-sm hover:bg-slate-200 bg-slate-100 hover:dark:bg-slate-500 dark:bg-slate-700 p-2"
              >
                <fluent-icon
                  class="text-xxs text-slate-700 dark:text-slate-200"
                  icon="document"
                  size="16"
                />
                {{ $t('CAMPAIGN.ONGOING.ATTACHMENT.CURRENT_ATTACHMENT') }}
              </span>
            </a>
          </div>

          <!-- RESET TEMPLATE -->
          <woot-button
            v-if="template"
            ref="arrowDownButton"
            color-scheme="secondary"
            class="mb-3 flex"
            icon="chevron-down"
            @click.prevent
            @click="resetWaTemplateSelection"
          >
            {{ $t('CAMPAIGN.TEMPLATE_PARSER.CHOOSE_ANOTHER_BUTTON') }}
          </woot-button>
        </div>

        <div v-else-if="isCommonWhatsappCampaign">
          <common-whatsapp-campaign-message
            :origin-params="true"
            :is-csv-audience="false"
            :campaign="campaign"
            :template="template"
            @on-change-message="onChangeMessageCommonWhatsapp"
            @attachments-change="updateAttachments"
          />

          <common-whatsapp-intervals-select
            @selected-interval="onChangeIntervalSelected"
          />
        </div>
        <!-- END MESSAGE LABEL -->
      </div>

      <!-- START BOT MULTISELECT -->
      <label>
        {{ $t('CAMPAIGN.ADD.FORM.BOT.LABEL') }}
        <select v-model="selectedAgentBotId">
          <option :value="null" selected>
            {{ $t('CAMPAIGN.ADD.FORM.BOT.INBOX_DEFAULT_OPTION') }}
          </option>
          <option
            v-for="agentBot in agentBots"
            :key="agentBot.id"
            :value="agentBot.id"
          >
            {{ agentBot.name }}
          </option>
        </select>
      </label>
      <!-- END BOT MULTISELECT -->

      <!-- START FILTERS INPUT -->
      <woot-input
        v-model="filters"
        :label="$t('CAMPAIGN.ONGOING.MODAL.FILTERS.LABEL')"
        type="text"
        style="margin-top: 1rem"
        :class="{ error: $v.filters.$error }"
        :error="
          $v.filters.$error ? $t('CAMPAIGN.ONGOING.MODAL.FILTERS.ERROR') : ''
        "
        :placeholder="$t('CAMPAIGN.ONGOING.MODAL.FILTERS.PLACEHOLDER')"
        @blur="$v.filters.$touch"
      />
      <!-- END FILTERS INPUT -->

      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <woot-button
          :is-loading="uiFlags.isUpdating"
          :disabled="badCampaignParams"
          type="submit"
        >
          {{ $t('CAMPAIGN.EDIT.UPDATE_BUTTON_TEXT') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import ongoingCampaignMixin from 'shared/mixins/ongoingCampaignMixin';
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import whatsappTemplateMixin from 'shared/mixins/whatsappTemplateMixin';
import CampaignTemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/CampaignTemplateParser.vue';
import DatePicker from 'vue2-datepicker';
import InputRadioGroup from 'dashboard/routes/dashboard/settings/inbox/components/InputRadioGroup.vue';

import CommonWhatsappIntervalsSelect from '../CommonWhatsappCampaign/CommonWhatsappIntervalsSelect.vue';
import CommonWhatsappCampaignMessage from '../CommonWhatsappCampaign/CommonWhatsappCampaignMessage.vue';

export default {
  components: {
    TemplatesPicker,
    CampaignTemplateParser,
    DatePicker,
    InputRadioGroup,
    CommonWhatsappIntervalsSelect,
    CommonWhatsappCampaignMessage,
  },

  mixins: [
    alertMixin,
    campaignMixin,
    whatsappTemplateMixin,
    ongoingCampaignMixin,
  ],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      description: '',
      message: '',
      dataSourceUrl: '',
      time: '',
      selectedInbox: null,
      messageParams: [],
      template: null, // selected WhatsApp Template
      filters: '',
      previousTemplateParams: '',
      selectedInterval: [],
      selectedAgentBotId: null,

      initialWaTemplateConfig: null,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'ongoingCampaigns/getUIFlags',
      currentUser: 'getCurrentUser',
      campaigns: 'ongoingCampaigns/getAllCampaigns',
      agentBots: 'agentBots/getBots',
    }),
    inboxSelected: {
      get() {
        if (this.selectedInbox) return this.selectedInbox.id;
        return false;
      },
      set(value) {
        this.selectedInbox = value;
      },
    },
    hasWhatsappTemplates() {
      if (this.selectedInbox) {
        return !!this.selectedInbox?.message_templates;
      }
      return false;
    },
    necessaryToSubmitMediaInTemplate() {
      // Checking if template was changed. If not, we force attachment upload
      const initialAndCurrentTemplateAreEqual =
        this.initialWaTemplateConfig === JSON.stringify(this.template);

      return (
        !initialAndCurrentTemplateAreEqual &&
        this.templateWithMediaAndAttachmentWasNotUploaded
      );
    },
    badCampaignParams() {
      return (
        this.$v.$invalid ||
        this.isCommonWhatsappConfigInvalid ||
        this.necessaryToSubmitMediaInTemplate
      );
    },
  },
  mounted() {
    this.setInitialValues();
    this.setInitialTemplateParams();
  },
  methods: {
    setInitialTemplateParams() {
      const params = this.campaign.additional_infos?.message_params;
      if (!params || params.length < 1) return;
      this.previousTemplateParams = params.map(e => e.text).join(', ');
    },
    onClose() {
      this.$emit('on-close');
    },
    // eslint-disable-next-line consistent-return
    async editCampaign() {
      if (this.canNotRequestWithCurrentParams())
        return this.showAlert(this.$t('CAMPAIGN.ONGOING.MODAL.PARAMS_ERROR'));

      try {
        const campaignDetails = this.getCampaignDetails();
        await this.$store.dispatch('ongoingCampaigns/update', campaignDetails);
        this.showAlert(this.$t('CAMPAIGN.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (err) {
        this.showAlert(this.$t('CAMPAIGN.EDIT.API.ERROR_MESSAGE'));
      }
    },
    getCampaignDetails() {
      const campaignDetails = {
        title: this.title,
        data_source: this.dataSourceUrl,
        cron_time: this.buildCronTime(),
        inbox_id: this.selectedInbox.id,
        message: { text: this.commonWhatsappMessage.text },
        additional_infos: this.additionalInfosData(),
        agent_bot_id: this.selectedAgentBotId,
        campaign_id: this.campaign.id,
      };
      if (this.description) campaignDetails.description = this.description;
      if (this.attachedFiles.length)
        campaignDetails.attachment = this.attachedFiles[0]?.resource.file;
      return campaignDetails;
    },
    setInitialValues() {
      // Setting selected inbox
      if (this.campaign.inbox)
        this.selectedInbox = this.campaignInboxes.find(
          e => e.id === this.campaign.inbox.id
        );

      // Setting title
      this.title = this.campaign.title;

      // Setting description
      this.description = this.campaign.description || '';

      // Setting filters
      const filters = this.campaign.additional_infos?.filters;
      if (filters) this.filters = JSON.stringify(filters);

      // Setting data source
      this.dataSourceUrl = this.campaign.data_source;

      // Setting message
      this.template = JSON.parse(this.campaign.message);
      this.message = Array.isArray(this.template)
        ? this.bodyComponent?.text // Whatsapp api template
        : this.template.text; // Common whatsapp template

      // Setting bot
      if (this.campaign.agent_bot)
        this.selectedAgentBotId = this.campaign.agent_bot.id;

      this.setInitialTimeOptions();
      // this.setInitialHsmParamsOptions();

      // Settings backup var to check if Template message was changed in component life cycle
      this.initialWaTemplateConfig = JSON.stringify(this.template);
    },
    setInitialTimeOptions() {
      // Setting selected cron time
      const cronTime = this.campaign.cron_time.split(' ');
      // HOURS
      this.time = this.transformUTC_To_BRT(cronTime[1]) + ':' + cronTime[0];
      // DAYS
      if (cronTime[2] !== '*') {
        this.recurrencyOptions[2].checked = true;
        this.selectedRecurrency = 'monthly';
        const listOfSettedDays = cronTime[2].split(',');
        if (!listOfSettedDays.length) return;
        this.selectedDays = listOfSettedDays.map(e => ({
          title: Number(e),
          cron_number: Number(e),
        }));
      }
      // WEEKDAYS
      else if (cronTime[4] !== '*') {
        this.recurrencyOptions[1].checked = true;
        this.selectedRecurrency = 'weekly';
        let listOfSettedWeekdays = cronTime[4].split(',');
        if (!listOfSettedWeekdays.length) return;
        listOfSettedWeekdays = listOfSettedWeekdays.map(e => Number(e));
        this.selectedDays = this.weekdays.filter(e =>
          listOfSettedWeekdays.includes(e.cron_number)
        );
      } else {
        this.recurrencyOptions[0].checked = true;
        this.selectedRecurrency = 'daily';
      }
    },
    // We are assuming the selected time is in BRT format (UTC -3)
    transformBRT_To_UTC(hours) {
      let time = typeof hours === 'number' ? hours : Number(hours);
      return `${time + 3}`;
    },
    transformUTC_To_BRT(hours) {
      let time = typeof hours === 'number' ? hours : Number(hours);
      return `${time - 3}`;
    },

    onChangeMessageCommonWhatsapp(event) {
      this.commonWhatsappMessage = event;
      this.message = event.text;
    },
    onChangeIntervalSelected(event) {
      this.selectedInterval = event;
    },
    onChangeInbox() {
      this.template = null;
    },
  },
};
</script>
<style lang="scss" scoped></style>
