<template>
  <div class="flex-1 overflow-auto p-4">
    <div v-if="isLoading">
      <div class="items-center flex text-base justify-center">
        <spinner color-scheme="primary" />
        <span>{{ $t('CAMPAIGN.LIST.LOADING_MESSAGE') }}</span>
      </div>
      <div class="h-[80vh]" />
    </div>
    <div v-else>
      <!-- ERROR IN API -->
      <span
        v-if="shouldShowErrorMessage"
        class="text-red-400 dark:text-red-500 text-sm block font-normal -mt-px w-full"
      >
        {{ $t('CAMPAIGN.REPORT.ERROR') }}
      </span>
      <div v-else class="flex justify-between">
        <!-- Campaign Details, Base Quality, and Template Preview -->
        <div class="column flex-1 max-height-card-container">
          <metric-card :show-live-label="false" class="max-height-card">
            <campaign-report-details
              :inbox="campaignInbox"
              :campaign="campaign"
              :campaign-report="campaignReport"
            />
          </metric-card>
        </div>
        <div class="column flex-1 max-height-card-container">
          <metric-card :show-live-label="false" class="max-height-card">
            <campaign-report-base-quality
              :inbox="campaignInbox"
              :campaign="campaign"
              :campaign-report="campaignReport"
            />
          </metric-card>
        </div>
        <div class="column flex-1 max-height-card-container">
          <metric-card
            :show-live-label="false"
            class="max-height-card template-preview"
          >
            <campaign-report-template-preview
              :inbox="campaignInbox"
              :campaign="campaign"
              :campaign-report="campaignReport"
            />
          </metric-card>
        </div>
      </div>

      <div class="flex row justify-center mt-4">
        <metric-card :show-live-label="false" :min-height="'min-h-[50px]'">
          <campaign-report-progress-bar
            ref="progressBar"
            :campaign-report="campaignReport"
          />
        </metric-card>
      </div>
      <!-- EXTENDED BENCHMARKS -->
      <div class="flex">
        <campaign-report-extended-benchmarks
          :is-loading="isLoadingReport"
          :campaign="campaign"
          :campaign-report="campaignReport"
          :current-display-theme="currentDisplayTheme"
        />
      </div>
      <!-- DELIVERY DETAILS -->
      <div class="flex justify-center">
        <metric-card class="h-[200px] w-full" :show-live-label="false">
          <div
            v-if="isLoadingReport"
            class="items-center flex text-base justify-center"
          >
            <spinner color-scheme="primary" />
          </div>
          <campaign-report-delivery-details
            v-else
            :is-loading="isLoadingReport"
            :campaign="campaign"
            :campaign-report="campaignReport"
            :default-text-color="defaultTextColor"
            :current-display-theme="currentDisplayTheme"
          />
        </metric-card>
      </div>
      <div class="responsive-benchmark">
        <!-- FUNIL DE CONVERSÃO -->
        <metric-card class="h-[500px]" :show-live-label="false">
          <div
            v-if="isLoadingReport"
            class="items-center flex text-base justify-center"
          >
            <spinner color-scheme="primary" />
          </div>
          <campaign-report-chart
            v-else
            :campaign-report="campaignReport"
            :default-text-color="defaultTextColor"
            :current-display-theme="currentDisplayTheme"
          />
        </metric-card>
        <!-- BENCHMARKS -->
        <div style="max-width: 23.5rem !important">
          <campaign-report-benchmarks
            :is-loading="isLoadingReport"
            :campaign="campaign"
            :campaign-report="campaignReport"
            :current-display-theme="currentDisplayTheme"
          />
        </div>
      </div>
      <!-- MESSAGES CONTAINER -->
      <div class="flex row">
        <metric-card
          :show-live-label="false"
          style="max-width: calc(100vw - 100px)"
        >
          <campaign-report-messages
            :campaign-report="campaignReport"
            :page-index="pageIndex"
            :is-loading="isLoadingReportMessages"
            @page-change="pageChange"
            @status-change="statusChange"
            @search-by-contactinfo="searchByContactInfo"
            @clean-filter="cleanSearchFilter"
          />
        </metric-card>
      </div>
    </div>
  </div>
</template>

<script>
import campaignMixin from 'shared/mixins/campaignMixin';
import MetricCard from 'dashboard/routes/dashboard/settings/reports/components/overview/MetricCard.vue';
import CampaignReportChart from '../reports/CampaignReportChart.vue';
import CampaignReportDetails from '../reports/CampaignReportDetails.vue';
import CampaignReportBenchmarks from '../reports/CampaignReportBenchmarks.vue';
import CampaignReportMessages from '../reports/CampaignReportMessages.vue';
import CampaignReportProgressBar from '../reports/CampaignReportProgressBar.vue';
import Spinner from 'shared/components/Spinner.vue';
import { getChangesOnTheme } from 'dashboard/helper/themeHelper';
import CampaignReportExtendedBenchmarks from '../reports/CampaignReportExtendedBenchmarks.vue';
import CampaignReportDeliveryDetails from '../reports/CampaignReportDeliveryDetails.vue';
import CampaignReportBaseQuality from '../reports/CampaignReportBaseQuality.vue';
import CampaignReportTemplatePreview from '../reports/CampaignReportTemplatePreview.vue';

export default {
  components: {
    MetricCard,
    CampaignReportChart,
    CampaignReportDetails,
    CampaignReportBenchmarks,
    CampaignReportMessages,
    CampaignReportProgressBar,
    Spinner,
    CampaignReportExtendedBenchmarks,
    CampaignReportDeliveryDetails,
    CampaignReportBaseQuality,
    CampaignReportTemplatePreview,
  },
  mixins: [campaignMixin],
  data() {
    return {
      isLoading: true,
      isLoadingReport: true,
      isLoadingReportMessages: true,
      campaignReport: {},
      pageIndex: 1,
      statusFilter: '',
      contactInfoFilter: '',
      defaultTextColor: '',
      currentDisplayTheme: '',
      campaign: {},
    };
  },
  computed: {
    campaignInbox() {
      return this.campaign?.inbox || {};
    },
    shouldShowErrorMessage() {
      return !this.campaign;
    },
  },
  watch: {
    campaignDisplayId() {
      if (this.$route?.params?.campaignDisplayId) {
        this.fetchCurrentCampaign();
        this.fetchCurrentCampaignReport();
      }
    },
  },
  created() {
    this.setDefaultTextColor();
  },
  mounted() {
    this.fetchCurrentCampaign();
    this.fetchCurrentCampaignReport();
    getChangesOnTheme(this.currentDisplayTheme, this);
  },
  methods: {
    async fetchCurrentCampaign() {
      const campaign = await this.$store.dispatch('campaigns/getCampaignById', {
        id: this.campaignDisplayId,
      });
      this.campaign = campaign || {};
      this.isLoading = false;
    },
    async fetchCurrentCampaignReport() {
      const reqParams = {
        id: this.campaignDisplayId,
        pageIndex: this.pageIndex,
        contactInfo: this.contactInfoFilter,
        status: this.statusFilter,
      };
      const response = await this.$store.dispatch(
        'campaigns/getReport',
        reqParams
      );
      this.isLoadingReport = false;
      this.isLoadingReportMessages = false;
      if (response === 'API error') return;
      this.campaignReport = response;
    },
    handleThemeChange(newTheme) {
      this.currentDisplayTheme = newTheme;
      this.setDefaultTextColor();
    },
    setDefaultTextColor() {
      if (!this.currentDisplayTheme)
        this.currentDisplayTheme = window.localStorage.color_scheme;

      this.defaultTextColor =
        this.currentDisplayTheme === 'dark'
          ? 'hsl(208 11.7% 91.1% / var(--tw-text-opacity))'
          : 'hsl(198 6.6% 15.8% / var(--tw-text-opacity))';
    },
    pageChange(newVal) {
      this.pageIndex = newVal;
      this.isLoadingReportMessages = true;
      this.fetchCurrentCampaignReport();
    },
    statusChange(newVal) {
      this.statusFilter = newVal;
      this.isLoadingReportMessages = true;
      this.fetchCurrentCampaignReport();
    },
    searchByContactInfo(newVal) {
      this.contactInfoFilter = newVal;
      this.isLoadingReportMessages = true;
      this.fetchCurrentCampaignReport();
    },
    cleanSearchFilter() {
      this.statusFilter = '';
      this.contactInfoFilter = '';
      this.isLoadingReportMessages = true;
      this.fetchCurrentCampaignReport();
    },
    startProgress() {
      this.$refs.progressBar.start();
    },
  },
};
</script>

<style scoped>
.column {
  flex: 1;
}

.max-height-card-container {
  max-height: 300px; /* Ajuste o valor conforme necessário */
}

.max-height-card {
  height: 100%;
}

.template-preview {
  overflow: auto;
}

@media (min-width: 1236px) {
  .responsive-benchmark {
    display: flex;
  }
}

@media (max-width: 1236px) {
  .flex {
    flex-wrap: wrap;
  }
}
</style>
