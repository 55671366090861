<template>
  <transition name="menu-slide">
    <div
      v-if="show"
      v-on-clickaway="onClickAway"
      class="options-menu dropdown-pane"
      :class="{ 'dropdown-pane--open': show }"
    >
      <woot-dropdown-menu>
        <woot-dropdown-item>
          <woot-button
            variant="clear"
            color-scheme="secondary"
            :class="{ 'is-active': isChildMenuActive }"
            size="small"
            icon="add-circle"
            :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
            :target="openInNewPage ? '_blank' : undefined"
            @click="openTickets"
          >
            {{ $t('SIDEBAR_ITEMS.NEW_TICKET') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <woot-button
            variant="clear"
            color-scheme="secondary"
            :class="{ 'is-active': isChildMenuActive }"
            size="small"
            icon="apps-list-detail"
            :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
            :target="openInNewPage ? '_blank' : undefined"
            @click="newTicket"
          >
            {{ $t('SIDEBAR_ITEMS.OPEN_TICKETS') }}
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import wootConstants from 'dashboard/constants/globals';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem,
  },
  mixins: [clickaway],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      urlJira: wootConstants.JIRA_URL,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    openTickets() {
      const externalURL = `${this.urlJira}portal/3/group/4/create/10073?customfield_10063=${this.accountId}&customfield_10064=${this.currentUser.email}&customfield_10066=${this.globalConfig.appVersion}&customfield_10065=${this.globalConfig.maVersion}`;
      window.open(externalURL, '_blank');
    },
    newTicket() {
      const externalURL = `${this.urlJira}user/requests?filter=MASUP`;
      window.open(externalURL, '_blank');
    },
    onClickAway() {
      if (this.show) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.options-menu.dropdown-pane {
  left: var(--space-jumbo);
  bottom: var(--space-larger);
  min-width: var(--space-mega);
  top: unset;
  z-index: var(--z-index-low);
}
</style>
