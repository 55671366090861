<template>
  <div
    class="bg-white dark:bg-slate-800 mb-2 border border-slate-50 dark:border-slate-900 rounded-md px-5 py-4"
  >
    <router-link :to="campaignReportURL" style="color: inherit">
      <div class="flex flex-row items-start justify-between">
        <div class="flex flex-col">
          <div class="text-base font-medium -mt-1 mb-1">
            {{ campaign.title }}
          </div>
          <div
            v-dompurify-html="formatMessage(campaign.description)"
            class="text-sm line-clamp-1 [&>p]:mb-0"
          />
        </div>
        <div class="flex flex-row space-x-4">
          <router-link :to="campaignReportURL">
            <woot-button
              variant="link"
              icon="open"
              size="small"
              color-scheme="primary"
            >
              {{ $t('CAMPAIGN.LIST.BUTTONS.DETAILS') }}
            </woot-button>
          </router-link>
          <woot-button
            variant="link"
            icon="edit"
            color-scheme="secondary"
            size="small"
            @click.prevent
            @click="$emit('edit', campaign)"
          >
            {{ $t('CAMPAIGN.LIST.BUTTONS.EDIT') }}
          </woot-button>
          <woot-button
            v-tooltip.left="
              !campaign.can_be_destroyed
                ? $t('CAMPAIGN.ONGOING.CAN_NOT_BE_DESTROYED')
                : ''
            "
            variant="link"
            icon="dismiss-circle"
            size="small"
            color-scheme="secondary"
            :disabled="!campaign.can_be_destroyed"
            @click.prevent
            @click="$emit('delete', campaign)"
          >
            {{ $t('CAMPAIGN.LIST.BUTTONS.DELETE') }}
          </woot-button>
          <woot-button
            v-tooltip.left="
              !campaign.inbox ? $t('CAMPAIGN.ONGOING.CAN_NOT_BE_ACTIVATED') : ''
            "
            variant="link"
            :icon="campaign.status === 'disabled' ? 'eye-show' : 'eye-hide'"
            size="small"
            color-scheme="secondary"
            :disabled="!campaign.inbox"
            @click.prevent
            @click="updateCampaignStatus"
          >
            {{
              campaign.status === 'disabled'
                ? $t('CAMPAIGN.ONGOING.CHANGE_STATUS.ACTIVATE')
                : $t('CAMPAIGN.ONGOING.CHANGE_STATUS.DEACTIVATE')
            }}
          </woot-button>
        </div>
      </div>

      <div class="flex flex-row mt-5 items-center space-x-3">
        <woot-label
          small
          :title="currentCampaignStatus.text"
          :color-scheme="currentCampaignStatus.colorScheme"
          class="text-xs mr-3"
        />
        <inbox-name
          v-if="campaign.inbox"
          :inbox="campaign.inbox"
          class="ltr:ml-0 rtl:mr-0 mb-1"
        />
        <div
          v-if="campaign.agent_bot"
          class="text-xs text-slate-700 dark:text-slate-900 mb-1 bg-slate-100 dark:bg-slate-700 p-1 text-ellipsis max-w-[12rem]"
        >
          <b>BOT:</b> {{ campaign.agent_bot.name }}
        </div>
        <div class="text-xs text-slate-700 dark:text-slate-500 mb-1">
          {{ $t('CAMPAIGN.ONGOING.LAST_TRIGGERED') + last_triggered_time }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { frontendURL } from 'dashboard/helper/URLHelper';
import campaignMixin from 'shared/mixins/campaignMixin';
import InboxName from 'dashboard/components/widgets/InboxName.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    InboxName,
  },
  mixins: [messageFormatterMixin, timeMixin, campaignMixin, alertMixin],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    currentCampaignStatus() {
      return this.campaignStatusInfos(this.campaign.status);
    },
    last_triggered_time() {
      return this.campaign.last_triggered_at
        ? this.messageStamp(
            new Date(this.campaign.last_triggered_at),
            'LLL d, h:mm a'
          )
        : '---';
    },
    campaignReportURL() {
      return frontendURL(
        `accounts/${this.accountId}/campaigns/ongoing/${this.campaign.id}`
      );
    },
  },
  methods: {
    async updateCampaignStatus() {
      const newStatus =
        this.campaign.status === 'disabled' ? 'enabled' : 'disabled';
      const campaignDetails = {
        campaign_id: this.campaign.id,
        inbox_id: this.campaign.inbox?.id,
        status: newStatus,
      };
      try {
        await this.$store.dispatch('ongoingCampaigns/update', campaignDetails);
        this.showAlert(this.$t('CAMPAIGN.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('CAMPAIGN.EDIT.API.ERROR_MESSAGE'));
      }
      this.$store.dispatch('ongoingCampaigns/get');
    },
  },
};
</script>
