import { CAMPAIGN_TYPES } from '../constants/campaign';

export default {
  computed: {
    campaignType() {
      const pageURL = window.location.href;
      return pageURL.substring(pageURL.lastIndexOf('/') + 1);
    },
    campaignTypeAsUrlMember() {
      const pageURL = window.location.href;
      return pageURL.split('/').reverse()[1];
    },
    isOngoingType() {
      return (
        this.campaignType === CAMPAIGN_TYPES.ONGOING ||
        this.campaignTypeAsUrlMember === CAMPAIGN_TYPES.ONGOING
      );
    },
    isOneOffType() {
      return (
        this.campaignType === CAMPAIGN_TYPES.ONE_OFF ||
        this.campaignTypeAsUrlMember === CAMPAIGN_TYPES.ONE_OFF
      );
    },
    campaignDisplayId() {
      if (this.$route?.params?.campaignDisplayId)
        return this.$route.params.campaignDisplayId;

      const pageURL = window.location.href;
      return pageURL.substring(pageURL.lastIndexOf('/') + 1);
    },
    campaignId() {
      if (this.$route?.params?.campaignId) return this.$route.params.campaignId;

      const pageURL = window.location.href;
      return pageURL.substring(pageURL.lastIndexOf('/') + 1);
    },
    isCommonWhatsappCampaign() {
      if (!this.selectedInbox) return false;
      return this.selectedInbox.channel_type === 'Channel::CommonWhatsapp';
    },
    isWhatsappCampaign() {
      if (!this.selectedInbox) return false;
      return this.selectedInbox.channel_type === 'Channel::Whatsapp';
    },
    statusSettings() {
      return {
        // ONE OFF CAMPAIGN STATUS
        active: {
          icon: 'arrow-right',
          text: this.$t('CAMPAIGN.REPORT.DETAILS.STATUS.ACTIVE'),
          colorScheme: 'primary',
        },
        processing: {
          icon: 'arrow-right',
          text: this.$t('CAMPAIGN.REPORT.DETAILS.STATUS.PROCESSING'),
          colorScheme: 'success',
        },
        in_progress: {
          icon: 'arrow-up',
          text: this.$t('CAMPAIGN.REPORT.DETAILS.STATUS.IN_PROGRESS'),
          colorScheme: 'secondary',
        },
        completed: {
          icon: 'checkmark',
          text: this.$t('CAMPAIGN.REPORT.DETAILS.STATUS.COMPLETED'),
          colorScheme: 'success',
        },
        error: {
          icon: 'warning',
          text: this.$t('CAMPAIGN.REPORT.DETAILS.STATUS.ERROR'),
          colorScheme: 'alert',
        },
        // ONGOING CAMPAIGN STATUS
        enabled: {
          icon: 'arrow-right',
          text: this.$t('CAMPAIGN.ONGOING.STATUS.ENABLED'),
          colorScheme: 'primary',
        },
        disabled: {
          icon: 'warning',
          text: this.$t('CAMPAIGN.ONGOING.STATUS.DISABLED'),
          colorScheme: 'alert',
        },
      };
    },

    // Only display whatsapp inboxes
    campaignInboxes() {
      return this.$store.getters['inboxes/getInboxes'].filter(
        inbox =>
          (inbox.channel_type === 'Channel::Whatsapp' &&
            inbox.provider === 'whatsapp_cloud') ||
          inbox.channel_type === 'Channel::CommonWhatsapp'
      );
    },
  },
  methods: {
    campaignStatusInfos(campaignStatus) {
      return this.statusSettings[campaignStatus];
    },
  },
};
