<template>
  <div>
    <div>{{ $t('CAMPAIGN.ADD.FORM.MESSAGE.INTERVALS.DEFINED') }}</div>
    <p style="margin-top: 0; margin-bottom: 8px">
      {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.INTERVALS.DESCRIPTION') }}
    </p>
    <div>
      <div
        v-for="smartOption in smartOptions"
        :key="'smart_option'.concat(smartOption.time)"
      >
        <input
          :id="'smart_option'.concat(smartOption.time)"
          v-model="intervalBetweenMessages"
          class="inline"
          type="radio"
          :value="smartOption.value"
          @change="changeOption"
        />
        <label :for="'smart_option'.concat(smartOption.time)" class="inline">
          {{ $t(`CAMPAIGN.ADD.FORM.MESSAGE.INTERVALS.${smartOption.label}`) }}
          <span style="filter: brightness(0.7)">{{ smartOption.time }}</span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import CommonWhatsappCampaignUtils from './CommonWhatsappCampaign.utils';

export default {
  data() {
    return {
      intervalBetweenMessages:
        CommonWhatsappCampaignUtils.defaultIntervalOption,
    };
  },
  computed: {
    smartOptions() {
      return CommonWhatsappCampaignUtils.intervalOptions;
    },
  },
  mounted() {
    this.$emit('selected-interval', this.intervalBetweenMessages);
  },
  methods: {
    changeOption() {
      this.$emit('selected-interval', this.intervalBetweenMessages);
    },
  },
};
</script>
