<template>
  <div class="responsive-container text-slate-800 dark:text-slate-100">
    <div v-for="(metric, index) in metrics" :key="index" class="h-full">
      <metric-card
        class="h-full justify-center items-center text-center"
        :show-live-label="false"
      >
        <spinner v-if="isLoading" color-scheme="primary" />
        <div v-else class="benchmark-content relative">
          <div style="margin: 0.5rem auto">
            <h2
              class="text-4xl"
              :style="`color: ${textColor}; font-weight: bold`"
            >
              {{ metric.value || 0 }}%
            </h2>
            <h5 class="text-lg/1" :style="`color: ${textColor}`">
              {{ metric.label }}
            </h5>
          </div>
          <button
            class="fixed-div justify-center align-middle h-12 w-12 sm:h-12 sm:w-12"
          >
            <img :src="metric.icon" alt="dartboard" />
          </button>

          <button
            class="fixed-tooltip-div justify-center align-middle h-10 w-10"
          >
            <fluent-icon
              v-tooltip.left="metric.info"
              icon="info"
              size="20"
              class="mt-px"
            />
          </button>
        </div>
      </metric-card>
    </div>
  </div>
</template>

<script>
import MetricCard from 'dashboard/routes/dashboard/settings/reports/components/overview/MetricCard.vue';
import Spinner from 'shared/components/Spinner.vue';
import benchmarksMixin from './benchmarksMixin';

export default {
  components: { MetricCard, Spinner },
  mixins: [benchmarksMixin],
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
    campaignReport: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    currentDisplayTheme: {
      type: String,
      default: '',
    },
  },
  computed: {
    sentRate() {
      return Math.round((this.alreadySentCount / this.countObj.all) * 100);
    },
    failureRate() {
      const { all: totalMessages, failed: failedMessages } = this.countObj;
      return Math.round((failedMessages / totalMessages) * 100);
    },

    openRate() {
      const readMessages =
        this.countObj.read + this.countObj.answered + this.countObj.completed;
      const deliveredMessages = this.countObj.delivered + readMessages;
      return Math.round((readMessages / deliveredMessages) * 100);
    },

    interactRate() {
      const readMessages =
        this.countObj.read + this.countObj.answered + this.countObj.completed;
      const interactedMessages =
        this.countObj.answered + this.countObj.completed;
      return Math.round((interactedMessages / readMessages) * 100);
    },

    completedRate() {
      const interactedMessages =
        this.countObj.answered + this.countObj.completed;
      return Math.round((this.countObj.completed / interactedMessages) * 100);
    },
    currentCampaignStatus() {
      return this.campaignStatusInfos(this.campaign.campaign_status);
    },
    textColor() {
      return this.currentDisplayTheme === 'dark' ? '#147cff' : '#0013b3';
    },
    metrics() {
      return [
        {
          value: this.sentRate || 0,
          label: this.$t('CAMPAIGN.REPORT.BENCHMARK.SENT_RATE'),
          icon: '/assets/images/dashboard/campaigns/sent_icon.png',
          info: this.$t('CAMPAIGN.REPORT.BENCHMARK.SENT_RATE_INFO'),
        },
        {
          value: this.failureRate || 0,
          label: this.$t('CAMPAIGN.REPORT.BENCHMARK.FAIL_RATE'),
          icon: '/assets/images/dashboard/campaigns/fail_icon.png',
          info: this.$t('CAMPAIGN.REPORT.BENCHMARK.FAIL_RATE_INFO'),
        },
        {
          value: this.openRate || 0,
          label: this.$t('CAMPAIGN.REPORT.BENCHMARK.OPEN_RATE'),
          icon: '/assets/images/dashboard/campaigns/read_icon.png',
          info: this.$t('CAMPAIGN.REPORT.BENCHMARK.OPEN_RATE_INFO'),
        },
        {
          value: this.interactRate || 0,
          label: this.$t('CAMPAIGN.REPORT.BENCHMARK.INTERACT_RATE'),
          icon: '/assets/images/dashboard/campaigns/answered_icon.png',
          info: this.$t('CAMPAIGN.REPORT.BENCHMARK.INTERACT_RATE_INFO'),
        },
        {
          value: this.completedRate || 0,
          label: this.$t('CAMPAIGN.REPORT.BENCHMARK.COMPLETED_RATE'),
          icon: '/assets/images/dashboard/campaigns/completed_icon.png',
          info: this.$t('CAMPAIGN.REPORT.BENCHMARK.COMPLETED_RATE_INFO'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-container {
  @apply align-middle h-[150px];
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.fixed-div {
  @apply absolute top-[-20px] left-[-10px] flex flex-row;
  box-shadow: var(--shadow-large);
  background-color: transparent;
  border: 1px solid #8080801f;
  border-radius: 50%;
}
.fixed-tooltip-div {
  @apply absolute top-[-20px] right-[-10px] flex flex-row;
  box-shadow: var(--shadow-large);
  background-color: transparent;
  border: 1px solid #8080801f;
  border-radius: 50%;
  flex: 1;
}
.benchmark-content {
  flex: 1;
}
.h-full {
  flex: 1;
}

@media (max-height: 720px) {
  h5 {
    font-size: 0.75rem;
  }
}
</style>
