<template>
  <form class="row" @submit.prevent="submitForm()">
    <div
      v-if="!editMode"
      class="medium-8 columns w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]"
    >
      <label :class="{ error: $v.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
        <input
          v-model.trim="inboxName"
          :disabled="isLoading"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="$v.inboxName.$touch"
        />
        <span v-if="$v.inboxName.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
        </span>
      </label>
    </div>

    <div class="medium-8 columns w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.phoneNumber.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
        <input
          v-model.trim="phoneNumber"
          v-tooltip.bottom="
            isConnected ? 'Desconecte o telefone antes de modificá-lo' : ''
          "
          :disabled="isLoading || isConnected"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')"
          @blur="$v.phoneNumber.$touch"
        />
        <span v-if="$v.phoneNumber.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
        </span>
      </label>
    </div>

    <div v-if="imgSrc" id="qr-code-container" class="medium-12 columns">
      <img
        :src="imgSrc"
        alt="qr-code"
        style="width: 100%; max-width: 400px; margin-bottom: 8px"
      />
    </div>

    <div
      v-if="messageShown"
      :class="{
        warn: messageShown === $t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'),
        success:
          messageShown ===
          $t('INBOX_MGMT.ADD.WHATSAPP.QRCODE_MESSAGES.SUCCESS_ON_CONNECTION'),
      }"
      class="medium-12 columns"
    >
      <p>{{ messageShown }}</p>
    </div>

    <div
      v-if="(!isConnected && editMode) || !editMode"
      class="medium-12 columns"
    >
      <woot-submit-button
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.QRCODE_MESSAGES.GENERATE')"
        :disabled="isLoading"
      />
      <span v-if="isLoading" class="spinner" />
    </div>

    <div v-if="isConnected && editMode" class="medium-12 columns">
      <woot-button
        :loading="uiFlags.isCreating"
        :disabled="isLoading"
        size="medium"
        variant="smooth"
        color-scheme="alert"
        type="button"
        @click="refreshConnection()"
      >
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.QRCODE_MESSAGES.REFRESH_CONNECTION') }}
        <span v-if="isLoading" class="spinner" />
      </woot-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../../index';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';
import CommonWhatsappAPI from './api';

export default {
  mixins: [alertMixin],
  props: {
    defaultPlayer: {
      type: String,
      required: false,
      default: 'codechat',
    },
    editMode: {
      default: false,
      type: Boolean,
    },
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      imgSrc: '',
      messageShown: '',
      isLoading: false,
      apiToken: '',
      isConnected: false,
      maxAttempts: 5,
      attempts: 0,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
  },
  validations() {
    const validations = {
      phoneNumber: { required, isPhoneE164OrEmpty },
    };
    if (!this.editMode) validations.inboxName = { required };
    return validations;
  },
  mounted() {
    if (this.editMode) {
      this.phoneNumber = this.inbox.phone_number;
      this.checkConnectionStatus();
    }
  },
  methods: {
    async createChannel() {
      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'common_whatsapp',
              phone_number: this.phoneNumber,
              token: this.apiToken,
              player: this.defaultPlayer,
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      }
    },

    async submitForm() {
      if (this.isLoading) return;

      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isLoading = true;
      this.messageShown = '';
      this.imgSrc = '';

      const { data } = await CommonWhatsappAPI.getQrCode(
        this.phoneNumber,
        this.inbox
      );

      if (!data.success) {
        this.messageShown = data.message;
        this.isLoading = false;
        return;
      }
      this.checkConnectionStatus();
    },

    async checkConnectionStatus(editMode = false) {
      const { data } = await CommonWhatsappAPI.checkConnectionStatus(
        this.phoneNumber,
        this.inbox,
        {
          editMode: this.editMode && editMode,
        }
      );

      // Sessão expirou e precisa gerar uma nova
      if (data.status && data.status.status === 404) {
        this.isLoading = false;
        if (!this.editMode) this.submitForm();
        else this.messageShown = 'Telefone Desconectado';
        return;
      }

      if (data.success !== true || data.status.state === 'connecting') {
        if (data.message) {
          this.messageShown = data.message;
          this.imgSrc = '';
          this.isLoading = false;
          return;
        }

        const { qrcode } = data;
        if (qrcode)
          this.imgSrc = qrcode.includes('data:image')
            ? qrcode
            : 'data:image/png;base64,' + qrcode;
        await CommonWhatsappAPI.waitInSeconds(5);
        this.checkConnectionStatus();
        return;
      }

      this.messageShown = this.$t(
        'INBOX_MGMT.ADD.WHATSAPP.QRCODE_MESSAGES.SUCCESS_ON_CONNECTION'
      );
      this.imgSrc = '';
      this.apiToken = data.token;
      this.isConnected = true;
      if (!this.editMode) {
        this.createChannel();
      } else {
        this.isLoading = false;
        if (this.phoneNumber !== this.inbox.phone_number)
          this.updateInboxPhoneNumber();
      }
    },
    updateInboxPhoneNumber() {
      this.$emit('updated-phone-number', {
        phoneNumber: this.phoneNumber,
        token: this.apiToken,
      });
    },
    async refreshConnection() {
      this.isLoading = true;
      this.messageShown = '';
      await CommonWhatsappAPI.closeAndClearSession(
        this.phoneNumber,
        this.inbox,
        {
          editMode: true,
        }
      );

      this.$emit('rerender');
    },
  },
};
</script>
<style lang="scss" scoped>
.warn {
  color: var(--r-500);
  font-weight: bold;
}

.success {
  color: var(--g-500);
  font-weight: bold;
}
</style>
