var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm()}}},[(!_vm.editMode)?_c('div',{staticClass:"medium-8 columns w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]"},[_c('label',{class:{ error: _vm.$v.inboxName.$error }},[_vm._v("\n      "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL'))+"\n      "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inboxName),expression:"inboxName",modifiers:{"trim":true}}],attrs:{"disabled":_vm.isLoading,"type":"text","placeholder":_vm.$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')},domProps:{"value":(_vm.inboxName)},on:{"blur":[_vm.$v.inboxName.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.inboxName=$event.target.value.trim()}}}),_vm._v(" "),(_vm.$v.inboxName.$error)?_c('span',{staticClass:"message"},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR'))+"\n      ")]):_vm._e()])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"medium-8 columns w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]"},[_c('label',{class:{ error: _vm.$v.phoneNumber.$error }},[_vm._v("\n      "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL'))+"\n      "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.phoneNumber),expression:"phoneNumber",modifiers:{"trim":true}},{name:"tooltip",rawName:"v-tooltip.bottom",value:(
          _vm.isConnected ? 'Desconecte o telefone antes de modificá-lo' : ''
        ),expression:"\n          isConnected ? 'Desconecte o telefone antes de modificá-lo' : ''\n        ",modifiers:{"bottom":true}}],attrs:{"disabled":_vm.isLoading || _vm.isConnected,"type":"text","placeholder":_vm.$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')},domProps:{"value":(_vm.phoneNumber)},on:{"blur":[_vm.$v.phoneNumber.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.phoneNumber=$event.target.value.trim()}}}),_vm._v(" "),(_vm.$v.phoneNumber.$error)?_c('span',{staticClass:"message"},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR'))+"\n      ")]):_vm._e()])]),_vm._v(" "),(_vm.imgSrc)?_c('div',{staticClass:"medium-12 columns",attrs:{"id":"qr-code-container"}},[_c('img',{staticStyle:{"width":"100%","max-width":"400px","margin-bottom":"8px"},attrs:{"src":_vm.imgSrc,"alt":"qr-code"}})]):_vm._e(),_vm._v(" "),(_vm.messageShown)?_c('div',{staticClass:"medium-12 columns",class:{
      warn: _vm.messageShown === _vm.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'),
      success:
        _vm.messageShown ===
        _vm.$t('INBOX_MGMT.ADD.WHATSAPP.QRCODE_MESSAGES.SUCCESS_ON_CONNECTION'),
    }},[_c('p',[_vm._v(_vm._s(_vm.messageShown))])]):_vm._e(),_vm._v(" "),((!_vm.isConnected && _vm.editMode) || !_vm.editMode)?_c('div',{staticClass:"medium-12 columns"},[_c('woot-submit-button',{attrs:{"loading":_vm.uiFlags.isCreating,"button-text":_vm.$t('INBOX_MGMT.ADD.WHATSAPP.QRCODE_MESSAGES.GENERATE'),"disabled":_vm.isLoading}}),_vm._v(" "),(_vm.isLoading)?_c('span',{staticClass:"spinner"}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.isConnected && _vm.editMode)?_c('div',{staticClass:"medium-12 columns"},[_c('woot-button',{attrs:{"loading":_vm.uiFlags.isCreating,"disabled":_vm.isLoading,"size":"medium","variant":"smooth","color-scheme":"alert","type":"button"},on:{"click":function($event){return _vm.refreshConnection()}}},[_vm._v("\n      "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP.QRCODE_MESSAGES.REFRESH_CONNECTION'))+"\n      "),(_vm.isLoading)?_c('span',{staticClass:"spinner"}):_vm._e()])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }