<template>
  <div class="container unselectable">
    <div
      class="background-container"
      :class="{ dark: currentDisplayTheme === 'dark' }"
    />
    <div class="message-container">
      <div
        class="message-text-preview"
        :class="{ dark: currentDisplayTheme === 'dark' }"
      >
        <!-- TEMPLATE HEADER (WHEN THERE IS) -->
        <div>
          <!-- HEADER AS IMG -->
          <div v-if="hasImage" class="media-component">
            <fluent-icon icon="image" size="24" />
            <p class="m-0 font-bold text-base">JPEG/PNG</p>
          </div>
          <!-- HEADER AS DOCUMENT -->
          <div v-if="hasDocument" class="media-component">
            <fluent-icon icon="document" size="24" />
            <p class="m-0 font-bold text-lg">PDF</p>
          </div>
          <!-- HEADER AS VIDEO -->
          <div v-if="hasVideo" class="media-component">
            <fluent-icon icon="video" size="22" class="mr-1" />
            <p class="m-0 font-bold text-sm">VIDEO.MP4</p>
          </div>
          <!-- HEADER AS TEXT -->
          <div
            v-if="headerText"
            class="text-base font-bold pb-3"
            v-html="headerText"
          />
        </div>
        <!-- TEMPLATE BODY TEXT -->
        <div class="text-sm" v-html="processedBody" />
        <!-- TEMPLATE FOOTER TEXT (WHEN THERE IS) -->
        <div
          v-if="footerText"
          class="pt-3 text-xs italic"
          v-html="footerText"
        />
        <!-- TEMPLATE BUTTONS (WHEN THERE IS) -->
        <div v-if="numberOfButtons" class="pt-3 pb-2 text-xs italic">
          <div v-for="button in buttons" :key="button.text">
            <hr />
            <div class="text-center flex gap-2 align-middle justify-center">
              <fluent-icon
                size="16"
                class="-mt-0.5 align-middle text-slate-600 dark:text-slate-300 inline-block"
                :icon="button.icon || 'arrow-reply'"
              />
              <span>{{ button.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getChangesOnTheme } from 'dashboard/helper/themeHelper';
import whatsappTemplateMixin from 'shared/mixins/whatsappTemplateMixin';
export default {
  mixins: [whatsappTemplateMixin],
  props: {
    // Whatsapp cloud template message
    template: {
      type: Object,
      default: () => {},
    },
    // common whastsapp content messages
    message: {
      type: String,
      default: '',
    },
    // Attached file type (Common whatsapp)
    // We will use this prop to show or hide attachment illustration
    attachedFileType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentDisplayTheme: '',
    };
  },
  computed: {
    processedBody() {
      const message = this.message || this.bodyText;
      let processedMessage = message.replace(/\*([^*]+)\*/g, '<b>$1</b>');
      return processedMessage.replace(/_([^_]+)_/g, '<i>$1</i>');
    },
    numberOfButtons() {
      return this.buttonsComponent?.buttons.length;
    },
    buttons() {
      // Label to represent the 'open list button'
      const listOption = {
        text: this.$t('WHATSAPP_TEMPLATES.PARSER.LIST_BUTTON'),
        icon: 'list',
      };
      // Following current Facebook's flow to send HSMs with buttons (29, april, 2024)
      // In cases where Template has more than 3 buttons only the first 2 are displayed
      // Remaining buttons are encapsulated within the LIST tool
      // Which can be opened when customer clicks in the 'See all options' button
      if (this.numberOfButtons > 3)
        return [
          this.buttonsComponent?.buttons[0],
          this.buttonsComponent?.buttons[1],
          listOption,
        ];

      return this.buttonsComponent?.buttons || [];
    },
  },
  mounted() {
    getChangesOnTheme(this.currentDisplayTheme, this);
  },
  methods: {
    handleThemeChange(newTheme) {
      this.currentDisplayTheme = newTheme;
    },
  },
};
</script>
<style scoped>
.unselectable {
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}
.container {
  margin: 10px 0;
  position: relative;
  padding: 30px 40px;
  border-radius: 4px;
}
.background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  background-image: url('~dashboard/assets/images/whatsapp-white-backg.png');
}
.background-container.dark {
  background-image: url('~dashboard/assets/images/whatsapp-backg.png');
}

.message-text-preview {
  white-space: pre-line;
  position: relative;
  max-width: 30em;
  background-color: #dafdd3;
  padding: 1.125em 1.5em;
  font-size: 1em;
  border-radius: 1rem;
  box-shadow:
    0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
}
.message-text-preview.dark {
  background-color: #035c4b;
}

.message-text-preview::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100%;
  left: 1.5em;
  border: 0.75rem solid transparent;
  border-top: none;
  border-bottom-color: #dafdd3;
  filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
}
.message-text-preview.dark::before {
  border-bottom-color: #035c4b;
}
.media-component {
  @apply w-full p-4 bg-slate-200 dark:bg-slate-400 text-center justify-center flex mb-2;
  color: black;
  border-radius: 0.8rem;
}
</style>
