<template class="m-0">
  <div class="control-section">
    <!-- DETAILS CONTAINER -->
    <div class="flex ml-0 ar min-h-[265px]" style="flex-direction: column">
      <div class="flex justify-between responsive-content">
        <h5 class="text-2xl text-slate-800 dark:text-slate-100 mr-2">
          {{ campaign.title }}
        </h5>
        <woot-button
          v-tooltip.left="$t('CAMPAIGN.REPORT.DETAILS.TOOLTIP_STATUS')"
          :color-scheme="currentCampaignStatus.colorScheme"
          class="current-campaign-status max-w-[10rem] cursor-default"
          :icon="currentCampaignStatus.icon"
        >
          {{ currentCampaignStatus.text }}
        </woot-button>
      </div>

      <div class="column">
        <div class="flex w-full gap-5 mt-6 responsive-content">
          <!-- START 'SCHEDULED AT' SECTION -->
          <div v-if="isOneOffType">
            <span class="text-sm font-bold text-slate-600 dark:text-slate-400">
              {{ $t('CAMPAIGN.REPORT.DETAILS.SCHEDULED_AT') }}
            </span>
            <p
              class="text-slate-600 dark:text-slate-500 m-0"
              style="font-size: 0.9rem"
            >
              <fluent-icon
                icon="send-clock"
                class="inline text-slate-600 dark:text-slate-500"
              />
              {{
                messageStamp(new Date(campaign.scheduled_at), 'LLL d, h:mm a')
              }}
            </p>
          </div>
          <!-- END 'SCHEDULED AT' SECTION -->
          <!-- START 'LAST TRIGGERED AT' SECTION -->
          <div v-if="isOngoingType">
            <span class="text-sm font-bold text-slate-600 dark:text-slate-400">
              {{ $t('CAMPAIGN.REPORT.DETAILS.LAST_TRIGGERED_AT') }}
            </span>
            <p
              class="text-slate-600 dark:text-slate-500 m-0"
              style="font-size: 0.9rem"
            >
              <fluent-icon
                icon="send-clock"
                class="inline text-slate-600 dark:text-slate-500"
              />
              {{
                messageStamp(
                  new Date(campaign.last_triggered_at),
                  'LLL d, h:mm a'
                )
              }}
            </p>
          </div>
          <!-- END 'LAST TRIGGERED AT' SECTION -->
          <!-- START 'CREATED AT' SECTION -->
          <div>
            <span class="text-sm font-bold text-slate-600 dark:text-slate-400">
              {{ $t('CAMPAIGN.REPORT.DETAILS.CREATED_AT') }}
            </span>
            <p
              class="text-slate-600 dark:text-slate-500 m-0"
              style="font-size: 0.9rem"
            >
              <fluent-icon
                icon="book-clock"
                class="inline text-slate-600 dark:text-slate-500"
              />
              {{
                messageStampWhenDateIsString(
                  campaign.created_at,
                  'LLL d, h:mm a'
                )
              }}
            </p>
          </div>
          <!-- END 'CREATED AT' SECTION -->
          <!-- START 'CURRENT CAMPAIGN BOT' SECTION -->
          <div v-if="campaign.agent_bot">
            <span class="text-sm font-bold text-slate-600 dark:text-slate-400">
              BOT
            </span>
            <p
              class="text-slate-600 dark:text-slate-500 m-0 text-ellipsis max-w-[12rem]"
              style="font-size: 0.9rem"
            >
              <fluent-icon
                icon="bot"
                class="inline text-slate-600 dark:text-slate-500"
              />
              {{ campaign.agent_bot.name }}
            </p>
          </div>
          <!-- END 'CURRENT CAMPAIGN BOT' SECTION-->
        </div>
        <div class="flex w-full gap-5 mt-8 responsive-content">
          <!-- START 'NUMBER OF CAMPAIGNS' SECTION -->
          <div v-if="isOngoingType">
            <span class="text-sm font-bold text-slate-600 dark:text-slate-400">
              {{ $t('CAMPAIGN.REPORT.DETAILS.CAMPAIGNS_QUANTITY') }} (TOTAL)
            </span>
            <p
              class="text-slate-600 dark:text-slate-500 m-0"
              style="font-size: 0.9rem"
            >
              <fluent-icon
                icon="apps-list-detail"
                class="inline text-slate-600 dark:text-slate-500"
              />
              {{
                campaignReport.campaigns_count ||
                $t('CAMPAIGN.REPORT.DETAILS.CAMPAIGN_MESSAGES_COUNT_IS_ZERO')
              }}
            </p>
          </div>
          <!-- END 'NUMBER OF CAMPAIGNS' SECTION -->
          <!-- START 'INBOX' SECTION -->
          <div>
            <span class="text-sm font-bold text-slate-600 dark:text-slate-400">
              {{ $t('CAMPAIGN.REPORT.DETAILS.INBOX') }}
            </span>
            <div class="inbox-name-wrap">
              <inbox-name
                :inbox="inbox"
                :text-length="50"
                style="font-size: 0.9rem; margin: 0"
              />
            </div>
          </div>
          <!-- END 'INBOX' SECTION -->
          <div v-if="isOneOffType" class="w-full">
            <span class="text-sm font-bold text-slate-600 dark:text-slate-400">
              {{ $t('CAMPAIGN.REPORT.DETAILS.AUDIENCE_ORIGIN') }}
            </span>
            <div>
              <p
                class="text-slate-600 dark:text-slate-500 m-0"
                style="font-size: 0.9rem"
              >
                <fluent-icon
                  :icon="isCsvAudience ? 'file-upload' : 'tag'"
                  class="inline text-slate-600 dark:text-slate-500"
                />
                {{
                  isCsvAudience
                    ? $t('CAMPAIGN.ADD.FORM.SOURCE.CSV')
                    : `${$t('CAMPAIGN.ADD.FORM.SOURCE.AUDIENCE')}: `
                }}
                <span
                  class="bg-slate-75 dark:bg-slate-600 text-slate-900 text-sm"
                >
                  {{ audienceLabels }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <!-- START 'ANALYZED INTERVAL' SECTION -->
        <div v-if="isOngoingType" class="w-full gap-5 mt-8 responsive-content">
          <span class="text-sm font-bold text-slate-600 dark:text-slate-400">
            {{ $t('CAMPAIGN.REPORT.DETAILS.ANALYZED_INTERVAL') }}
          </span>
          <div>
            <div class="text-slate-600 dark:text-slate-500 m-0">
              <p style="font-size: 0.9rem">
                {{ $t('CAMPAIGN.REPORT.DETAILS.START_DATE') }}
              </p>
              <date-picker
                v-model="startAt"
                type="date"
                format="YYYY-MM-DD"
                value-type="format"
                :confirm="true"
                :clearable="false"
                :editable="true"
                :confirm-text="$t('CAMPAIGN.ONGOING.MODAL.SELECT_DATE')"
              />
            </div>
          </div>
          <div>
            <div class="text-slate-600 dark:text-slate-500 m-0">
              <p style="font-size: 0.9rem">
                {{ $t('CAMPAIGN.REPORT.DETAILS.END_DATE') }}
              </p>
              <date-picker
                v-model="endAt"
                type="date"
                format="YYYY-MM-DD"
                value-type="format"
                :confirm="true"
                :clearable="false"
                :editable="true"
                :confirm-text="$t('CAMPAIGN.ONGOING.MODAL.SELECT_DATE')"
              />
            </div>
          </div>
          <woot-button
            class="w-[210px]"
            variant="smooth"
            color-scheme="secondary"
            @click="searchReportInCurrentRange"
          >
            <div class="justify-center items-center text-center">
              {{ $t('CAMPAIGN.REPORT.DETAILS.SEARCH_INTERVAL') }}
            </div>
          </woot-button>
        </div>
        <!-- END 'ANALYZED INTERVAL' SECTION -->
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import campaignMixin from 'shared/mixins/campaignMixin';
import InboxName from 'dashboard/components/widgets/InboxName.vue';
import timeMixin from 'dashboard/mixins/time';
import DatePicker from 'vue2-datepicker';

export default {
  components: {
    InboxName,
    DatePicker,
  },
  mixins: [campaignMixin, timeMixin],
  props: {
    message: {
      type: String,
      default: '',
    },
    inbox: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      default: () => {},
    },
    campaignReport: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // STRING - Default is today's date (YYYY-MM-DD)
      endAt: new Date().toISOString().split('T')[0],
      // STRING - Default of 30 days ago (YYYY-MM-DD)
      startAt: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0],
    };
  },
  computed: {
    template() {
      return JSON.parse(this.campaign.message);
    },
    commonWhatsappMessage() {
      return JSON.parse(this.campaign.message)?.text || '';
    },
    currentCampaign() {
      return this.$store.getters.getCurrentCampaign(this.campaign.id);
    },
    currentCampaignStatus() {
      const status = this.isOneOffType
        ? this.campaign.campaign_status
        : this.campaign.status;
      return this.campaignStatusInfos(status);
      // return this.campaignStatusInfos(this.campaign.campaign_status);
    },
    isCsvAudience() {
      return this.campaign?.audience[0]?.type !== 'Label';
    },
    audienceLabels() {
      if (this.isCsvAudience) return '';

      const labelIds = this.campaign.audience.map(e => e.id);
      const fetchedLabels = this.$store.getters['labels/getLabels'];
      const filteredLabelTitles = labelIds
        .map(id => fetchedLabels.find(item => item.id === id)?.title)
        .filter(Boolean);
      return filteredLabelTitles.join(', ');
    },
  },
  mounted() {},
  methods: {
    searchReportInCurrentRange() {
      this.$emit('search-by-range', this.startAt, this.endAt);
    },
    async loadCampaignDetails(campaignId) {
      await this.$store.dispatch('campaigns/fetchById', campaignId);
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  .current-campaign-status {
    @apply ltr:right-2 rtl:left-2 top-2 flex flex-row;
    right: 2;
    top: 2;
  }
}
.inbox-name-wrap {
  @apply h-5 flex justify-center items-center rounded w-fit ml-0 mr-0;
}
@media (max-width: 500px) {
  .responsive-content {
    flex-direction: column;
  }
}
</style>
