import { required } from 'vuelidate/lib/validators';
export default {
  validations() {
    const commonValidations = {
      title: {
        required,
      },
      dataSourceUrl: {
        required,
      },
      selectedInbox: {
        required,
      },
    };
    return {
      ...commonValidations,
      selectedDays: {
        isEmpty() {
          return (
            this.selectedRecurrency === 'daily' || !!this.selectedDays.length
          );
        },
      },
      filters: {
        isNotJson() {
          if (this.filters === '') return true;
          try {
            const parsed = JSON.parse(this.filters);
            if (typeof parsed === 'object') return true;
            return false;
          } catch (error) {
            return false;
          }
        },
      },
    };
  },

  data() {
    return {
      selectedRecurrency: 'weekly',
      selectedDays: [],
      recurrencyOptions: [
        {
          id: 'daily',
          title: this.$t('CAMPAIGN.ONGOING.MODAL.RECURRENCY.DAILY'),
          checked: false,
        },
        {
          id: 'weekly',
          title: this.$t('CAMPAIGN.ONGOING.MODAL.RECURRENCY.WEEKLY'),
          checked: true,
        },
        {
          id: 'monthly',
          title: this.$t('CAMPAIGN.ONGOING.MODAL.RECURRENCY.MONTHLY'),
          checked: false,
        },
      ],
      // 'cron_number' key refers to the number of unix cron that points to specified weekday
      weekdays: [
        { title: this.$t('DAYS_OF_WEEK.SUNDAY'), cron_number: 0 },
        { title: this.$t('DAYS_OF_WEEK.MONDAY'), cron_number: 1 },
        { title: this.$t('DAYS_OF_WEEK.TUESDAY'), cron_number: 2 },
        { title: this.$t('DAYS_OF_WEEK.WEDNESDAY'), cron_number: 3 },
        { title: this.$t('DAYS_OF_WEEK.THURSDAY'), cron_number: 4 },
        { title: this.$t('DAYS_OF_WEEK.FRIDAY'), cron_number: 5 },
        { title: this.$t('DAYS_OF_WEEK.SATURDAY'), cron_number: 6 },
      ],
      // Generating an array of counting days: 0 - 31
      countDaysOfMonth: [...Array(31).keys()].map(i => ({
        title: i + 1,
        cron_number: i + 1,
      })),
    };
  },
  computed: {
    daysOptionsList() {
      if (this.selectedRecurrency === 'monthly') return this.countDaysOfMonth;
      if (this.selectedRecurrency === 'weekly') return this.weekdays;
      return [];
    },
    isCommonWhatsappConfigInvalid() {
      if (!this.isCommonWhatsappCampaign) return false;
      if (!this.selectedInterval || this.selectedInterval.length !== 2)
        return true;
      if (this.selectedInterval[1] < this.selectedInterval[0]) return true;
      if (
        this.commonWhatsappMessage.parameters.length &&
        this.commonWhatsappMessage.parameters.includes('')
      )
        return false;
      return false;
    },
  },
  methods: {
    selectedDaysList(variableType) {
      const selectedRecurrency = this.selectedRecurrency;
      if (
        (selectedRecurrency !== 'weekly' && variableType === 'weekday') ||
        (selectedRecurrency !== 'monthly' && variableType === 'day')
      )
        return '*';

      return this.selectedDays.map(day => day.cron_number);
    },
    buildCronTime() {
      const minutes = this.time.split(':')[1];
      const hours = this.transformBRT_To_UTC(this.time.split(':')[0]);
      const days = this.selectedDaysList('day');
      const weekdays = this.selectedDaysList('weekday');
      const months = '*';
      return minutes + ' ' + hours + ' ' + days + ' ' + months + ' ' + weekdays;
    },
    // We are assuming the selected time is in BRT format (UTC -3)
    transformBRT_To_UTC(hours) {
      let time = typeof hours === 'number' ? hours : Number(hours);
      return `${time + 3}`;
    },
    additionalInfosData() {
      const data = {};
      if (this.filters) data.filters = JSON.parse(this.filters);
      if (this.isCommonWhatsappCampaign) {
        data.trigger_rules = {
          minInterval: this.selectedInterval[0],
          maxInterval: this.selectedInterval[1],
        };
        this.messageParams = this.commonWhatsappMessage.parameters.map(e => {
          return {
            param_type: 'origin',
            text: e,
          };
        });
      }
      data.message_params = this.messageParams;
      return data;
    },
    logss() {},
  },
};
