<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
    />

    <span v-if="!player" class="spinner" />
    <wpp-connect-form v-if="player === 'wppconnect'" />
    <common-whatsapp-form v-if="player === 'codechat'" />
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import alertMixin from 'shared/mixins/alertMixin';
import WppConnectForm from './WppConnectForm';
import CommonWhatsappAPI from './commonWhatsapp/api';
import CommonWhatsappForm from './commonWhatsapp/CommonWhatsappForm';

export default {
  components: {
    PageHeader,
    WppConnectForm,
    CommonWhatsappForm,
  },
  mixins: [alertMixin],
  data() {
    return {
      player: '',
    };
  },
  mounted() {
    this.getCurrentPlayer();
  },
  methods: {
    async getCurrentPlayer() {
      const { data } = await CommonWhatsappAPI.getCurrentPlayer();
      if (!data || !data.player) {
        this.showAlert('Integração com Whatsapp indisponível no momento');
        return;
      }
      this.player = data.player;
    },
  },
};
</script>
