<template>
  <div style="display: flex; width: 100%">
    <select v-model="paramType" style="margin-bottom: 0; width: 50%">
      <option value="fixed">
        {{ $t('CAMPAIGN.TEMPLATE_PARSER.PARAM_TYPES.FIXED') }}
      </option>
      <option v-if="isOngoing" value="origin">
        {{ $t('CAMPAIGN.TEMPLATE_PARSER.PARAM_TYPES.ORIGIN') }}
      </option>
      <option
        value="dynamic"
        :disabled="csvHeaders.length === 0 && isCsvAudience"
      >
        {{ $t('CAMPAIGN.TEMPLATE_PARSER.PARAM_TYPES.DYNAMIC') }}
      </option>
    </select>
    <woot-input
      v-if="paramType === 'fixed' || paramType === 'origin'"
      v-model="paramValue"
      type="text"
      class="variable-input"
      style="width: 100%; margin-bottom: 0"
      @input="onChange"
    />
    <select
      v-else
      v-model="fieldSelected"
      style="margin-bottom: 0"
      @change="onChange"
    >
      <option
        v-for="item in options_type(isCsvAudience)"
        :key="item.key"
        :value="item.value"
        :disabled="item.value === 'disabled'"
      >
        {{ item.key }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    csvHeaders: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: '',
    },
    isCsvAudience: {
      type: Boolean,
      default: false,
    },
    availableContactInfos: {
      type: Array,
      required: true,
    },
    isOngoing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paramType: 'fixed',
      paramValue: this.value,
      fieldSelected: '',
    };
  },
  watch: {
    isCsvAudience(newVal) {
      if (newVal) {
        this.resetVariables();
      }
    },
  },
  mounted() {},
  methods: {
    onChange() {
      // The type of parameter for labels will always be fixed,
      // as the replacement of variabels will only be done in backend (triggering campaign)
      let param_type_checked = this.isCsvAudience ? this.paramType : 'fixed';
      let param_value_checked = this.isCsvAudience
        ? this.paramValue
        : this.fieldSelected;

      this.$emit('change-field-value', {
        type: param_type_checked,
        value: param_value_checked || this.paramValue,
        fieldSelected: this.fieldSelected,
      });
    },
    resetVariables() {
      this.paramType = 'fixed';
      this.paramValue = '';
      this.fieldSelected = '';
      this.onChange();
    },
    options_type(isCsv) {
      if (isCsv) return this.csvHeaders.map(e => ({ key: e, value: e }));

      // Following options contain contact infos
      // If contact doesn't have the selected parameter, campaign will send empty string instead
      return this.availableContactInfos;
    },
  },
};
</script>
