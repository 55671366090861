<template>
  <div class="flex-1 overflow-auto">
    <campaigns-table
      :campaigns="campaigns"
      :show-empty-result="showEmptyResult"
      :is-loading="uiFlags.isFetching"
      :campaign-type="type"
      @edit="openEditPopup"
      @delete="openDeletePopup"
    />
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-campaign :campaign="selectedCampaign" @on-close="hideEditPopup" />
    </woot-modal>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CAMPAIGN.DELETE.CONFIRM.TITLE')"
      :message="$t('CAMPAIGN.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('CAMPAIGN.DELETE.CONFIRM.YES')"
      :reject-text="$t('CAMPAIGN.DELETE.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import CampaignsTable from '../CampaignsTable.vue';
import EditCampaign from '../ongoing/EditCampaign.vue';
export default {
  components: {
    CampaignsTable,
    EditCampaign,
  },
  mixins: [alertMixin, campaignMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditPopup: false,
      selectedCampaign: {},
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      labelList: 'labels/getLabels',
      campaigns: 'ongoingCampaigns/getAllCampaigns',
    }),
    showEmptyResult() {
      const hasEmptyResults =
        !this.uiFlags.isFetching && this.campaigns.length === 0;
      return hasEmptyResults;
    },
  },
  mounted() {
    this.$store.dispatch('ongoingCampaigns/get');
  },
  methods: {
    openEditPopup(campaign) {
      this.selectedCampaign = campaign;
      this.showEditPopup = true;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openDeletePopup(campaign) {
      this.showDeleteConfirmationPopup = true;
      this.selectedCampaign = campaign;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      const { id } = this.selectedCampaign;
      // One way to know if current campaign is ongoing or not if it has cron_time field
      const campaignType = this.selectedCampaign.cron_time
        ? 'ongoingCampaigns'
        : 'campaigns';
      this.deleteCampaign(campaignType, id);
    },
    async deleteCampaign(campaignType, id) {
      try {
        await this.$store.dispatch(`${campaignType}/delete`, id);
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.button-wrapper {
  @apply flex justify-end pb-2.5;
}
</style>
