/* global axios */
import ApiClient from './ApiClient';

export const appendObjectToFormData = (formData, obj, baseKey) => {
  Object.keys(obj).forEach(key => {
    const fullKey = baseKey ? `${baseKey}[${key}]` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Se é um objeto (mas não um Array ou null), recursivamente adiciona suas subchaves
      if (!Array.isArray(obj[key]))
        appendObjectToFormData(formData, obj[key], fullKey);
      if (Array.isArray(obj[key]) && obj[key].length === 0)
        formData.append(fullKey, '[]');
    } else {
      // Para valores não-objetos ou arrays, adiciona diretamente ao FormData
      formData.append(fullKey, obj[key]);
    }
  });
};

export const buildCreatePayload = ({
  title,
  audience,
  inbox_id,
  message,
  agent_bot_id,
  attachment,
  scheduled_at,
  country_code,
  trigger_rules,
}) => {
  let payload;
  payload = new FormData();
  payload.append('title', title);
  payload.append('message', message);
  payload.append('inbox_id', inbox_id);
  payload.append('scheduled_at', scheduled_at);
  payload.append('audience', JSON.stringify(audience));
  if (trigger_rules)
    payload.append('trigger_rules', JSON.stringify(trigger_rules));
  if (country_code) payload.append('country_code', country_code);
  if (agent_bot_id) payload.append('agent_bot_id', agent_bot_id);
  if (attachment) payload.append('attachment', attachment);
  return payload;
};

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  create({
    title,
    audience,
    content,
    inbox_id,
    message,
    agent_bot_id,
    attachment,
    scheduled_at,
    country_code,
    trigger_rules,
  }) {
    return axios({
      method: 'post',
      url: this.url,
      data: buildCreatePayload({
        title,
        audience,
        content,
        inbox_id,
        message,
        agent_bot_id,
        attachment,
        scheduled_at,
        country_code,
        trigger_rules,
      }),
    });
  }

  getReport(params) {
    const { id, pageIndex, status, contactInfo } = params;
    let targetUrl = `${this.url}/${id}/report?page=${pageIndex || 1}`;

    const statusAsParam = status ? `&status=${status}` : '';
    const contactInfoAsParam = contactInfo
      ? `&q=${encodeURIComponent(contactInfo)}`
      : '';
    targetUrl += statusAsParam;
    targetUrl += contactInfoAsParam;

    return axios.get(targetUrl);
  }

  get(params) {
    const { title, page, status, sort_by, start_at, end_at, id } = params;

    const idAsParam = id ? `&id=${id}` : '';
    const titleAsParam = title ? `&title=${encodeURIComponent(title)}` : '';
    const pageAsParam = page ? `&page=${page}` : '';
    const statusAsParam = status ? `&status=${status}` : '';
    const sortByAsParam = sort_by ? `&sort_by=${sort_by}` : '';
    const endAtAsParam = end_at ? `&end_at=${end_at}` : '';
    const startAtAsParam = start_at ? `&start_at=${start_at}` : '';

    let targetUrl = this.url + '?';
    targetUrl += idAsParam;
    targetUrl += titleAsParam;
    targetUrl += pageAsParam;
    targetUrl += statusAsParam;
    targetUrl += sortByAsParam;
    targetUrl += endAtAsParam;
    targetUrl += startAtAsParam;

    return axios.get(targetUrl);
  }
}

export default new CampaignsAPI();
