/* eslint-disable no-promise-executor-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* import('axios').AxiosResponse */
/* global axios */
import ApiClient from '../../../../../../api/ApiClient';

class CommonWhatsappAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getCurrentPlayer() {
    const url = this.url + 'common_whatsapp_player/defaultPlayer';
    return axios.get(url);
  }

  /**
   *
   * @param {string} phone
   * @param {string} inbox
   * @returns {
   *  AxiosResponse<{
   *    success: boolean,
   *    qrcode?: string
   *  }>
   * }
   */
  getQrCode(phone, inbox) {
    const url = this.url + 'common_whatsapp_player/qrcode';
    return axios.post(url, {
      name: inbox,
      channel: {
        type: 'common_whatsapp',
        phone_number: phone,
      },
    });
  }

  /**
   *
   * @param {string} phone
   * @param {string} inbox
   * @returns {
   *  AxiosResponse<{
   *    success: boolean,
   *    status: string
   *  }>
   * }
   */
  checkConnectionStatus(phone, inbox, props) {
    const url = this.url + 'common_whatsapp_player/connStatus';
    return axios.post(url, {
      ...props,
      name: inbox,
      channel: {
        type: 'common_whatsapp',
        phone_number: phone,
      },
    });
  }

  /**
   *
   * @param {string} phone
   * @param {string} inbox
   * @returns {
   *  AxiosResponse<{
   *    success: boolean,
   *    status: string
   *  }>
   * }
   */
  closeAndClearSession(phone, inbox) {
    const url = this.url + 'common_whatsapp_player/clearSession';
    return axios.post(url, {
      name: inbox,
      channel: {
        type: 'common_whatsapp',
        phone_number: phone,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  waitInSeconds(t = 1) {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve();
      }, t * 1000)
    );
  }

  // * GROUP METHODS
  getGroupInfo(groupAsContactId, phone_number) {
    const url =
      this.url + 'common_whatsapp_player/groupInfo/' + groupAsContactId;
    return axios.get(url, { params: { phone_number } });
  }

  extractGroupInfoFromJSON(json) {
    return {
      name: json.subject,
      description: json.desc || '',
      picture: json.profile_picture || '',
      inviteLink: json.invite_link || '',
    };
  }

  extractGroupParticipantsInfoFromJSON(json, phoneNumber) {
    const conn = phoneNumber.replace(/[^0-9]/g, '');
    const adms = json.admins.map(e => e.id.replace(/[^0-9]/g, ''));
    return [...json.admins, ...json.members].map(member => {
      member.phone = member.id.replace(/[^0-9]/g, '');
      member.img = member.picture || '';
      member.isMe = member.phone === conn;
      member.isAdm = adms.includes(member.phone);
      member.name = member.name || `+${member.phone}`;

      return member;
    });
  }

  updateGroupImage(groupAsContactId, phone_number, image) {
    const formData = new FormData();
    formData.append('operation', 'updatePicture');
    formData.append('value', image);
    formData.append('phone_number', phone_number);
    const url =
      this.url + 'common_whatsapp_player/groupAction/' + groupAsContactId;
    return axios.post(url, formData);
  }

  updateParticipants(groupAsContactId, phone_number, target, operation, extra) {
    const url =
      this.url + 'common_whatsapp_player/groupAction/' + groupAsContactId;
    const targets = Array.isArray(target)
      ? target.map(e => e.replace(/[^0-9]/g, ''))
      : [target.replace(/[^0-9]/g, '')];
    return axios.post(url, {
      ...extra,
      target: targets,
      operation,
      phone_number,
    });
  }

  setGroupUserAdmin(groupAsContactId, phone_number, { target, promote }) {
    return this.updateParticipants(
      groupAsContactId,
      phone_number,
      target,
      'updateParticipantPerms',
      { promote }
    );
  }

  userControlInGroup(groupAsContactId, phone_number, target, operation) {
    return this.updateParticipants(
      groupAsContactId,
      phone_number,
      target,
      'updateParticipant',
      { add: operation === 'add' }
    );
  }

  newGroup(phone_number, body) {
    const url = this.url + 'common_whatsapp_player/newGroup';
    return axios.post(url, { ...body, phone_number });
  }

  leaveGroup(groupAsContactId, phone_number, inbox_id) {
    const url =
      this.url + 'common_whatsapp_player/leaveGroup/' + groupAsContactId;
    return axios.post(url, { phone_number, inbox_id });
  }
}

export default new CommonWhatsappAPI();
