<template>
  <div class="table-container">
    <h3 class="text-xl font-semibold text-slate-800 dark:text-slate-100">
      {{ $t('CAMPAIGN.REPORT.MESSAGES_TABLE.HEADER') }}
    </h3>
    <h5 class="text-sm text-slate-800 dark:text-slate-100">
      {{ $t('CAMPAIGN.REPORT.MESSAGES_TABLE.DESC') }}
    </h5>
    <br />
    <!-- SEARCH BY CONTACT INFO INPUT -->
    <div class="flex">
      <woot-input
        v-model="contactInfoQuery"
        class="min-w-[18rem] max-w-[20rem]"
        type="text"
        :placeholder="$t('CAMPAIGN.REPORT.MESSAGES_TABLE.NAME_OR_NUMBER')"
      />
      <woot-button
        :disabled="isLoading"
        :is-loading="isLoading"
        color-scheme="primary"
        @click.prevent="searchByContactInfo"
      >
        <fluent-icon icon="search" class="search-icon" />
      </woot-button>
      <woot-button
        v-if="currentSearchedStatus || currentSearchedContactInfoQuery"
        color-scheme="alert"
        @click="cleanFilter"
      >
        {{ $t('FILTER.CLEAR_BUTTON_LABEL') }}
      </woot-button>
    </div>
    <!-- MESSAGES TABLE -->
    <section class="bg-white dark:bg-slate-900">
      <!-- The stripe option change the background color of each line  -->
      <ve-table
        max-height="calc(100vh - 21.875rem)"
        :fixed-header="true"
        :border-around="false"
        :columns="columns"
        :table-data="tableData"
        :row-style-option="{ stripe: false }"
      />
      <div v-show="!tableData.length" class="empty-records">
        {{ $t('CAMPAIGN.REPORT.MESSAGES_TABLE.NO_RECORDS') }}
      </div>
      <div v-if="totalResponseCount" class="table-pagination">
        <ve-pagination
          :total="totalResponseCount"
          :page-index="pageIndex"
          :page-size="10"
          :page-size-option="[10]"
          @on-page-number-change="onPageNumberChange"
        />
      </div>
    </section>
  </div>
</template>
<script>
import { VeTable, VePagination, VeLocale } from 'vue-easytable';
import ptBR from './ptBR.js';
import { frontendURL, conversationUrl } from 'dashboard/helper/URLHelper';

import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName.vue';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';

export default {
  components: {
    VeTable,
    VePagination,
  },
  mixins: [timeMixin, rtlMixin],
  props: {
    campaignReport: {
      type: Object,
      default: () => {},
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contactInfoQuery: '',
      currentSearchedContactInfoQuery: '',
      currentSearchedStatus: '',
      filterList: [
        {
          id: 'sent',
          label: this.$t('CAMPAIGN.REPORT.STATUS.SENT'),
          selected: false,
        },
        {
          id: 'delivered',
          label: this.$t('CAMPAIGN.REPORT.STATUS.DELIVERED'),
          selected: false,
        },
        {
          id: 'read',
          label: this.$t('CAMPAIGN.REPORT.STATUS.READ'),
          selected: false,
        },
        {
          id: 'answered',
          label: this.$t('CAMPAIGN.REPORT.STATUS.ANSWERED'),
          selected: false,
        },
        {
          id: 'completed',
          label: this.$t('CAMPAIGN.REPORT.STATUS.COMPLETED'),
          selected: false,
        },
        {
          id: 'failed',
          label: this.$t('CAMPAIGN.REPORT.STATUS.FAILED'),
          selected: false,
        },
      ],
    };
  },
  computed: {
    columns() {
      return [
        {
          field: 'contact',
          key: 'contact',
          title: this.$t('CAMPAIGN.REPORT.MESSAGES_TABLE.RECIPIENT'),
          align: 'left',
          width: 200,
          renderBodyCell: ({ row }) => {
            if (row.contact) {
              return (
                <UserAvatarWithName
                  textClass="text-sm text-slate-800"
                  size="24px"
                  user={row.contact}
                />
              );
            }
            return '---';
          },
        },
        {
          field: 'status',
          key: 'status',
          title: 'STATUS',
          align: 'center',
          width: 100,
          renderBodyCell: ({ row }) => {
            return (
              <span>
                {this.$t(
                  `CAMPAIGN.REPORT.STATUS.${row.status.toUpperCase()}`
                ) || '---'}
              </span>
            );
          },
          filter: {
            filterList: this.filterList,
            // filter confirm hook
            filterConfirm: filterList => {
              const labels = filterList.filter(x => x.selected).map(x => x.id);
              this.changeStatusSelection(labels);
            },
            // filter reset hook
            filterReset: () => {
              this.changeStatusSelection('');
            },
          },
        },
        {
          field: 'protocol',
          key: 'protocol',
          title: this.$t('CONVERSATION.HEADER.PROTOCOL_LABEL'),
          align: 'center',
          width: 200,
          renderBodyCell: ({ row }) => {
            return <span>{row.protocol || '---'}</span>;
          },
        },
        {
          field: 'conversationLink',
          key: 'conversationLink',
          title: '',
          align: 'right',
          width: 100,
          renderBodyCell: ({ row }) => {
            const pathToMessage = frontendURL(
              conversationUrl({
                accountId: row.conversation.account_id,
                id: row.conversation.id,
                messageId: row.id,
              })
            );
            return (
              <div class="text-right">
                <router-link to={pathToMessage}>
                  {this.$t('CAMPAIGN.REPORT.MESSAGES_TABLE.ACCESS_CHAT')}
                </router-link>
              </div>
            );
          },
        },
      ];
    },
    tableData() {
      const messages = this.campaignReport?.messages?.map(response => ({
        contact: response.contact,
        conversation: response.conversation,
        protocol: response.protocol,
        status: response.campaign_message_status,
        id: response.id,
      }));
      return messages || [];
    },
    totalResponseCount() {
      const count = this.campaignReport?.messages_count;
      return count;
    },
  },
  mounted() {
    const currentLanguage = this.$root.$i18n.locale;
    if (currentLanguage === 'pt_BR' || currentLanguage === 'es')
      VeLocale.use(ptBR);
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
    changeStatusSelection(status) {
      this.currentSearchedStatus = status;
      this.$emit('status-change', status);
    },
    searchByContactInfo() {
      this.currentSearchedContactInfoQuery = this.contactInfoQuery;
      this.$emit('search-by-contactinfo', this.contactInfoQuery);
    },
    cleanFilter() {
      this.filterList = this.filterList.map(e => ({ ...e, selected: false }));
      this.currentSearchedContactInfoQuery = '';
      this.currentSearchedStatus = '';
      this.contactInfoQuery = '';
      this.$emit('clean-filter');
    },
  },
};
</script>
<style lang="scss" scoped>
.table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    @apply bg-white dark:bg-slate-900;

    &::v-deep {
      .ve-table-container {
        border-radius: var(--border-radius-normal);
      }

      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-normal) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-small) var(--space-normal) !important;
      }
      span.ve-radio-label {
        @apply text-slate-800;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .emoji-response {
    font-size: var(--font-size-large);
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.empty-records {
  align-items: center;
  // border: 1px solid var(--color-border);
  border-top: 0;
  display: flex;
  font-size: var(--font-size-small);
  height: 12.5rem;
  justify-content: center;
  margin-top: -1px;
  width: 100%;
  @apply text-slate-600 dark:text-slate-200 bg-white dark:bg-slate-900 border border-t-0 border-solid border-slate-75 dark:border-slate-700;
}
</style>
