<!-- eslint-disable no-restricted-syntax -->
<!-- eslint-disable guard-for-in -->
<template>
  <div class="medium-12 columns" style="margin-bottom: 1rem">
    <div>
      <div v-if="processedText">
        <p class="text-xs">Preview:</p>
        <whatsapp-message-preview
          :message="processedText"
          :attached-file-type="uploadedFileType"
        />
      </div>
      <textarea
        ref="textarea"
        v-model="originalText"
        :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL')"
        rows="4"
        class="template-input"
        @input="handleInput"
        @click="handleInput"
        @select="handleInput"
        @keydown="handleKeys"
      />
      <div
        v-for="(item, index) in mappedVariables"
        :key="'mapped_variable_' + index"
      >
        <woot-input
          v-if="originParams"
          v-model="mappedVariables[index]"
          :placeholder="
            $t('CAMPAIGN.ADD.FORM.MESSAGE.VARIABLE') + ' ' + (index + 1)
          "
          type="text"
          class="variable-input"
        />
        <select v-if="!originParams" v-model="mappedVariables[index]">
          <option
            v-for="option in optionsToSelect"
            :key="option.key"
            :value="option.value"
            :disabled="option.value === 'disabled'"
          >
            {{ option.key }}
          </option>
        </select>
      </div>
    </div>
    <div>
      <woot-button
        :disabled="mappedVariables.length >= 10"
        :loading="false"
        type="button"
        @click="addVariable"
      >
        {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ADD_VARIABLE') }}
      </woot-button>
    </div>
    <!-- START OF UPLOAD FILE SECTION -->
    <div class="pt-3 pb-1">
      <div>{{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ATTACHMENT.HEADER') }}</div>
      <p class="text-sm m-0">
        {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ATTACHMENT.DESCRIPTION') }}
      </p>
      <p class="text-sm m-0">
        {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ATTACHMENT.ACCEPTED_EXTENSIONS') }}
      </p>
      <file-upload
        ref="upload"
        input-id="commonWhatsappAttachment"
        :size="4096 * 4096"
        :accept="allowedFileTypes"
        :multiple="false"
        :drop="true"
        :drop-directory="false"
        :data="{
          direct_upload_url: '/rails/active_storage/direct_uploads',
          direct_upload: true,
        }"
        @input-file="onFileUpload"
      >
        <div class="w-full flex">
          <woot-button
            v-if="true"
            :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
            icon="attach"
            emoji="📎"
            color-scheme="secondary"
            variant="smooth"
            :style="
              uploadedFileIsNotInCorrectExtension
                ? 'border: 1px solid red'
                : 'border: none'
            "
          >
            {{ $t('WHATSAPP_TEMPLATES.PARSER.ATTACH') }}
          </woot-button>
        </div>
        <span
          v-if="uploadedFileIsNotInCorrectExtension"
          class="text-red-400 text-xs inline-block"
        >
          {{
            $t('WHATSAPP_TEMPLATES.PARSER.SUBMIT_FILE') + ' (.mp4/.png/.jpeg)'
          }}
        </span>
      </file-upload>
      <div v-if="attachedFiles.length">
        <attachment-preview
          class="flex-col mt-4"
          :attachments="attachedFiles"
          :remove-attachment="removeAttachment"
        />
      </div>
    </div>
    <!-- END OF UPLOAD FILE SECTION -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import FileUpload from 'vue-upload-component';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview.vue';
import fileUploadMixin from 'dashboard/mixins/fileUploadMixin';
import alertMixin from 'shared/mixins/alertMixin';

import CommonWhatsappCampaignUtils from './CommonWhatsappCampaign.utils';
import WhatsappMessagePreview from '../WhatsappMessagePreview.vue';

export default {
  components: {
    WhatsappMessagePreview,
    FileUpload,
    AttachmentPreview,
  },
  mixins: [alertMixin, fileUploadMixin],
  props: {
    isCsvAudience: {
      type: Boolean,
      default: false,
    },
    csvHeaders: {
      type: Array,
      default: () => [],
    },
    originParams: {
      type: Boolean,
      default: false,
    },
    firstExample: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      default: () => {},
    },
    template: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      message: '',
      mappedVariables: [],
      originalText:
        this.template?.text || this.$t('CAMPAIGN.ADD.FORM.MESSAGE.PREVIEW'),
      selectionStart: 0,
      selectionEnd: 0,
      attachedFiles: [],
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    optionsToSelect() {
      if (this.isCsvAudience)
        return this.csvHeaders.map(e => ({ key: e, value: e }));
      return CommonWhatsappCampaignUtils.available_contact_infos;
    },
    processedText() {
      return CommonWhatsappCampaignUtils.processTextToShow({
        isCsv: this.isCsvAudience,
        firstExample: this.firstExample,
        originalText: this.originalText,
        mappedVariables: this.mappedVariables,
        originParams: this.originParams,
      });
    },
    allowedFileTypes() {
      return 'image/png, image/jpeg, application/pdf';
    },
    uploadedFileIsNotInCorrectExtension() {
      const fileType = String(this.attachedFiles[0]?.resource?.file?.type);
      const correctExt = this.allowedFileTypes.includes(fileType.trim());
      return this.attachedFiles.length && !correctExt;
    },
    uploadedFileType() {
      return String(this.attachedFiles[0]?.resource?.file?.type)?.trim();
    },
  },
  watch: {
    isCsvAudience() {
      this.resetVariables();
    },
    processedText() {
      this.$emit('on-change-message', {
        text: this.originalText,
        parameters: [...this.mappedVariables],
      });
    },
  },
  mounted() {
    if (this.template?.text) this.setInitialParamValues();
    this.$emit('on-change-message', {
      text: this.originalText,
      parameters: [...this.mappedVariables],
    });
  },
  methods: {
    setInitialParamValues() {
      const pattern = /\{\{\d+\}\}/g; // Catching '{{ int }}' pattern
      const matches = this.originalText.match(pattern);
      const numberOfVariablesInText = matches ? matches.length : 0;

      const originalMessageParams =
        this.campaign?.additional_infos?.message_params || [];

      for (let i = 0; i < numberOfVariablesInText; i += 1)
        this.mappedVariables.push(originalMessageParams[i]?.text || '');
    },
    addVariable() {
      if (this.mappedVariables.length >= 10) return;
      this.mappedVariables.push('');
      const text =
        this.originalText.substring(0, this.selectionStart) +
        `{{${this.mappedVariables.length}}}` +
        this.originalText.substring(
          this.selectionEnd,
          this.originalText.length
        );
      this.originalText = text;
    },
    removeVariable(index) {
      this.mappedVariables.splice(index, 1);
    },
    resetVariables() {
      this.mappedVariables = new Array(this.mappedVariables.length).fill('');
    },
    handleInput() {
      const { selectionStart, selectionEnd } = this.$refs.textarea;
      this.selectionStart = selectionStart;
      this.selectionEnd = selectionEnd;
    },
    handleKeys(event) {
      const { key } = event;
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(key))
        this.handleInput();
    },
    attachFile({ blob, file }) {
      // If another file is being uploaded we remove the first one
      this.attachedFiles = [];
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
      this.propagateAttachments();
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
      this.propagateAttachments();
    },
    propagateAttachments() {
      this.$emit('attachments-change', this.attachedFiles);
    },
  },
};
</script>
