/* eslint-disable no-promise-executor-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* import('axios').AxiosResponse */
/* global axios */
import ApiClient from '../../../../../api/ApiClient';

class WppConnectAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  /**
   *
   * @param {string} phone
   * @param {string} inbox
   * @returns {
   *  AxiosResponse<{
   *    success: boolean,
   *    qrcode?: string
   *  }>
   * }
   */
  getQrCode(phone, inbox) {
    const url = this.url + 'common_whatsapp/qrCode';
    return axios.post(url, {
      name: inbox,
      channel: {
        type: 'common_whatsapp',
        phone_number: phone,
      },
    });
  }

  /**
   *
   * @param {string} phone
   * @param {string} inbox
   * @returns {
   *  AxiosResponse<{
   *    success: boolean,
   *    status: string
   *  }>
   * }
   */
  checkConnectionStatus(phone, inbox) {
    const url = this.url + 'common_whatsapp/connStatus';
    return axios.post(url, {
      name: inbox,
      channel: {
        type: 'common_whatsapp',
        phone_number: phone,
      },
    });
  }

  /**
   *
   * @param {string} phone
   * @param {string} inbox
   * @returns {
   *  AxiosResponse<{
   *    success: boolean,
   *    status: string
   *  }>
   * }
   */
  closeAndClearSession(phone, inbox) {
    const url = this.url + 'common_whatsapp/clearSession';
    return axios.post(url, {
      name: inbox,
      channel: {
        type: 'common_whatsapp',
        phone_number: phone,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  waitInSeconds(t = 1) {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve();
      }, t * 1000)
    );
  }

  // GROUP METHODS
  getGroupMembers(groupAsContactId, phone_number) {
    const url = this.url + 'common_whatsapp/groupMembers/' + groupAsContactId;
    return axios.get(url, { params: { phone_number } });
  }

  extractGroupParticipantsInfoFromJSON(json, phoneNumber) {
    const conn = phoneNumber.replace(/[^0-9]/g, '');
    const adms = json.admins
      .flat()
      .map(({ user }) => user)
      .map(e => e.id.user.replace(/[^0-9]/g, ''));

    return json.members.map(member => {
      member.phone = member.id.user.replace(/[^0-9]/g, '');
      member.img =
        member.profilePicThumbObj && member.profilePicThumbObj.imgFull
          ? member.profilePicThumbObj.imgFull
          : '';
      member.name = member.name || member.pushname;
      member.isMe = member.phone === conn;
      member.isAdm = adms.includes(member.phone);

      return member;
    });
  }

  getGroupInfo(groupAsContactId, phone_number) {
    const url = this.url + 'common_whatsapp/groupInfo/' + groupAsContactId;
    return axios.get(url, { params: { phone_number } });
  }

  extractGroupInfoFromJSON(json) {
    const imgExist =
      json.contact.profilePicThumbObj &&
      json.contact.profilePicThumbObj.imgFull;
    return {
      name: json.contact.name,
      description: json.groupMetadata.desc || '',
      picture: imgExist ? json.contact.profilePicThumbObj.imgFull : '',
      inviteLink: json.invite_link || '',
    };
  }

  setGroupUserAdmin(groupAsContactId, phone_number, body) {
    const url = this.url + 'common_whatsapp/setAdmin/' + groupAsContactId;
    return axios.post(url, { ...body, phone_number });
  }

  userControlInGroup(groupAsContactId, phone_number, target, operation) {
    const url = this.url + 'common_whatsapp/userControl/' + groupAsContactId;
    return axios.post(url, { target, operation, phone_number });
  }

  leaveGroup(groupAsContactId, phone_number, inbox_id) {
    const url = this.url + 'common_whatsapp/leaveGroup/' + groupAsContactId;
    return axios.post(url, { phone_number, inbox_id });
  }

  updateGroupConfig(groupAsContactId, phone_number, body) {
    const url = this.url + 'common_whatsapp/groupConfig/' + groupAsContactId;
    return axios.post(url, { ...body, phone_number });
  }

  updateGroupImage(groupAsContactId, phone_number, image) {
    const formData = new FormData();
    formData.append('type', 'image');
    formData.append('value', image);
    formData.append('phone_number', phone_number);
    const url = this.url + 'common_whatsapp/groupConfig/' + groupAsContactId;
    return axios.post(url, formData);
  }

  newGroup(phone_number, body) {
    const url = this.url + 'common_whatsapp/newGroup';
    return axios.post(url, { ...body, phone_number });
  }
}

export default new WppConnectAPI();
