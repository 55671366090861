/* eslint-disable class-methods-use-this */
/**
 * @typedef {import('../types/WhatsappCampaign').IntervalOption} IntervalOption
 * @typedef {import('../types/WhatsappCampaign').TextToProcess} TextToProcess
 */

class CommonWhatsappCampaignUtil {
  /**
   * @type {IntervalOption[]} Intervalos de tempo definidos entre envios das mensagens
   */
  intervalOptions = [
    // {
    //   label: 'VERY_SHORT',
    //   time: '(1 - 5s)',
    //   value: [1, 5],
    // },
    {
      label: 'SHORT',
      time: '(5 - 20s)',
      value: [5, 20],
    },
    {
      label: 'MEDIUM',
      time: '(20 - 50s)',
      value: [20, 50],
    },
    {
      label: 'LONG',
      time: '(50 - 120s)',
      value: [50, 120],
    },
    {
      label: 'VERY_LONG',
      time: '(120 - 300s)',
      value: [120, 300],
    },
  ];

  /**
   * @type {IntervalOption['value']}
   */
  defaultIntervalOption = [20, 50];

  available_contact_infos = [
    { key: 'Dados do contato', value: 'disabled' },
    { key: 'Nome', value: 'contact.name' },
    { key: 'Primeiro nome', value: 'contact.first_name' },
    { key: 'Último nome', value: 'contact.last_name' },
    { key: 'E-mail', value: 'contact.email' },
    { key: 'Telefone', value: 'contact.phone' },
  ];

  processVariable(str) {
    const key = str.replace(/{{|}}/g, '');
    const parsedKey = Number(key);
    if (Number.isNaN(parsedKey)) {
      return key;
    }
    return parsedKey - 1;
  }

  /**
   *
   * @param {TextToProcess} varsOnProcess
   * @returns {s}
   */
  processTextToShow({
    isCsv,
    firstExample,
    originalText,
    mappedVariables,
    originParams,
  }) {
    return originalText.replace(/{{([^}]+)}}/g, (match, variable) => {
      const noParsed = `{{${variable}}}`; // Texto para não matches
      const variableKey = this.processVariable(variable); // Index da variável
      const matchtext = mappedVariables[variableKey]; // Nome do atributo, Ex.: contact.name

      if (!matchtext) return noParsed;

      if (isCsv) {
        if (firstExample && firstExample[matchtext])
          return firstExample[matchtext];
        return noParsed;
      }

      if (originParams) {
        return matchtext;
      }

      const { key: newKey } = this.available_contact_infos.find(
        e => e.value === matchtext
      );
      if (!newKey) return noParsed;
      return newKey;
    });
  }
}

export default new CommonWhatsappCampaignUtil();
