<template>
  <div class="flex items-center flex-col">
    <div v-if="isLoading" class="items-center flex text-base justify-center">
      <spinner color-scheme="primary" />
      <span>{{ $t('CAMPAIGN.LIST.LOADING_MESSAGE') }}</span>
    </div>
    <div v-else class="w-full">
      <empty-state v-if="showEmptyResult" :title="emptyMessage" />
      <div v-else>
        <div v-if="isOngoingType" class="w-full">
          <ongoing-campaign-card
            v-for="campaign in campaigns"
            :key="campaign.id"
            :campaign="campaign"
            @edit="campaign => $emit('edit', campaign)"
            @delete="campaign => $emit('delete', campaign)"
          />
        </div>
        <div v-else class="w-full">
          <one-off-campaign-card
            v-for="campaign in campaigns"
            :key="campaign.id"
            :campaign="campaign"
            :campaign-metrics="campaign.campaign_metrics"
            @delete="campaign => $emit('delete', campaign)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import campaignMixin from 'shared/mixins/campaignMixin';
import OngoingCampaignCard from './ongoing/CampaignCard.vue';
import OneOffCampaignCard from './oneoff/CampaignCard.vue';

export default {
  components: {
    EmptyState,
    Spinner,
    OngoingCampaignCard,
    OneOffCampaignCard,
  },

  mixins: [clickaway, campaignMixin],

  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
    showEmptyResult: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    emptyMessage() {
      return this.isOngoingType
        ? this.$t('CAMPAIGN.ONGOING.404')
        : this.$t('CAMPAIGN.ONE_OFF.404');
    },
  },
};
</script>
